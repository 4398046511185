import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';

import DialogContent from '@mui/material/DialogContent';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Select } from '../select';
import { teachersOnboardingTextFields } from '../../_mock';
import { FileUpload } from '../file-upload';

const fieldsNotToInclude = ['createdAt', 'updatedAt', '__v', '_id', 'image'];

export default function AlertDialog({
  open,
  handleClose,

  isLoading,
  schoolToEditData,
  editedSchoolData,
  setEditedSchoolData,
  modifySchool,
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({});

  const [previewImage, setPreviewImage] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  useEffect(() => {
    if (Object.keys(editedSchoolData).length !== 0) {
      modifySchool();
    }
  }, [editedSchoolData]);

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.size > 10 * 1024 * 1024) {
      toast.error('Resolve the Errors');
      return;
    }
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (fieldsNotToInclude.includes(key) === false) {
        formData.append(key, data[key]);
      }
      if (key === '_id') {
        formData.append('id', data[key]);
      }
    });

    // // if image updated then selected image will be the file
    if (selectedImage) {
      formData.append('image', selectedImage);
    }

    setEditedSchoolData({ formData });
  };

  useEffect(() => {
    reset({ ...schoolToEditData });

    setPreviewImage(schoolToEditData?.image);
  }, [schoolToEditData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            width: { xs: '350px', sm: '649px' },
            height: 'max-content',
            borderRadius: '20PX',
          },
        }}
      >
        <Box sx={{ textAlign: 'right', p: '20px 20px 0 0' }}>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleClose} />
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Typography variant="h3" sx={{ fontWeight: 400, color: '#000', fontFamily: "'Noto Sans JP'", mb: '14px' }}>
              Edit School
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: 300 }}>
              Thank you for your dedication to the teaching profession and your interest in supporting new teachers of
              color in our growing community.
            </Typography>

            <FileUpload
              setPreviewFile={setPreviewImage}
              setSelectedFile={setSelectedImage}
              previewFile={previewImage}
              selectedFile={selectedImage}
            />
            <TextField
              name="name"
              label="School Name"
              placeholder="School Name Here"
              fullWidth
              {...register(
                'name',

                {
                  required: `School name is required`,
                  maxLength: { value: 30, message: 'School name cannot exceed 70 characters' },
                }
              )}
              error={errors && !!errors?.name}
              helperText={errors?.name?.message}
              type="text"
              sx={{ mb: '17px' }}
            />
            <TextField
              name="address"
              label="address"
              placeholder="Address Here"
              fullWidth
              // {...register('confirmPassword')}
              type="text"
              sx={{ mb: '10px' }}
              {...register(
                'state',

                {
                  required: `Address is required`,
                  maxLength: { value: 70, message: 'Address cannot exceed 70 characters' },
                }

                // { required: `Address is required` }
              )}
              error={errors && !!errors?.state}
              helperText={errors?.state?.message}
            />

            <Select
              register={register}
              field={{ ...teachersOnboardingTextFields[10], label: 'District', name: 'district' }}
              errors={errors}
              name={'district'}
              watch={watch}
              defaultValue={watch('district') || ''}
              onChange={(e) => setValue('district', e.target.value)}
              items={teachersOnboardingTextFields[10].items}
              schoolDistrict
            />
          </DialogContent>

          <Box sx={{ textAlign: 'right', pr: '31px', pb: '37px' }}>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="form"
              sx={{ width: '164px', height: '48px', mr: '4px' }}
              type="submit"
            >
              Save
            </LoadingButton>
            <Button
              variant="outlined"
              color="form"
              sx={{ width: '164px', height: '48px' }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Dialog>
    </div>
  );
}
