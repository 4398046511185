import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { DigitalSignature } from '../components/digital-signature';

const listItems = [
  'Two monthly 30-minute sessions',
  'One monthly on-site meeting with colleagues',
  'Review Two Filmed Lessons (Fall and Spring)',
  'Identify areas of support (e.g. supporting students with IEPs, classroom management) Be open to receiving feedback and improving your practice',
  'Complete Edifying Teachers’ surveys',
  'Alert site leader to challenges (e.g. scheduling, mentor matching)',
];

const OnBoardingAdOns = () =>  (
    <Box sx={{ minWidth: '800px', width: '800px', border: '1px solid ', background: 'white', mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box component="img" src="/addon.png" sx={{ position: 'relative', left: '-9px' }} />
        <Box component="img" src="/assets/newLogo.png" sx={{ mr: '50px' }} />
      </Box>

      <Typography variant="specs" sx={{ textAlign: 'center', mt: '20px' }}>
        Edifying Teachers’ Expectations and Agreement Form
      </Typography>
      <Box sx={{ maxWidth: '590px', mx: 'auto' }}>
        <Typography variant="specs" sx={{ mt: '20px' }}>
          Mission:{' '}
          <span style={{ fontWeight: 'normal' }}>
            We’re working to rehumanize education through the power of connection! Our mission is to strengthen the
            teacher workforce through a community of holistic mentorship, coaching, and peer support, where teachers can
            connect and learn with and from each other.
          </span>
          <span style={{ display: 'block', fontWeight: 'normal', marginTop: '20px' }}>
            This document will serve as a contract to help you understand your role in the Edifying community.
          </span>
        </Typography>

        <Typography variant="specs" sx={{ mt: '20px', mx: 'auto' }}>
          Contract Length:
          <span style={{ fontWeight: 'normal', marginLeft: '10px' }}>1 School Year</span>
        </Typography>

        <form style={{ paddingBottom: '30px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="specs" sx={{ display: 'grid', alignItems: 'flex-end', minWidth: 'max-content' }}>
                Phone Number:
              </Typography>

              <TextField variant="standard" sx={{ ml: '10px', width: '150px' }} />
            </Box>

            <Box sx={{ display: 'flex' }}>
              <Typography variant="specs" sx={{ display: 'grid', alignItems: 'flex-end', minWidth: 'max-content' }}>
                Email Address:
              </Typography>

              <TextField variant="standard" sx={{ ml: '10px', width: '150px' }} />
            </Box>
          </Box>

          <Typography variant="specs" sx={{ mt: '20px' }}>
            Preferred Method of Communication #1 (Rank 1-4)
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <TextField variant="standard" sx={{ ml: '10px' }} />
              <Typography
                variant="specs"
                sx={{ display: 'grid', alignItems: 'flex-end', minWidth: 'max-content', fontWeight: '500' }}
              >
                Text
              </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <TextField variant="standard" sx={{ ml: '10px' }} />

              <Typography
                variant="specs"
                sx={{ display: 'grid', alignItems: 'flex-end', minWidth: 'max-content', fontWeight: '500' }}
              >
                Phone Call
              </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <TextField variant="standard" sx={{ ml: '10px' }} />

              <Typography
                variant="specs"
                sx={{ display: 'grid', alignItems: 'flex-end', minWidth: 'max-content', fontWeight: '500' }}
              >
                Email
              </Typography>

              <Box sx={{ display: 'flex' }}>
                <TextField variant="standard" sx={{ ml: '10px' }} />

                <Typography
                  variant="specs"
                  sx={{ display: 'grid', alignItems: 'flex-end', minWidth: 'max-content', fontWeight: '500' }}
                >
                  Social Media
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="specs" sx={{ mt: '20px', minWidth: 'max-content' }}>
              Preferred Time of Communication:
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <TextField variant="standard" sx={{ ml: '10px' }} />
              <Typography
                variant="specs"
                sx={{ display: 'grid', alignItems: 'flex-end', minWidth: 'max-content', fontWeight: '500', ml: '5px' }}
              >
                Morning
              </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <TextField variant="standard" sx={{ ml: '10px' }} />

              <Typography
                variant="specs"
                sx={{ display: 'grid', alignItems: 'flex-end', minWidth: 'max-content', fontWeight: '500', ml: '5px' }}
              >
                Afternoon
              </Typography>
            </Box>

            <Box sx={{ display: 'flex' }}>
              <TextField variant="standard" sx={{ ml: '10px' }} />

              <Typography
                variant="specs"
                sx={{ display: 'grid', alignItems: 'flex-end', minWidth: 'max-content', fontWeight: '500', ml: '5px' }}
              >
                Evening
              </Typography>
            </Box>
          </Box>

          <Typography variant="specs" sx={{ mt: '30px' }}>
            Roles and Responsibilities
          </Typography>

          <ul style={{ marginLeft: '40px' }}>
            {listItems.map((item, index) => (
              <li style={{ fontSize: '12px' }} key={index}>
                {item}
              </li>
            ))}
          </ul>

          <Typography variant="specs" sx={{ mt: '15px' }}>
            Confidentiality:
            <span style={{ fontWeight: 'normal', marginLeft: '10px' }}>
              Principals will know that a mentoring session occurred and on what date. Any other information regarding
              the session to be shared with the Principal will be mutually agreed upon by the Mentee and the Edifier.
            </span>
          </Typography>

          <Typography variant="specs" sx={{ mt: '15px' }}>
            Evaluation and Feedback:
            <span style={{ fontWeight: 'normal', marginLeft: '10px' }}>
              We will seek your feedback through focus groups and surveys. We will use this information to provide
              appropriate resources and tools.
            </span>
          </Typography>

          <Typography variant="specs" sx={{ mt: '15px' }}>
            Agreement:
            <span style={{ fontWeight: 'normal', marginLeft: '10px' }}>
              By signing this document, you are affirming that you have read, understood, and agree to the expectations
              outlined herein.
            </span>
          </Typography>

          <table style={{ width: '100%', marginTop: '5px' }}>
            <tr style={{ border: '1px solid', paddingLeft: '10px' }}>
              <th style={{ paddingLeft: '5px' }}>
                {' '}
                <Typography variant="specs" sx={{ fontWeight: '500' }}>
                  Print Name:
                </Typography>
              </th>
              <th style={{ borderLeft: '1px solid', paddingLeft: '5px' }}>
                <Typography variant="specs" sx={{ fontWeight: '500', py: '5px' }}>
                  Signature
                </Typography>
              </th>
            </tr>

            <tr style={{ border: '1px solid' }}>
              <td style={{ paddingLeft: '5px' }}>
                <Typography variant="specs" sx={{ fontWeight: '500' }}>
                  Date:
                </Typography>
              </td>
              <td style={{ borderLeft: '1px solid' }}>
                {' '}
                <DigitalSignature />
              </td>
            </tr>
          </table>
        </form>
      </Box>
    </Box>
  );


export default OnBoardingAdOns;
