/*eslint-disable */
import * as React from 'react';

import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ListItem, Grid, List, Typography, Box, Dialog, Button } from '@mui/material';
import { formatDateTimeInDaySlots } from '../../utils';
import { months, days } from '../../_mock';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function TimeSlotsDialog({
  availabilitySlots,
  open,
  handleClose,
  setInviteNotificationPayload,
  edifierId,
  isNotificationSending,
  inviteNotificationPayload,
  refactoredInvites,
  isLoadingSentInvites,
  isEdifier,
  getDayIndex,
}) {

  const isSmallScreen = useMediaQuery('(max-width:360px)');

  const convertDateString = (date) => {
    return new Date(date);
  };

  const isInvitePendingOrAccepted = (item) => {
    return refactoredInvites[convertDateString(item?.start)?.toISOString()]?.status;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-paper': {
            minWidth: { xs: '100%', md: '800px' },
            p: { xs: '10px', md: '20px' },
          },
        }}
      >
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" component="div">
              {isEdifier ? 'Teacher Schedule' : 'Edifier Schedule'}
            </Typography>
            <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => handleClose()} />
          </Box>

          <Demo>
            <List>
              {formatDateTimeInDaySlots(availabilitySlots)
                ?.sort((a, b) => {
                  return getDayIndex(a.day) - getDayIndex(b.day);
                })
                ?.map((daySlots, index) => (
                  <Box key={index}>
                    <Typography variant="h5" sx={{ textAlign: 'center' }}>
                      {daySlots?.day}
                    </Typography>

                    {daySlots?.daySlots.map((item, subIndex) => (
                      <ListItem
                        sx={{
                          py: '20px',
                          opacity: new Date() > item.end && '0.5',
                          pointerEvents: new Date() > item.end && 'none',
                          '& .MuiListItemSecondaryAction-root': { right: 0 },
                        }}
                        key={subIndex}
                        secondaryAction={
                          <LoadingButton
                            loading={inviteNotificationPayload?.slot === item?.start && isNotificationSending}
                            variant="contained"
                            color="main"
                            sx={{
                              width: { xs: '100px', sm: '118px' },
                              p: { xs: '5px', sm: '10px' },
                              textTransform: 'capitalize',
                              fontSize: { xs: '12px', sm: '14px' },
                            }}
                            disabled={['PENDING', 'ACCEPTED'].includes(isInvitePendingOrAccepted(item))}
                            onClick={() => setInviteNotificationPayload({ recipient: edifierId, slot: item.start })}
                          >
                            {['PENDING', 'ACCEPTED'].includes(isInvitePendingOrAccepted(item))
                              ? isInvitePendingOrAccepted(item)
                              : 'Send Invite'}
                          </LoadingButton>
                        }
                      >
                        <Box
                          component="img"
                          src="/assets/scheduleIcon.svg"
                          sx={{ mr: { xs: '10px', sm: '20px', md: '40px' } }}
                        />
                        <Typography
                          sx={{
                            fontSize: { xs: '12px', sm: '16px' },
                            width: { xs: '190px', sm: 'max-content' },
                            pr:isSmallScreen?'80px':'40px'
                          }}
                        >
                          {`${convertDateString(item?.start)?.getDate()} ${
                            months[convertDateString(item?.start)?.getMonth()]
                          }, ${days[convertDateString(item?.start)?.getDay()]} (${convertDateString(item?.start)
                            ?.toLocaleTimeString()
                            .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')} to ${convertDateString(item?.end)
                            ?.toLocaleTimeString()
                            .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')})`}
                        </Typography>
                      </ListItem>
                    ))}
                  </Box>
                ))}
              {/* {availabilitySlots?.map((item, index) => (
                <ListItem
                  sx={{
                    py: '20px',
                    opacity: new Date() > item.end && '0.5',
                    pointerEvents: new Date() > item.end && 'none',

                    '& .MuiListItemSecondaryAction-root': {
                      position: { xs: 'relative', md: 'absolute' },
                      ml: { xs: 'auto', md: 'inherit' },
                    },
                    '&.MuiListItem-root': { p: { xs: '20px 0 0 0', md: '20px' } },
                  }}
                  key={index}
                  secondaryAction={
                    <LoadingButton
                      loading={inviteNotificationPayload?.slot === item?.start && isNotificationSending}
                      variant="contained"
                      color="main"
                      sx={{ width: { xs: '100px', md: '118px' }, fontSize: { xs: '10px', md: '14px' } }}
                      disabled={isInvitePendingOrAccepted(item) ? true : false}
                      onClick={() => setInviteNotificationPayload({ recipient: edifierId, slot: item.start })}
                    >
                      {isInvitePendingOrAccepted(item) ? 'Invite Sent' : 'Send Invite'}
                    </LoadingButton>
                  }
                >
                  <Box component="img" src="/assets/scheduleIcon.svg" sx={{ mr: { xs: '20px', md: '40px' } }} />
                  <Typography
                    sx={{ fontSize: { xs: '12px', md: '15px' }, width: { xs: '160px', sm: '250px', md: 'inherit' } }}
                  >
                    {`${item.start.getDate()}  ${months[item.start?.getMonth()]} ${
                      days[item.start.getDay()]
                    } ${item.start.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}`}{' '}
                    <Box style={{ fontWeight: 700, ml: { xs: '0', sm: '10px' }, mr: '10px', display: 'inline-block' }}>
                      {' '}
                      To
                    </Box>{' '}
                    {`${item.end.getDate()} ${days[item.end.getDay()]} ${item.end
                      .toLocaleTimeString()
                      .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}`}
                  </Typography>
                </ListItem>
              ))} */}
            </List>
          </Demo>
        </Grid>
      </Dialog>
    </>
  );
}
