/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Button, Box, TextField, Typography } from '@mui/material';

const BarChart = ({ data, toast }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState({});
  const [msChartData, setMsChartData] = useState({
    options: {
      chart: {
        id: 'ms-bar',
        width: '700', // Adjust width as needed
        height: '500', // Adjust height as needed
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%', // You can adjust this percentage as needed
          barHeight: '100%',
          barWidth: '50%', // Adjust the width of each bar here
        },
      },
    },
    series: [],
  });
  const [csChartData, setCsChartData] = useState({
    options: {
      chart: {
        id: 'cs-bar',
        width: '700', // Adjust width as needed
        height: '500', // Adjust height as needed
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%', // You can adjust this percentage as needed
          barHeight: '100%',
          barWidth: '50%', // Adjust the width of each bar here
        },
      },
    },
    series: [],
  });

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const filterData = () => {
    if (startDate && endDate) {
      const filtered = data.filter((entry) => {
        const timestamp = new Date(entry.timestamp);
        return timestamp >= startDate && timestamp <= endDate;
      });
      setFilteredData(filtered);
    }
  };

  useEffect(() => {
    updateChartData();
  }, [filteredData]);

  const updateChartData = () => {
    const msSeriesData = {};
    const csSeriesData = {};

    filteredData?.length &&
      filteredData?.forEach((entry) => {
        entry?.surveyData?.Ms?.forEach((ms) => {
          if (!msSeriesData[ms]) {
            msSeriesData[ms] = new Array(12).fill(0);
          }
        });
        entry?.surveyData?.Cs?.forEach((cs) => {
          if (!csSeriesData[cs]) {
            csSeriesData[cs] = new Array(12).fill(0);
          }
        });
      });

    filteredData?.length &&
      filteredData?.forEach((entry) => {
        const monthIndex = new Date(entry.timestamp).getMonth();
        entry?.surveyData?.Ms?.forEach((ms) => {
          msSeriesData[ms][monthIndex]++;
        });
        entry?.surveyData?.Cs?.forEach((cs) => {
          csSeriesData[cs][monthIndex]++;
        });
      });

    const msSeries = Object.entries(msSeriesData).map(([name, data]) => ({
      name,
      data,
    }));

    const csSeries = Object.entries(csSeriesData).map(([name, data]) => ({
      name,
      data,
    }));

    setMsChartData((prevChartData) => ({
      ...prevChartData,
      series: msSeries,
    }));

    setCsChartData((prevChartData) => ({
      ...prevChartData,
      series: csSeries,
    }));
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const searchCsMs = () => {
    if (!endDate || !startDate) {
      toast.error('Both start and end dates required');
    }

    if (endDate < startDate) {
      toast.error('End Date must be Greater than Start Date');
      return;
    }
    filterData();
  };

  return (
    <Box
      className="app"
      sx={{
        background: '#FFF',
        boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
        p: '30px',
        mt: '20px',
        maxWidth: { xs: '500px', sm: '100%' },
        overflow: 'scroll',
      }}
    >
      <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
        M'S & C'S
      </Typography>
      <Box
        sx={{
          display: 'flex',
          height: 'fit-content',
          flexDirection: { xs: 'column', sm: 'row' },
          rowGap: '25px',
          columnGap: '10px',
          my: '30px',
          justifyContent: 'flex-end',
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <span style={{ fontSize: '12px', display: 'block', position: 'absolute', top: '-20px' }}>Start Date</span>
          <TextField
            size="small"
            type="date"
            id="start-date"
            onChange={(e) => handleStartDateChange(new Date(e.target.value))}
          />
        </Box>

        <Box sx={{ position: 'relative' }}>
          <span style={{ fontSize: '12px', display: 'block', position: 'absolute', top: '-20px' }}>End Date</span>
          <TextField
            size="small"
            type="date"
            id="end-date"
            onChange={(e) => handleEndDateChange(new Date(e.target.value))}
          />
        </Box>
        <Button
          variant="contained"
          color="main"
          onClick={searchCsMs}
          sx={{ height: '30px', mt: 'auto', mb: 'auto', width: '80px' }}
        >
          Search
        </Button>
      </Box>
      <div className="row">
        <div className="mixed-chart">
          <Chart options={msChartData.options} series={msChartData.series} type="bar" width="100%" />
        </div>
      </div>
      <div className="row">
        <div className="mixed-chart">
          <Chart options={csChartData.options} series={csChartData.series} type="bar" width="100%" />
        </div>
      </div>
    </Box>
  );
};

export default BarChart;
