/*eslint-disable*/
import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
// import Picker from 'emoji-mart/dist-modern/index.js';
// import 'emoji-mart/css/emoji-mart.css';
import DialogContent from '@mui/material/DialogContent';

import { useOutletContext } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { getUserDetails } from '../../utils';
import { newMessageReceivedIdentifierStyles } from '../../jsxStyles';

export default function ScrollDialog({ open, setOpen, id, recipientUser }) {
  const isSmallScreen = useMediaQuery('(max-width:700px)');

  const scrollableRef = useRef(null);
  const newMessageRef = useRef(null);
  const inputRef = useRef(null); // Ref for the input element

  const [roomId, setRoomId] = useState('');
  const [messagesArray, setMessagesArray] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const userDetails = getUserDetails();

  const socketInstance = useOutletContext();

  useEffect(() => {
    // Listen for incoming messages from the server

    socketInstance[8]?.emit('joinRoom', { receiverId: id }, (data) => {
      setRoomId(data);
    });
    // socket.emit('joinRoom', 'test');

    socketInstance[8]?.on('newMessage', (message) => {
      try {
        scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
      } catch (e) {}
      if (message[message.length - 1]?.senderId?._id !== userDetails.id) {
        try {
          newMessageRef.current.style.display = 'block';
        } catch (e) {}
        setTimeout(() => {
          try {
            newMessageRef.current.style.display = 'none';
          } catch (e) {}
        }, 2000);
      }

      setMessagesArray([...message]);
    });
  }, [socketInstance[8]]);

  useEffect(() => {
    if (open) {
      socketInstance[8]?.emit('roomMessages', { roomId }, (data) => {
        setMessagesArray(data);
      });
    }
  }, [open]);

  useEffect(() => {
    if (messagesArray.length > 0) {
      try {
        scrollableRef.current.scrollTop = scrollableRef.current.scrollHeight;
      } catch (e) {}
    }
  }, [messagesArray]);

  // Optionally, listen for acknowledgment from the server

  const [scroll, setScroll] = React.useState('paper');

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const insertEmoji = (emoji) => {
    const startPos = inputRef.current.selectionStart;
    const endPos = inputRef.current.selectionEnd;

    const textBeforeCursor = userMessage.substring(0, startPos);
    const textAfterCursor = userMessage.substring(endPos);

    setUserMessage(textBeforeCursor + emoji + textAfterCursor);

    // Move cursor after the inserted emoji
    inputRef.current.selectionStart = startPos + emoji.length;
    inputRef.current.selectionEnd = startPos + emoji.length;
  };

  function containsOnlySpaces() {
    return /^\s*$/.test(userMessage);
  }

  const handleSendMessage = () => {
    if (containsOnlySpaces()) {
      return;
    }
    socketInstance[8]?.emit('sendMessage', { roomId, message: userMessage });
    setUserMessage('');
  };

  return (
    <div style={{ position: 'relative' }}>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          '& .MuiPaper-root': { minWidth: { xs: '350px', sm: '600px', md: '731.599px' }, height: '713px' },
        }}
      >
        <Box
          sx={{
            ...newMessageReceivedIdentifierStyles,
          }}
          ref={newMessageRef}
        >
          <Typography
            sx={{
              fontSize: '13px',
              textAlign: 'center',
              borderRadius: '20px',
            }}
          >
            New Message
          </Typography>
        </Box>
        <DialogTitle id="scroll-dialog-title">
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="mainTitles" sx={{ lineHeight: '70px', color: '#A94B9C' }}>
              Chat with {recipientUser}
            </Typography>

            <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => handleClose()} />
          </Box>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} ref={scrollableRef}>
          {messagesArray?.map((item, index) =>
            item?.senderId?._id === userDetails?.id ? (
              <Box key={index} sx={{ display: 'flex', columnGap: '16.06px', mb: '10px', justifyContent: 'flex-end' }}>
                <Box>
                  {/* <Typography variant="subtitle2" sx={{ textAlign: 'right', mb: '5px' }}>
                    {item?.senderId?.name}
                  </Typography> */}

                  <Box
                    sx={{
                      borderRadius: '14px 6px 14px 14px',
                      background: '#8D73AF',
                      p: '11.63px 14.3px 14.37px 13.31px',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: '13px',
                        color: '#FFF',
                        maxWidth: { xs: '200px', sm: '340px' },
                        wordWrap: 'break-word',
                      }}
                    >
                      {' '}
                      {item?.message}
                    </Typography>
                  </Box>

                  <div style={{ textAlign: 'right' }}>
                    <span style={{ fontSize: '9px' }}>
                      {' '}
                      {new Date(item?.timestamp).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </span>
                  </div>
                </Box>

                <Box
                  component="img"
                  src={item?.senderId?.image || '/assets/ProfileAvatar.png'}
                  sx={{ height: '40px', width: '40px', borderRadius: '50%' }}
                />
              </Box>
            ) : (
              <Box key={index} sx={{ display: 'flex', columnGap: '16.06px ' }}>
                <Box
                  component="img"
                  src={item?.senderId?.image || '/assets/ProfileAvatar.png'}
                  sx={{ height: '40px', width: '40px', borderRadius: '50%' }}
                />

                <Box>
                  <Typography variant="subtitle2" sx={{ mb: '5px' }}>
                    {item?.senderId?.fname}
                  </Typography>

                  <Box
                    sx={{
                      borderRadius: '6px 14px 14px 14px',
                      background: '#F9F9F9',
                      p: '11.63px 14.3px 14.37px 13.31px',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: '13px', color: '#282A39', maxWidth: { xs: '200px', sm: '340px' } }}
                    >
                      {' '}
                      {item?.message}
                    </Typography>
                  </Box>
                  <div style={{ textAlign: 'right' }}>
                    <span style={{ fontSize: '9px' }}>
                      {' '}
                      {new Date(item?.timestamp).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })}
                    </span>
                  </div>
                </Box>
              </Box>
            )
          )}

          {/* chat item other user */}
        </DialogContent>
        <DialogActions sx={{ padding: '0' }}>
          <Box sx={{ position: 'relative', width: '100%' }}>
            <Box
              component="img"
              src="/assets/addEmojiIcon.svg"
              onClick={toggleEmojiPicker}
              sx={{
                position: 'absolute',
                left: { xs: '5px', sm: '15px', md: '25.33px' },
                top: '15px',
                cursor: 'pointer',
              }}
            />
            <input
              className="send-message-input"
              value={userMessage}
              ref={inputRef}
              placeholder="your message .."
              style={{
                width: '100%',
                border: 'none',
                height: '70.59px',
                paddingRight: isSmallScreen ? '60px' : '100px',
                paddingLeft: isSmallScreen ? '55px' : '83.87px',
              }}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent default behavior of Enter key (e.g., form submission)
                  handleSendMessage();
                }
              }}
            />
            <Button
              disabled={!userMessage || containsOnlySpaces()}
              sx={{
                position: 'absolute',
                right: { xs: '0', sm: '20px', md: '35.06px' },
                top: '15px',
                cursor: 'pointer',
              }}
              onClick={handleSendMessage}
            >
              Send
            </Button>
            {showEmojiPicker && (
              <Box sx={{ position: 'absolute', bottom: '70px' }}>
                <Picker
                  data={data}
                  onEmojiSelect={(e) => {
                    insertEmoji(e.native);
                  }}
                />
              </Box>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
