/*eslint-disable */
import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

import { teachingInfoInEdifiersCase, welcomeSessionSurveyItems, sessionBSurveyHeadItems } from '../../_mock';

import { stepperBoxStyles } from '../../jsxStyles';

const SessionSurveyB = ({ setPayload, participantId, apiPayload, submitSurvey, isLoading, prePopulatedData }) => {
  const [checkboxGroupSelection, setCheckboxGroupSelection] = useState({
    myAsessment: {
      goalsBeingAddressed: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      identifiedNewStrategies: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      ocassionallyQuestionsNotAnswered: {
        stronglyAgree: false,
        agree: false,
        disagree: false,
        stronglyDisagree: false,
      },
      betterTeacherAsaResult: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      addressingIdentifiedGoals: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    // getValues,
    // setValue,
    // watch,
  } = useForm({});

  const onSubmit = (submittedData) => {
    const updatedPayload = {
      myAsessment: checkboxGroupSelection.myAsessment,
      likeEdifierKnowAboutYou: submittedData.likeEdifierToKnow,
      title: 'Session Survey B',
    };
    setPayload({
      participantId,
      surveyData: updatedPayload,
    });
  };

  useEffect(() => {
    if (apiPayload && Object.keys(apiPayload).length > 0) {
      submitSurvey();
    }
  }, [apiPayload]);

  useEffect(() => {
    if (prePopulatedData) {
      setCheckboxGroupSelection({ myAsessment: prePopulatedData?.myAsessment });
    }
  }, [prePopulatedData]);

  return (
    <Box sx={{ ...stepperBoxStyles, minWidth: '100%', mt: '30px' }}>
      <Typography
        variant="h3"
        sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif", mb: '50px', textAlign: 'center' }}
      >
        Session Survey B
      </Typography>

      <Typography variant="h3" sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif", my: '50px' }}>
        Note-: This will be shared with your Edifier
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {sessionBSurveyHeadItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: '25px', md: index !== 0 && '30px' },
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                width: '200px',
                fontSize: '18px',
                fontWeight: 300,
                display: 'grid',
                alignItems: 'center',
                lineHeight: '25px',
              }}
              variant="form"
            >
              {item?.title}
            </Typography>

            {index === 0 &&
              welcomeSessionSurveyItems.map((subItem, subIndex) => (
                <Typography
                  key={subIndex}
                  sx={{
                    width: '156px',
                    mb: '24px',
                    fontWeight: 500,
                    color: '#000',
                    minWidth: 'max-content',
                    display: { xs: 'none', md: 'inherit' },
                    justifyContent: 'center',
                  }}
                  variant="subtitle1"
                >
                  {subItem}
                </Typography>
              ))}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  Strongly Agree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.myAsessment[item?.name]?.stronglyAgree || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item?.name}-stronglyAgree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          myAsessment: {
                            ...checkboxGroupSelection.myAsessment,
                            [item?.name]: {
                              stronglyAgree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Agree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.myAsessment[item?.name]?.agree || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item?.name}-agree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          myAsessment: {
                            ...checkboxGroupSelection.myAsessment,
                            [item?.name]: {
                              agree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Disagree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.myAsessment[item?.name]?.disagree || false}
                      name={`${item?.name}-disagree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          myAsessment: {
                            ...checkboxGroupSelection.myAsessment,
                            [item?.name]: {
                              disagree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Strongly Disagree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.myAsessment[item?.name]?.stronglyDisagree || false}
                      name={`${item?.name}-stronglyDisagree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          myAsessment: {
                            ...checkboxGroupSelection?.myAsessment,
                            [item?.name]: {
                              stronglyDisagree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                  {...register('item', {
                    validate: () =>
                      // your validation logic here :)
                      JSON.stringify(checkboxGroupSelection.myAsessment).split('true').length - 1 === 5,
                  })}
                />
              </Box>
            )}
          </Box>
        ))}

        {errors && !!errors?.item && (
          <Typography variant="validation" sx={{ fontSize: '15px' }}>
            All options must be checked
          </Typography>
        )}

        <Box>
          <Typography sx={{ mt: '50px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
            What would you like your Edifier to know about you (EF)
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            fullWidth
            name="likeEdifierToKnow"
            {...register('likeEdifierToKnow')}
            defaultValue={prePopulatedData ? prePopulatedData?.likeEdifierKnowAboutYou : ''}
          />
        </Box>

        {!prePopulatedData && (
          <Box sx={{ textAlign: 'right' }}>
            <LoadingButton
              type="submit"
              loading={isLoading}
              variant="contained"
              color="accept"
              sx={{ color: 'white', mr: '20px', mt: '30px' }}
            >
              Submit
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default SessionSurveyB;
