/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import { useDropzone } from 'react-dropzone';

export default function UploadVideoDialog({
  open,
  setPreviewFile,
  setSelectedFile,
  selectedFile,
  isLoading,
  handleClose,
  saveVideo,
  video,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    const imageFile = acceptedFiles[0];

    if (imageFile instanceof File) {
      setSelectedFile(imageFile);
      setPreviewFile(URL.createObjectURL(imageFile));
    }

    // Do something with the files
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'video/mp4': ['.mp4'], 'video/mov': ['.mov'], 'video/avi': ['.avi'], 'video/webm': ['.webm'] },
    onDrop,

    minSize: 0,
    multiple: false,
  });
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          '& .MuiPaper-root': { minWidth: '792px', height: '600' },
        }}
      >
        <Box sx={{ textAlign: 'right', p: '10px', cursor: 'pointer' }} onClick={handleClose}>
          <CloseIcon />
        </Box>
        <DialogContent
          sx={{
            borderTop: 'none ',
          }}
        >
          <Box
            sx={{
              width: '100%',
              borderRadius: '10px',
              border: '2px solid #98D1FD',
              background: '#EDF7FF',
              height: '481px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {selectedFile && (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <video
                  key={typeof selectedFile === 'string' ? selectedFile : selectedFile.name}
                  controls
                  width="100%"
                  height="100%"
                >
                  <source
                    src={
                      JSON.stringify(selectedFile)?.includes('.s3.us-west-2.amazonaws')
                        ? `${selectedFile}?${Date.now()}`
                        : URL.createObjectURL(selectedFile)
                    }
                  />
                  Your browser does not support the video tag.
                </video>
              </Box>
            )}
            {!selectedFile && !video && (
              <Box sx={{ height: 'fit-content', my: 'auto' }}>
                <Box
                  component="img"
                  src="/assets/dashboard/videoUploadArrow.svg"
                  sx={{ height: '106px', width: '85px', mb: '30px', mx: 'auto' }}
                />
                <Typography
                  variant="mainTitles"
                  sx={{ mb: '5px', color: 'text.titles', display: 'block', textAlign: 'center' }}
                >
                  Please Upload Video
                </Typography>
                <Typography sx={{ lineHeight: '10px', mb: '30px', textAlign: 'center' }}>
                  Video size should not exceed from 50 mb.
                </Typography>

                <Button color="main" variant="upload" sx={{ mx: 'auto' }} {...getRootProps()}>
                  {' '}
                  Upload New Video
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: '24px 34.6px 30.32px 0' }}>
          <Box
            sx={{
              display: 'flex',
              columnGap:
                '10px                                                                                                                                                                ',
            }}
          >
            {!video && (
              <LoadingButton
                loading={isLoading}
                variant="outlined"
                color="main"
                sx={{ mr: '20px' }}
                onClick={() => {
                  setSelectedFile('');
                  setPreviewFile('');
                  handleClose();
                }}
              >
                Cancel
              </LoadingButton>
            )}
            {video && (
              <Button color="main" variant="upload" sx={{ mx: 'auto' }} {...getRootProps()}>
                {' '}
                Upload New Video
              </Button>
            )}

            <LoadingButton variant="contained" color="main" onClick={saveVideo} loading={isLoading}>
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
