// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Typography, CardContent } from '@mui/material';
// utils
import { trackTrainingJourneySubheader } from '../../../jsxStyles';
//
// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});



const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------


export default function BlogPostCard({ post, trainingJourney }) {
  const { img, title,  subTitle, totalComments } = post;

  return (
    <Grid item xs={12} sm={6} md={4} >
      <Card sx={{ position: 'relative' }}>
        <StyledCardMedia>
          <StyledCover alt={title} src={img} />
        </StyledCardMedia>

        <CardContent sx={{ p: '16px' }}>
          <StyledTitle
            color="inherit"
            variant="subtitle2"
            underline="hover"
            sx={{
              p: '0',
              fontSize: trainingJourney ? '16px' : '20px',
              fontWeight: 500,
              lineHeight: trainingJourney ? '20px' : 'normal',
            }}
          >
            {title}
          </StyledTitle>
          {trainingJourney && (
            <Typography sx={{ ...trackTrainingJourneySubheader }}>
              {subTitle} <li>{totalComments}</li>
            </Typography>
          )}

          {trainingJourney && (
            <Box sx={{ display: 'flex', columnGap: '5.24px', mt: '9.49px' }}>
              4.0 <Box component="img" src="/assets/reviewsImg.svg" />
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}
