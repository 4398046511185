// @mui
/*eslint-disable */
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Typography, CardContent, FormControlLabel, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

import { Marker } from 'react-mark.js';
import { months } from '../../../_mock';

// utils
import { Rankings } from '../../../components/reviews';

import { Menu } from '../../../components/dropdownMenu';

//

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

const StyledTitle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

export default function FindEdifierCard({
  data,
  setOpen,
  setItemToDeleteId,
  setEdifierToEditData,
  setEditEdifierOpen,
  manageCredentials,
  setManageCredentials,
  setUserToEdit,
  userRole,
  searchedValue,
  isInviteCard,
  setMeetingInvitePayload,
  isMeetingStatusModifying,
  meetingInvitePayload,
  setDisplayUserDetails,
  setCurrentUser,
  manageAvailability,
  setAvailabilityPayload,
  isChangingAvailability,
  availabilityPayload,
  setReviewsGiven,
  reviewsGiven,
  pairedUsers,
  rank,
  setEdifierRankings,
  edifierRankings,
  setRankedEdifierPayload,
  isSendingPairRequest,
  rankedEdifierPayload,
  notPaired,
}) {
  const { fname, lname, _id, title, email, role } = data;

  const [userAvailability, setUserAvailability] = useState(data?.isAvailable);

  const navigate = useNavigate();

  const inviteTime = new Date(data?.scheduleTime);

  const isUserRanked = () => {
    return rank && JSON.stringify(edifierRankings).includes(_id);
  };

  const structureRankedEdifierPayload = () => {
    Object.keys(edifierRankings).map((keys) => {
      if (edifierRankings[keys] === _id) {
        setRankedEdifierPayload({ id: _id, rank: parseInt(keys) });
      }
    });

    // structureRankedEdifierPayload
  };

  const discardEdifierRanking = () => {
    const restructuredRankings = {};
    Object.keys(edifierRankings).map((keys) => {
      if (edifierRankings[keys] !== _id) {
        restructuredRankings = { ...restructuredRankings, keys: edifierRankings[keys] };
      }
    });

    setEdifierRankings({ ...restructuredRankings });
  };

  const returnRoute = () => {
    if (pairedUsers) {
      return `/dashboard/user-profile?id=${_id}&role=${userRole}&pairedUsers=1`;
    }
    return `/dashboard/user-profile?id=${_id}&role=${userRole}`;
  };

  return (
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <Card
        sx={{
          position: 'relative',
          boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.06)',
          height: '100%',
          pb:
            userAvailability !== data?.isAvailable
              ? { xs: '80px', md: '50px' }
              : isUserRanked()
              ? { xs: '80px', md: '50px' }
              : 'inherit',
        }}
      >
        <StyledCardMedia sx={{ height: '250px', p: '0' }}>
          <StyledCover alt={title} src={data?.image ? data?.image : '/assets/ProfileAvatar.png'} />
        </StyledCardMedia>
        {userRole === 'teacher' && rank && notPaired && (
          <Rankings
            id={_id}
            setReviewsGiven={setReviewsGiven}
            reviewsGiven={reviewsGiven}
            setEdifierRankings={setEdifierRankings}
            edifierRankings={edifierRankings}
          />
        )}

        <CardContent sx={{ p: '0', pb: '0 !important' }}>
          <Box sx={{ p: '17.59px', pr: '0' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <StyledTitle
                color="inherit"
                variant="secondaryTitles"
                underline="hover"
                sx={{
                  fontWeight: 500,
                  lineHeight: 'normal',
                  height: 'fit-content',
                  my: 'auto',
                  display: 'block',
                  columnGap: '5px',
                  textTransform: 'capitalize',
                  textDecoration: 'none !important',
                  pointerEvents: 'none',
                }}
              >
                <Marker mark={searchedValue}>{`${fname?.toLowerCase()} ${lname?.toLowerCase()}`}</Marker>

                {/* <Highlight search={searchedValue}>{name} </Highlight> */}
                {!pairedUsers && (
                  <span style={{ fontSize: '12px', fontWeight: '400px', margin: 'auto 0' }}>
                    (
                    {role === 'COACH'
                      ? 'Edifier'
                      : isInviteCard
                      ? userRole.toLowerCase() === 'teacher'
                        ? 'Edifier'
                        : 'Teacher'
                      : role.toLowerCase()}
                    )
                  </span>
                )}

                {pairedUsers && (
                  <span style={{ fontSize: '12px', fontWeight: '400px', margin: 'auto 0' }}>
                    ({userRole.toLowerCase() === 'teacher' ? 'Edifier' : 'Teacher'})
                  </span>
                )}
              </StyledTitle>

              {manageAvailability && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={userAvailability}
                      onChange={(e) => setUserAvailability(e.target.checked)}
                      color="main"
                    />
                  }
                  label="Available"
                  sx={{
                    '& .MuiTypography-root': { color: 'black' },
                  }}
                />
              )}

              {(userRole === 'admin' || manageCredentials || userRole === 'site-leader') && (
                <Menu
                  setMenuOpen={setOpen}
                  setItemToDeleteId={setItemToDeleteId}
                  id={_id}
                  data={data}
                  setEdifierToEditData={setEdifierToEditData}
                  setEditEdifierOpen={setEditEdifierOpen}
                  manageCredentials={manageCredentials}
                  setManageCredentials={setManageCredentials}
                  setUserToEdit={setUserToEdit}
                />
              )}
              {(userRole === 'teacher' || userRole === 'coach') && (
                <StyledTitle
                  sx={{
                    color: 'black',
                    fontSize: '13px',
                    pr: '10px',
                    display: 'grid',
                    alignItems: 'center',
                    cursor: 'pointer',
                    minWidth: 'max-content',
                    textDecoration: 'none',
                  }}
                  onClick={() => navigate(returnRoute())}
                >
                  {(userRole === 'teacher' && rank && notPaired) ? 'Learn More About them' : 'Connect with them'}
                </StyledTitle>
              )}
            </Box>
            <Box sx={{ display: 'flex', columnGap: '4.48px', mt: '10px' }}>
              <Box component="img" src="/assets/emailIcon.svg" />
              <Typography
                variant="email"
                sx={{
                  display: 'block',
                  // overflowWrap: 'break-word',
                  // whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  pointerEvents: 'none ',
                }}
              >
                {' '}
                {email}
              </Typography>
            </Box>

            {/* {role === 'COACH' && (
              <>
                <Typography variant="specs" sx={{ mt: '15px' }}>
                  Score:
                </Typography>
                <Typography variant="specs" sx={{ color: '#8D71AC', fontWeight: 500 }}>
                  {data?.score}
                </Typography>
                <Typography variant="specs" sx={{ mt: '15px' }}>
                  Shared:
                </Typography>

                <Typography variant="specs" sx={{ color: '#8D71AC', fontWeight: 500 }}>
                  {data?.shared}
                </Typography>
              </>
            )} */}
            {isInviteCard && (
              <Typography sx={{ fontWeight: 700, fontSize: '12px', mt: '20px' }}>
                {`${inviteTime.getDate()} ${months[inviteTime.getMonth()]} ${days[inviteTime.getDay()]}  ${inviteTime
                  .toLocaleTimeString()
                  .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}`}
              </Typography>
            )}
            {(userRole === 'coach' || data?.role?.toLowerCase() === 'teacher') && (
              <Box>
                <Typography variant="specs" sx={{ mt: '15px', pointerEvents: 'none' }}>
                  Partner Affiliation:
                </Typography>

                <StyledTitle
                  sx={{
                    color: 'black',
                    fontSize: '13px',
                    display: 'grid',
                    alignItems: 'center',
                    cursor: 'pointer',
                    textDecoration: 'none',
                    height: 'fit-content',
                  }}
                >
                  {data?.info?.partnerSchool || 'NA'}
                </StyledTitle>
              </Box>
            )}

            {!isInviteCard && (
              <Typography variant="specs" sx={{ mt: '15px', pointerEvents: 'none' }}>
                Expertise:
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                columnGap: '3.87px',
                mt: '8.28px',
                flexWrap: 'wrap',
                rowGap: '10px',
                pr: '20px',
                pb: data?.info?.expertiesAndResources && '30px',
                pointerEvents: 'none',
              }}
            >
              {data?.info?.expertiesAndResources?.map(
                (item, key) =>
                  key <= 2 && (
                    <Box
                      key={key}
                      sx={{
                        background: '#EDF7FF',
                        border: '1px solid #98D1FD',
                        p: '3.08px 10.7px',
                        borderRadius: '3px',
                      }}
                    >
                      <Typography
                        variant="specs"
                        sx={{ color: '#8D71AC', fontWeight: 500, whiteSpace: 'break-spaces' }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  )
              )}
            </Box>
          </Box>
          {data?.info?.expertiesAndResources?.length > 3 && (
            <Box sx={{ p: '20px', position: 'absolute', bottom: 0, right: 0, height: '50px', cursor: 'pointer' }}>
              <Box
                component="img"
                src="/assets/eyeIcon.svg"
                sx={{ ml: 'auto' }}
                onClick={() => {
                  setDisplayUserDetails(true);
                  setCurrentUser(data);
                }}
              />
            </Box>
          )}
        </CardContent>
        {isInviteCard && (
          <Box sx={{ display: 'flex', columnGap: '10px', p: '10px', pb: '20px' }}>
            <LoadingButton
              variant="contained"
              color="accept"
              sx={{ color: 'white' }}
              onClick={() => setMeetingInvitePayload({ inviteId: data?._id, status: 'ACCEPTED' })}
              loading={meetingInvitePayload?.inviteId === data?._id && isMeetingStatusModifying}
            >
              Accept Invite
            </LoadingButton>
            <LoadingButton
              variant="contained"
              color="reject"
              sx={{ color: 'white' }}
              onClick={() => setMeetingInvitePayload({ inviteId: data?._id, status: 'REJECTED' })}
              loading={meetingInvitePayload?.inviteId === data?._id && isMeetingStatusModifying}
            >
              Reject Invite
            </LoadingButton>
          </Box>
        )}

        {manageAvailability && userAvailability !== data?.isAvailable && (
          <Box
            sx={{
              display: 'flex',
              columnGap: '20px',
              position: 'absolute',
              bottom: '20px',
              left: '20px',
              flexWrap: 'wrap',
              justifyContent: 'center',
              rowGap: '10px',
              pr: '17px',
            }}
          >
            <LoadingButton
              variant="contained"
              loading={isChangingAvailability && data?._id === availabilityPayload._id}
              // color="white"
              disabled={isChangingAvailability && data?._id !== availabilityPayload._id}
              onClick={() => setUserAvailability(data?.isAvailable)}
              sx={{ width: '118px' }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              // loading={isModifyingTimeSlots}
              loading={isChangingAvailability && data?._id === availabilityPayload._id}
              disabled={isChangingAvailability && data?._id !== availabilityPayload._id}
              color="main"
              onClick={(e) => setAvailabilityPayload({ _id: data?._id, isAvailable: userAvailability })}
              sx={{ width: '118px' }}
            >
              Save
            </LoadingButton>
          </Box>
        )}
        {isUserRanked() && (
          <Box sx={{ display: 'flex', columnGap: '20px', position: 'absolute', bottom: '20px', left: '20px' }}>
            <LoadingButton
              variant="contained"
              loading={isSendingPairRequest && JSON.stringify(rankedEdifierPayload).includes(_id)}
              disabled={isSendingPairRequest && JSON.stringify(rankedEdifierPayload).includes(_id) === false}
              onClick={() => discardEdifierRanking()}
              sx={{ width: '118px' }}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant="contained"
              loading={isSendingPairRequest && JSON.stringify(rankedEdifierPayload).includes(_id)}
              disabled={isSendingPairRequest && JSON.stringify(rankedEdifierPayload).includes(_id) === false}
              color="main"
              onClick={(e) => structureRankedEdifierPayload()}
              sx={{ width: '118px' }}
            >
              Save
            </LoadingButton>
          </Box>
        )}
      </Card>
    </Grid>
  );
}
