import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem } from './styles';

// ----------------------------------------------------------------------

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data?.map((item, index) => (
          <NavItem key={index} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------


function NavItem({ item }) {
  const { title, path, iconSrc, info, breadCrumbs } = item;

  
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        color: breadCrumbs && breadCrumbs.includes(window.location.pathname) ? 'text.secondary' : 'text.primary',
        textDecoration: 'none !important',
        pl: '17.4px',
        mb: '20px',
        outline: 'none !important',
        fontWeight: 'fontWeightRegularMedium',

        '&.active': {
          color: 'text.secondary',
          bgcolor: 'action.selected',
        },
        bgcolor: breadCrumbs && breadCrumbs.includes(window.location.pathname) && 'action.selected',

        pointerEvents: path === 'NA' && 'none',
        opacity: path === 'NA' && 0.3,
      }}
    >
      <Box component="img" src={iconSrc} sx={{ mr: '16.44px' }} />

      <ListItemText disableTypography primary={title} sx={{ fontSize: '14px' }} />

      {info && info}
    </StyledNavItem>
  );
}
