import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';

import { Select } from '../select';

const ManageCredentialsDialog = ({ toast, open, handleClose, isLoading, userToEdit, setEditedUserData }) => {
const roles= {"local lead":'site-leader',"edifier":"coach","teacher":'teacher'}
  const submit = (e) => {
    e.preventDefault();
    if (userToEdit?.role.toLowerCase() === e.target[0].value) {
      toast.error('Change the Role to Submit Data');
      return;
    }
    setEditedUserData({ id: userToEdit?._id, role: roles[e.target[0].value] });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiPaper-root': {
          width: { xs: '350px', sm: '649px' },
          height: 'max-content',
          borderRadius: '20PX',
        },
      }}
    >
      <Box sx={{textAlign:'right',p:'20px 20px 0 0'}}>
      <CloseIcon sx={{cursor:'pointer'}} onClick={(e)=>handleClose(e)}/>
      </Box>
      <DialogContent>
        <Typography variant="mainTitles" sx={{ lineHeight: '70px', color: 'text.titles', textAlign: 'center' }}>
          Change Role
        </Typography>

        <form onSubmit={submit}>
          <Select
            field={{ name: 'roles', label: 'Role' }}
            items={['Teacher', 'Edifier', 'Local Lead']}
            defaultValue={userToEdit?.role?.toLowerCase()}
          />

          <LoadingButton
            loading={isLoading}
            variant="contained"
            type="submit "
            color="main"
            sx={{ height: 'fit-content', my: 'auto', mt: '20px' }}
          >
            Submit
          </LoadingButton>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ManageCredentialsDialog;
