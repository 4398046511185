import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useSearchParams, useNavigate, useOutletContext } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ResetPasswordDialog, UploadVideoDialog } from '../components/dialog';

import {
  profileListDisplayItemsStyle,
  profileBoxContainer,
  availabilityTextStyles,
  userProfileBottomSectionStyles,
  expertiesAndResourcesStyles,
  userProfileImageStyles,
} from '../jsxStyles';
import { ChatFeature } from '../components/chat';
import { raceDisplayNames } from '../_mock';
import { getUserDetails } from '../utils';
import { useMutationChangeUserInfo } from '../hooks/react-query/useData';

const MyEdifierProfile = () => {
  const [searchParams] = useSearchParams();
  const isLargeScreen = useMediaQuery('(max-width:1500px)');

  const queryClient = useQueryClient();

  const userDetails = getUserDetails();
  const userToken = userDetails?.token;
  const pairedUsers = useOutletContext();
  const isCurrentUserProfile = window.location.href.includes('my-profile');
  const userRole = userDetails?.role.toLowerCase();
  const isPaired = !isCurrentUserProfile
    ? JSON.stringify(pairedUsers[6]?.data?.data)?.includes(searchParams.get('id'))
    : '';
  const thisUserRole = searchParams.get('role');
  const notManagementRole = userDetails?.role !== 'ADMIN' && userDetails?.role !== 'SITE-LEADER';
  const profileTitle = isCurrentUserProfile
    ? 'My Profile'
    : thisUserRole === 'teacher'
    ? 'My Edifier(s) Profile'
    : 'My Teacher(s) Profile';

  const allRoleData =
    thisUserRole === 'teacher'
      ? searchParams.get('pairedUsers')
        ? queryClient.getQueryData(['getPairedEdifiers'], { exact: false })?.data?.data
        : queryClient.getQueryData(['getCoachesList'], { exact: false })?.data?.data
      : queryClient.getQueryData(['getPairedEdifiers'], { exact: false })?.data?.data;

  const myRoleData = isCurrentUserProfile
    ? [userDetails]
    : allRoleData
    ? allRoleData?.filter((item) => item?._id === searchParams.get('id'))
    : [];

  const [openRestPassword, setOpenRestPassword] = useState(false);

  const [previewVideo, setPreviewVideo] = useState('');
  const [selectedVideo, setSelectedVideo] = useState(userDetails?.video || '');
  const [editData, setEditData] = useState({});

  const [openUploadVideo, setOpenUploadVideo] = useState(false);

  const [openChat, setOpenChat] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const navigate = useNavigate();

  const { isLoading, mutate } = useMutationChangeUserInfo(
    editData,
    toast,
    userToken,
    navigate,
    setEditData,
    setOpenRestPassword,
    setPreviewVideo,
    setSelectedVideo,
    setOpenUploadVideo
  );

  // const [rateCoachOpen, setRateCoachOpen] = useState(false);

  useEffect(() => {
    if (Object.keys(myRoleData).length <= 0) {
      const destination = thisUserRole === 'teacher' ? '/dashboard/find-edifiers' : '/dashboard/teachers';
      navigate(destination);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(editData).length > 0) {
      mutate();
    }
  }, [editData]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    // Handle image load error here, if needed
    setImageLoaded(true); // Set to true to hide loader even if there's an error
  };

  const handleClose = () => {
    setOpenRestPassword(false);
  };

  const saveVideo = () => {
    if (selectedVideo.size > 50 * 1024 * 1024) {
      toast.error('Please make sure Video size is lesser than 50MB');
      return;
    }
    const formData = new FormData();
    formData.append('video', selectedVideo);

    setEditData({ formData });
  };

  return (
    <Box>
      <UploadVideoDialog
        video={userDetails?.video}
        setPreviewFile={setPreviewVideo}
        selectedFile={selectedVideo}
        previewFile={previewVideo}
        setSelectedFile={setSelectedVideo}
        open={openUploadVideo}
        handleClose={() => setOpenUploadVideo(false)}
        isLoading={isLoading}
        saveVideo={saveVideo}
      />
      <ResetPasswordDialog
        setOpen={setOpenRestPassword}
        open={openRestPassword}
        handleClose={handleClose}
        editData={editData}
        setEditData={setEditData}
        isLoading={isLoading}
      />
      <ChatFeature
        open={openChat}
        setOpen={setOpenChat}
        id={searchParams.get('id')}
        recipientUser={myRoleData?.length && `${myRoleData[0]?.fname} ${myRoleData[0]?.lname}`}
      />
      {/* <RateCoach open={rateCoachOpen} setOpen={setRateCoachOpen} /> */}
      <Helmet>
        <title> Edifiying Teacher | {profileTitle} </title>
      </Helmet>

      <Typography
        variant="mainTitles"
        sx={{ mb: '5px', color: 'text.titles', display: 'block', ml: { xs: '20px', sm: '0' } }}
      >
        {profileTitle}
      </Typography>

      <Box
        sx={{
          ...profileBoxContainer,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            columnGap: '29px',
            p: { xs: '15px', sm: '30px 30px 20px 40px', md: '47.59px 44px 24px 69.2px' },
          }}
        >
          <Box
            component="img"
            src={myRoleData[0]?.image ? `${myRoleData[0]?.image}?${Date.now()}` : '/assets/ProfileAvatar.png'}
            sx={{
              ...userProfileImageStyles,
              display: imageLoaded ? 'block' : 'none',
              minWidth: { xs: '250px', sm: isLargeScreen ? '250px' : '326px' },

              maxWidth: '326px',
              height: isLargeScreen ? '250px' : '351px',
            }}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />

          {!imageLoaded && (
            <Box
              sx={{
                height: '250px',
                minWidth: { xs: '250px', sm: '326px' },
                display: 'grid',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                rowGap: '10px',

                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="large"
                sx={{ color: '#A94B9C', lineHeight: '48px', mt: { xs: '10px', sm: '0' }, textTransform: 'capitalize' }}
              >
                {!myRoleData[0]?.fname
                  ? myRoleData[0]?.email?.split('@')[0]
                  : `${myRoleData[0]?.fname} ${myRoleData[0]?.lname}`}
                <span style={{ fontWeight: 400, fontSize: '27px', marginLeft: '10px' }}>
                  ({notManagementRole ? (myRoleData[0]?.role === 'COACH' ? 'Edifier' : 'Teacher') : 'Admin'})
                </span>
                {isPaired && (
                  <Tooltip title={`Chat with ${userRole === 'coach' ? 'Teacher' : 'Edifier'}`}>
                    <Button
                      variant="contained"
                      color="main"
                      sx={{ fontSize: '16px', ml: '10px' }}
                      onClick={() => setOpenChat(true)}
                    >
                      <Box component="img" src="/assets/chatIcon.svg" sx={{ height: '25px', width: '25px' }} />
                    </Button>
                  </Tooltip>
                )}
              </Typography>

              {notManagementRole && isCurrentUserProfile && (
                <Box
                  sx={{
                    display: 'flex',
                    textAlign: 'right',
                    mb: { xs: '30px', md: '0' },
                    justifyContent: 'flex-end',
                    width: '100%',
                    columnGap: '10px',
                  }}
                >
                  <Button variant="contained" color="main" onClick={() => setOpenRestPassword(true)}>
                    Edit Profile
                  </Button>
                </Box>
              )}
              <Box sx={{ display: 'flex', columnGap: '15px', flexWrap: 'wrap', rowGap: '10px' }}>
                {isPaired && (
                  <Typography
                    variant="h5"
                    sx={{
                      ...availabilityTextStyles,
                    }}
                    onClick={() =>
                      navigate(`/dashboard/edifier-timeslots?id=${searchParams.get('id')}&name=${myRoleData[0]?.fname}`)
                    }
                  >
                    <Box component="img" src="/assets/scheduleIcon.svg" sx={{ my: 'auto' }} />
                    <span style={{ minWidth: 'max-content', textTransform: 'capitalize' }}>
                      Schedule a meeting with {myRoleData[0]?.fname.split(' ')[0]}
                    </span>
                  </Typography>
                )}
                {notManagementRole && (
                  <Box sx={{ display: 'flex', columnGap: '16.84px', alignItems: 'center' }}>
                    <Box
                      component="img"
                      src="/assets/locationIcon.svg"
                      sx={{ width: '22px', height: '26px', my: 'auto' }}
                    />

                    <Typography
                      variant="h5"
                      sx={{ fontWeight: 500, m: '0', textTransform: 'capitalize', minWidth: 'max-content' }}
                    >
                      {myRoleData[0]?.info?.city ? myRoleData[0]?.info?.city : 'NA'} ,{' '}
                      {myRoleData[0]?.info?.state ? myRoleData[0]?.info?.state : 'NA'}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Typography variant="h3" sx={{ mt: { xs: '20px', sm: '7px' }, lineHeight: '35px' }}>
              {myRoleData[0]?.info?.contentSpeciality}
            </Typography>
            {notManagementRole && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: '27px', mt: '24px' }}>
                <Typography variant="h5" sx={{ lineHeight: 'normal', my: 'auto', fontWeight: 600 }}>
                  Expertise
                </Typography>

                <Box sx={{ display: 'flex', columnGap: '14px', mt: '8.28px', flexWrap: 'wrap', rowGap: '10px' }}>
                  {myRoleData[0]?.info?.expertiesAndResources.map((item, key) => (
                    <Box
                      key={key}
                      sx={{
                        ...expertiesAndResourcesStyles,
                      }}
                    >
                      <Typography variant="specs" sx={{ color: '#8D71AC', fontWeight: 500, fontSize: '16px' }}>
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            {notManagementRole && (
              <Typography
                variant="h5"
                sx={{ color: 'black', fontWeight: '600px', lineHeight: 'normal', mt: '20px', mb: '10px' }}
              >
                My Advice:
              </Typography>
            )}
            <Typography
              variant="secondaryTitles"
              sx={{ fontWeight: 300, lineHeight: '31px', color: '#282A39', maxWidth: '670px' }}
            >
              {myRoleData[0]?.info?.encouragementAdvice}
            </Typography>

            {notManagementRole && (
              <Typography variant="h5" sx={{ color: 'black', fontWeight: '600px', lineHeight: 'normal', mt: '20px' }}>
                My Goal:
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: '20px',
                flexDirection: isLargeScreen ? 'column' : 'row',
                rowGap: '20px',
              }}
            >
              <Typography
                variant="hightLightedText"
                sx={{ mt: '10px', width: isLargeScreen ? '100%' : '80%', height: 'max-content' }}
              >
                {myRoleData[0]?.info?.interestBio === 'test'
                  ? "This user didn't set up any bio yet"
                  : myRoleData[0]?.info?.interestBio
                  ? myRoleData[0]?.info?.interestBio
                  : notManagementRole
                  ? 'No Bio Yet'
                  : 'Admin at Edifying Teachers'}
              </Typography>
              {notManagementRole && isCurrentUserProfile && (
                <LoadingButton
                  color="main"
                  variant="video"
                  onClick={() => setOpenUploadVideo(true)}
                  sx={{ mx: 'auto' }}
                >
                  <Box
                    component="img"
                    src={
                      userDetails?.video ? '/assets/dashboard/play-icon.png' : '/assets/dashboard/videoUploadArrow.svg'
                    }
                    sx={{ mx: 'auto', mb: '10px' }}
                  />

                  {userDetails?.video ? 'Play Video' : 'Add Video'}
                </LoadingButton>
              )}
            </Box>
          </Box>
        </Box>

        {notManagementRole && (
          <Box
            sx={{
              p: { xs: '15px', sm: '33px 49px 22px 49px' },
              boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
              background: '#EDF7FF',
            }}
          >
            <Typography variant="h5" sx={{ lineHeight: 'normal', color: '#8E71AD', mb: { xs: '10px', sm: '0' } }}>
              Accolades
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, columnGap: '74px' }}>
              <ul style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <li style={{ ...profileListDisplayItemsStyle, textTransform: 'capitalize' }}>
                  <b> Nationally Board Certified?</b> &nbsp;{myRoleData[0]?.info?.nbctCertified}
                </li>
                <li style={{ ...profileListDisplayItemsStyle }}>
                  <b> Special Accolades:</b>&nbsp;
                  {myRoleData[0]?.info?.specialAccolades || 'N/A'}
                </li>
              </ul>
              <ul>
                <li style={{ ...profileListDisplayItemsStyle, minWidth: 'max-content', maxWidth: 'inherit' }}>
                  <Box sx={{ display: 'flex' }}>
                    {' '}
                    <b> Race:</b>&nbsp;
                    <Box sx={{ display: 'flex', maxWidth: '500px', flexWrap: 'wrap' }}>
                      {myRoleData[0]?.info?.ethnoRacial.map((item, key) => (
                        <Box key={key}>{raceDisplayNames[item]}, &nbsp;</Box>
                      ))}
                    </Box>
                  </Box>
                </li>

                <li style={{ ...profileListDisplayItemsStyle, textTransform: 'capitalize' }}>
                  <b> Current Status:</b>&nbsp;{userRole === 'coach' ? 'Mentee' : 'Edifier'}
                </li>
                <li style={{ ...profileListDisplayItemsStyle, textTransform: 'capitalize' }}>
                  <b> District Type:</b>&nbsp;{myRoleData[0]?.info?.districtSchool}
                </li>
                <li style={{ ...profileListDisplayItemsStyle }}>
                  <b> Partner Affiliation:</b> &nbsp;
                  {myRoleData[0]?.info?.partnerSchool || 'N/A'}
                </li>
              </ul>
            </Box>
          </Box>
        )}
        {notManagementRole && (
          <Box
            sx={{
              ...userProfileBottomSectionStyles,
            }}
          >
            <Box>
              <Typography variant="h5" sx={{ color: 'white', fontWeight: '600px', lineHeight: 'normal' }}>
                Why I Mentor?
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: 'white', fontWeight: '500', lineHeight: '24px', mt: '10px', maxWidth: '675px' }}
              >
                {myRoleData[0]?.info?.whatMotivates ? myRoleData[0]?.info?.whatMotivates : 'NA'}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MyEdifierProfile;
