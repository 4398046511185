import { useState } from 'react';
// @mui
import { Badge, Popover, IconButton } from '@mui/material';
import NotificationsDropdownContent from './NotificationsDropdownContent';

import { getUserDetails } from '../../../utils';
import NotificationItem from './NotificationItem';
import { overrideBadgeStyles } from '../../../jsxStyles';

// utils
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function NotificationsPopover({ data }) {


  const userDetails = getUserDetails();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const doesUnreadNotificationsExist = () =>
    data?.data?.data ? JSON.stringify(data?.data?.data)?.split(false).length - 1 : '';

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge
          badgeContent={doesUnreadNotificationsExist()}
          color="primary"
          sx={{
            '& .MuiBadge-badge': {
              ...overrideBadgeStyles,
              height: doesUnreadNotificationsExist() && '20px',
              width: doesUnreadNotificationsExist() && '20px',
            },
          }}
        >
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <NotificationsDropdownContent
          NotificationItem={NotificationItem}
          token={userDetails?.token}
          handleClose={handleClose}
          allNotificationsData={data?.data?.data}
        />
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------
