import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Box } from '@mui/material';
// hooks

import Onboarding from './OnBoarding';

// components
import Logo from '../components/logo';

// sections
import { LoginForm } from '../sections/auth/login';
// ----------------------------------------------------------------------


const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  marginTop: '0',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  paddingTop: '20px',
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const [selectedForm, setSelectedForm] = useState('');

  


  return (
    <>
      <Helmet>
        <title> OnBoard | Edifying Teachers </title>

        <title>
          {selectedForm === ''
            ? 'OnBoard|Edifying Teachers'
            : `Edifying Teachers | Onboard-${selectedForm === 'teacher' ? 'Mentee' : 'Edifier'}`}{' '}
        </title>
      </Helmet>
      {selectedForm === '' && (
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />
      )}

      <StyledRoot sx={{ background: '#EDF6F3' }}>
        <Container
          sx={{ maxWidth: selectedForm !== '' && '100% !important', padding: '0 !important', minWidth: '100%' }}
        >
          <StyledContent sx={{ maxWidth: selectedForm !== '' ? '100%' : '480' }}>
            {selectedForm === '' && <LoginForm setSelectedForm={setSelectedForm} />}

            <Box sx={{ width: '100%' }}>
              {selectedForm === 'teacher' && <Onboarding goBack setSelectedForm={setSelectedForm} />}
              {selectedForm === 'coach' && <Onboarding isOnlyEdifier goBack setSelectedForm={setSelectedForm} />}
            </Box>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
