/* eslint-disable */

import { useEffect, useState } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';

const useGoogleSignIn = (accessUserCalendar, isEdifier, setCurrentUserGoogleEvents) => {
  const [profile, setProfile] = useState({});
  const [user, setUser] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      localStorage.setItem('google_access_token', codeResponse.access_token);

      accessUserCalendar(codeResponse.access_token, setCurrentUserGoogleEvents);
      // window.location.href = '/dashboard';
    },
    onError: (error) => localStorage.removeItem('google_access_token'),
    scope: 'https://www.googleapis.com/auth/calendar.readonly',
    expiresIn: 259200, // 3 days

  });

  // Your client ID obtained from the Google Developer Console
  useEffect(() => {
    if (localStorage.getItem('google_access_token') && isEdifier) {
      (async () => {
        const calendarEventsResponse = await accessUserCalendar(
          localStorage.getItem('google_access_token'),
          setCurrentUserGoogleEvents
        );

        if (calendarEventsResponse?.eventsFound) {
          setUser({ access_token: localStorage.getItem('google_access_token') });
        }
      })().catch((err) => {
        console.error(err);
      });
    }
  }, []);

  const logOut = () => {
    googleLogout();
    setProfile(null);

    localStorage.removeItem('google_access_token');
    window.location.href = '/';
  };

  return { login, logOut, user, profile };
};

export default useGoogleSignIn;
