import React from 'react';
import { Grid, Container, Typography, Box, Button } from '@mui/material';
import { Card, EarnedBadges } from '../sections/@dashboard/earn-badge';
import { certificationsData, certificationsImagesData, earnedBadgesData } from '../_mock';
import { certificatesContainerStyles } from '../jsxStyles';

const FindAnEdifier = () => (
    <Box>
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
              Training Sessions
            </Typography>
            {/* <Typography variant="subtitles" sx={{ display: 'flex' }}>
              <Box component="img" src="/assets/calendarIcon.svg" sx={{ mr: '7.32px' }} />
              Member since July 11, 2021
            </Typography> */}
          </Box>
          <Button variant="contained" color="main" sx={{ height: 'fit-content', my: 'auto' }}>
            Check Out
          </Button>
        </Box>

        <Grid container spacing={'28.47px'} xs={12} sx={{ mt: '31px' }}>
          {certificationsData.map((post, index) => (
            <Card key={post.id} post={post} index={index} trainingJourney />
          ))}
        </Grid>

        <Typography
          variant="mainTitles"
          sx={{ color: 'text.titles', display: 'block', mt: '54px', lineHeight: '70px' }}
        >
          Certifications
        </Typography>
        <Grid container spacing={'28.47px'} xs={12}>
          {certificationsImagesData.map((img, key) => (
            <Grid key={key} item sm={6} xs={12}>
              <Box
                
                sx={{
                  ...certificatesContainerStyles,
                }}
              >
                <Box component="img" src={img} sx={{ mx: 'auto' }} />
              </Box>
            </Grid>
          ))}
        </Grid>

        <Typography
          variant="mainTitles"
          sx={{ color: 'text.titles', display: 'block', mt: '26.35px', mb: '21.83px', lineHeight: '70px' }}
        >
          Earned Badges
        </Typography>
        <Grid container spacing={'24.06px'}>
          {earnedBadgesData.map((item,key) => (
            <Grid item xs={12} sm={3} key={key}>
              <EarnedBadges item={item} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  
)

export default FindAnEdifier;
