/* eslint-disable */
import React from 'react';
import { Typography, Button, Box, Grid, TextField, FormControlLabel, Checkbox } from '@mui/material';
import { nbctCertifiedDropdown, expertiesAndResources, typesOfResources, typeOfResources } from '../../_mock';

import { CustomTextField } from '../textfield';
import { Select } from '../select';
import {
  cardBoxStyles,
  ifRetiredTypographyStyles,
  typesOfResourcesTypographyStyles,
  contentAreaTypographyStyles,
  gradeLevelTypographyStyles,
  multiCheckboxTypographyStyles,
  typographyMultiCheckboxTitleStyles,
  stepPageTitleStyles,
  multiCheckBoxMainTitleStyles,
} from '../../jsxStyles';

const OnboardingStep3 = ({
  edifierToEditData,
  register,
  errors,
  formOtherDefaultValues,
  teachersOnboardingTextFields,
  teachingExperienceItems,
  setCheckboxGroupSelection,
  checkboxGroupSelection,
  onboardingEarnedDegreesCheckboxData,
  programTypeMock,
  institutionDataMocks,
  isOnlyEdifier,
  setActiveStep,
  setValue,
  watch,
}) => {
  const teachingMapItems = isOnlyEdifier ? teachingExperienceItems : teachingExperienceItems.slice(0, 1);

  const setCheckboxValues = (item, attributeName) => {
    if (checkboxGroupSelection[attributeName].includes(item)) {
      const existingValues = checkboxGroupSelection[attributeName];

      const modifiedArray = existingValues.filter(function (arrItem) {
        return arrItem !== item;
      });

      setCheckboxGroupSelection({ ...checkboxGroupSelection, [attributeName]: modifiedArray });
      return;
    }

    if (attributeName === 'educatorProgramType' || attributeName === 'institution') {
      setCheckboxGroupSelection({
        ...checkboxGroupSelection,
        [attributeName]: [item],
      });
      return;
    }
    setCheckboxGroupSelection({
      ...checkboxGroupSelection,
      [attributeName]: [...checkboxGroupSelection[attributeName], item],
    });
  };

  return (
    <Box
      sx={{
        ...cardBoxStyles,
      }}
    >
      <Typography
        sx={{
          ...stepPageTitleStyles,
        }}
        variant="form"
      >
        Professional Experience
      </Typography>
      <Grid container sx={{ mt: '30px' }}>
        <Grid item xs={12}>
          <CustomTextField
            id="yearsClassroomTeaching"
            name="yearsClassroomTeaching"
            type="number"
            setValue={setValue}
            label="Years of Classroom Teaching Experience*"
            largeLabel
            register={register}
            isRequired={true}
            watch={watch}
            errors={errors}
            defaultValue={formOtherDefaultValues?.yearsClassroomTeaching}
            formOtherDefaultValues={formOtherDefaultValues}
            edifierToEditData={edifierToEditData}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: '30px', display: 'grid', gridTemplateColumns: { xs: 'auto', md: 'repeat(4,auto)' } }}>
        <Typography
          variant="form"
          sx={{
            mr: '25px',
            display: 'grid',
            alignItems: 'center',
            lineHeight: '20px',
            fontWeight: 500,
            color: errors && !!errors?.degrees && checkboxGroupSelection.degrees.length <= 0 && 'red',
          }}
        >
          Degrees earned (check all that apply)
        </Typography>
        {onboardingEarnedDegreesCheckboxData.map((item, key) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox
                checked={checkboxGroupSelection.degrees.includes(item.name)}
                {...register('degrees', {
                  validate: () => {
                    // your validation logic here :)
                    return checkboxGroupSelection.degrees.length > 0;
                  },
                })}
                sx={{
                  color: '#ADADAD',
                }}
                label={item.name}
                onChange={(e) => {
                  setCheckboxValues(item.name, 'degrees');
                }}
              />
            }
            label={item.label}
          />
        ))}

        {errors && !!errors?.degrees && checkboxGroupSelection.degrees.length <= 0 && (
          <Typography variant="validation">Degrees required</Typography>
        )}
      </Box>

      <Box sx={{ mt: '10px', display: 'grid', gridTemplateColumns: { xs: 'auto', lg: 'repeat(3,auto)' } }}>
        <Typography
          variant="form"
          sx={{
            mr: '34px',
            display: 'grid',
            alignItems: 'center',
            lineHeight: '20px',
            fontWeight: 500,
            color:
              errors &&
              !!errors?.educatorProgramType &&
              checkboxGroupSelection.educatorProgramType.length <= 0 &&
              'red',
          }}
        >
          Educator Preparation Program Type
        </Typography>

        {programTypeMock.map((pType, index) => (
          <FormControlLabel
            key={index}
            sx={{ mr: '22px' }}
            control={
              <Checkbox
                checked={checkboxGroupSelection.educatorProgramType.includes(pType.name)}
                {...register('educatorProgramType', {
                  validate: () => {
                    // your validation logic here :)
                    return checkboxGroupSelection.educatorProgramType.length > 0;
                  },
                })}
                sx={{
                  color: '#ADADAD',
                }}
                name={pType.name}
                onChange={(e) => setCheckboxValues(pType.name, 'educatorProgramType')}
              />
            }
            label={pType.label}
          />
        ))}

        {errors && !!errors?.educatorProgramType && checkboxGroupSelection.educatorProgramType.length <= 0 && (
          <Typography variant="validation">Preparation Program is required</Typography>
        )}
      </Box>

      {checkboxGroupSelection?.educatorProgramType.includes('traditional') && (
        <Box>
          <Typography
            sx={{
              ...multiCheckBoxMainTitleStyles,
            }}
            variant="subtitle1"
          >
            Which of the following best describes the institution where you completed your teacher training{' '}
          </Typography>

          {institutionDataMocks.map((institutes, key) => (
            <FormControlLabel
              key={key}
              sx={{ display: 'block' }}
              control={
                <Checkbox
                  checked={checkboxGroupSelection?.institution.includes(institutes.name)}
                  sx={{
                    color: '#ADADAD',
                  }}
                  {...register('institution', {
                    validate: () => {
                      // your validation logic here :)
                      return checkboxGroupSelection?.institution.length > 0;
                    },
                  })}
                  onChange={(e) => setCheckboxValues(institutes.name, 'institution')}
                  name={institutes.name}
                />
              }
              label={institutes.label}
            />
          ))}

          {errors && !!errors?.institution && checkboxGroupSelection.institution.length <= 0 && (
            <Typography variant="validation">Institution is required</Typography>
          )}
        </Box>
      )}
      <Grid container spacing="13px" sx={{ mt: '44px', rowGap: '40px', alignItems: 'flex-end' }}>
        {teachersOnboardingTextFields.slice(15, 17).map((field, key) => (
          <Grid item xs={12} key={key}>
            <CustomTextField
              largeLabel
              validationLabel={field?.validationLabel}
              watch={watch}
              id={field.name}
              name={field.name}
              label={field.label}
              register={register}
              isRequired={field.name === 'areaTeaching' || field.name === 'contentSpeciality'}
              errors={errors}
              type={field.type}
              defaultValue={formOtherDefaultValues[field.name]}
              formOtherDefaultValues={formOtherDefaultValues}
              edifierToEditData={edifierToEditData}
              setValue={setValue}
              pattern={field?.pattern}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container sx={{ mt: '30px' }} spacing="20px">
        <Grid item xs={12} md={6}>
          <span style={{ fontSize: '12px' }}>Are you a National Board Certified Teacher (NBCT)?</span>
          {!edifierToEditData || 'nbctCertified' in formOtherDefaultValues === false ? (
            <Select
              // label={'Are you a National Board Certified Teacher (NBCT)?'}
              register={register}
              field={{ name: 'nbctCertified', label: 'Select One', requiredLabel: 'NBCT Certification is required' }}
              errors={errors}
              name={'nbctCertified'}
              items={nbctCertifiedDropdown}
              watch={watch}
              defaultValue={watch('nbctCertified') || ''}
              onChange={(e) => setValue('nbctCertified', e.target.value)}
            />
          ) : (
            formOtherDefaultValues?.nbctCertified && (
              <Select
                label={'Are you a National Board Certified Teacher (NBCT)?'}
                register={register}
                field={{ name: 'nbctCertified', label: 'Select One', requiredLabel: 'NBCT Certification is required' }}
                errors={errors}
                watch={watch}
                name={'nbctCertified'}
                items={nbctCertifiedDropdown}
                defaultValue={
                  !watch('nbctCertified') ? formOtherDefaultValues?.nbctCertified : watch('nbctCertified') || false
                }
                onChange={(e) => setValue('nbctCertified', e.target.value)}
              />
            )
          )}
        </Grid>

        <Grid item xs={12} md={6} sx={{ display: 'grid', alignItems: 'flex-end' }}>
          <Box>
            {isOnlyEdifier && (
              <Grid item xs={12} sm={12}>
                <CustomTextField
                  watch={watch}
                  largeLabel
                  id="specialAccolades"
                  name="specialAccolades"
                  label="Special Accolades (e.g., Teacher of the Year, etc.)*"
                  validationLabel="Special Accolades are required"
                  register={register}
                  isRequired={true}
                  setValue={setValue}
                  errors={errors}
                  type="text"
                  placeholder="Your Answer"
                  formOtherDefaultValues={formOtherDefaultValues}
                  edifierToEditData={edifierToEditData}
                  defaultValue={edifierToEditData && formOtherDefaultValues?.specialAccolades}
                />
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing="20px" sx={{ mt: '10px' }}>
        {teachersOnboardingTextFields.slice(9, 11).map((field, key) =>
          !edifierToEditData || field.name in formOtherDefaultValues === false ? (
            <Grid item xs={12} sm={6} md={4} key={key} sx={{ mt: 'auto', display: 'grid', alignItems: 'flex-end' }}>
              <Select
                isOnlyEdifier={isOnlyEdifier}
                register={register}
                field={{ ...field, label: 'Select One' }}
                errors={errors}
                name={field.name}
                watch={watch}
                defaultValue={watch(field.name) || ''}
                onChange={(e) => setValue(field.name, e.target.value)}
                items={field.items}
              />
            </Grid>
          ) : (
            formOtherDefaultValues[field.name] && (
              <Grid item xs={12} sm={6} md={4} key={key} sx={{ display: 'grid', alignItems: 'flex-end' }}>
                <Select
                  isOnlyEdifier={isOnlyEdifier}
                  register={register}
                  field={{ ...field, label: 'Select One' }}
                  watch={watch}
                  errors={errors}
                  name={field.name}
                  items={field.items}
                  defaultValue={!watch(field.name) ? formOtherDefaultValues[field.name] : watch(field.name) || false}
                  onChange={(e) => setValue(field.name, e.target.value)}
                />
              </Grid>
            )
          )
        )}

        <Grid item xs={12} sm={6} md={4} sx={{ display: 'grid', alignItems: 'flex-end' }}>
          <Box>
            <span style={{ fontSize: '12px' }}>City</span>
            <CustomTextField
              validationLabel="City is required"
              watch={watch}
              id="city"
              name="city"
              setValue={setValue}
              label=""
              register={register}
              type="text"
              isRequired={true}
              errors={errors}
              formOtherDefaultValues={formOtherDefaultValues}
              edifierToEditData={edifierToEditData}
              defaultValue={formOtherDefaultValues?.city}
            />
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container spacing="20px" sx={{ mt: '10px', display: 'none' }}>
        <Grid item xs={12} sm={6}>
          {!edifierToEditData || 'currentStatus' in formOtherDefaultValues === false ? (
            <Select
              label={'Current Status*'}
              register={register}
              field={{ name: 'currentStatus', label: 'Current Status*' }}
              errors={errors}
              name={'currentStatus'}
              items={['Teaching', 'Previously Taught (Currently in Another Role)', 'Retired']}
              onChange={(e) => {
                setValue('currentStatus', e.target.value);
              }}
              watch={watch}
              defaultValue={
                !watch('currentStatus')
                  ? formOtherDefaultValues?.currentStatus && formOtherDefaultValues?.currentStatus
                  : watch('currentStatus')
              }
            />
          ) : (
            formOtherDefaultValues?.currentStatus && (
              <Select
                label={'Current Status*'}
                register={register}
                field={{ name: 'currentStatus', label: 'Current Status*' }}
                errors={errors}
                name={'currentStatus'}
                items={['Teaching', 'Previously Taught (Currently in Another Role)', 'Retired']}
                defaultValue={!watch('currentStatus') ? formOtherDefaultValues?.currentStatus : watch('currentStatus')}
                onChange={(e) => setValue('currentStatus', e.target.value)}
                watch={watch}
              />
            )
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          {!edifierToEditData ? (
            <Select
              label={'Current Role'}
              register={register}
              field={{ ...teachersOnboardingTextFields[3], label: 'Current Role' }}
              errors={errors}
              name={teachersOnboardingTextFields[3].name}
              items={isOnlyEdifier ? ['Coach', 'Teacher'] : ['Teacher', 'Coach']}
              disabled={true}
              watch={watch}
              onChange={(e) => setValue('currentStatus', e.target.value)}
              defaultValue={!watch('currentStatus') ? formOtherDefaultValues?.currentStatus : watch('currentStatus')}
            />
          ) : (
            edifierToEditData?.role && (
              <Select
                label={'Current Role'}
                register={register}
                field={{ ...teachersOnboardingTextFields[3], label: 'Current Role' }}
                errors={errors}
                name={teachersOnboardingTextFields[3].name}
                // items={['Teacher', 'Coach']}
                items={isOnlyEdifier ? ['Coach', 'Teacher'] : ['Teacher', 'Coach']}
                disabled={true}
                defaultValue={edifierToEditData && edifierToEditData?.role.toLowerCase()}
                watch={watch}
                onChange={(e) => setValue('currentStatus', e.target.value)}
              />
            )
          )}
        </Grid>
      </Grid> */}

      <Typography
        sx={{
          ...gradeLevelTypographyStyles,
        }}
        variant="form"
      >
        {isOnlyEdifier
          ? 'With which grade level(s) do you have teaching experience?'
          : 'With which grade level(s) are you currently teaching? (Check all that apply.)'}
        <span style={{ fontSize: '14px' }}> </span>
      </Typography>

      {teachersOnboardingTextFields[23].gradesTeaching.map((item, index) => (
        <Box
          key={index}
          sx={{
            mb: { xs: '25px', md: 'inherit' },
            display: { xs: 'block', md: 'flex' },
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              width: '180px',
              fontSize: '18px',
              lineHeight: '30px',
              fontWeight: 300,
              display: 'grid',
              alignItems: 'center',
            }}
            variant="form"
          >
            {item}
          </Typography>

          {index === 0 &&
            teachingMapItems.map((subItem, subIndex) => (
              <Typography
                key={subIndex}
                sx={{
                  width: '156px',
                  mb: '24px',
                  fontWeight: 500,
                  color: '#000',
                  minWidth: 'max-content',
                  display: { xs: 'none', md: 'inherit' },
                }}
                variant="subtitle1"
              >
                {subItem}
              </Typography>
            ))}

          {index !== 0 && (
            <Box sx={{ display: 'flex', columnGap: '10px' }}>
              <Typography
                sx={{ display: { xs: 'grid', md: 'none' }, width: { xs: '250px', md: '170px' }, alignItems: 'center' }}
              >
                Currently Teaching
              </Typography>
              <FormControlLabel
                sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                control={
                  <Checkbox
                    checked={checkboxGroupSelection?.teaching[item]?.teaching}
                    sx={{
                      color: '#ADADAD',
                    }}
                    name={`${item}-teaching`}
                    onChange={(e) => {
                      setCheckboxGroupSelection({
                        ...checkboxGroupSelection,
                        teaching: {
                          ...checkboxGroupSelection.teaching,
                          [item]: {
                            ...checkboxGroupSelection.teaching[item],
                            teaching: e.target.checked,
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </Box>
          )}
          {isOnlyEdifier && index !== 0 && (
            <Box sx={{ display: 'flex', columnGap: '10px' }}>
              <Typography
                sx={{ display: { xs: 'grid', md: 'none' }, alignItems: 'center', width: { xs: '250px', md: '170px' } }}
              >
                Currently Coaching
              </Typography>
              <FormControlLabel
                sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                control={
                  <Checkbox
                    checked={checkboxGroupSelection?.teaching[item]?.coaching}
                    sx={{
                      color: '#ADADAD',
                    }}
                    name={`${item}-coaching`}
                    onChange={(e) => {
                      setCheckboxGroupSelection({
                        ...checkboxGroupSelection,
                        teaching: {
                          ...checkboxGroupSelection.teaching,
                          [item]: {
                            ...checkboxGroupSelection.teaching[item],
                            coaching: e.target.checked,
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </Box>
          )}
          {isOnlyEdifier && index !== 0 && (
            <Box sx={{ display: 'flex', columnGap: '10px' }}>
              <Typography
                sx={{ display: { xs: 'grid', md: 'none' }, alignItems: 'center', width: { xs: '250px', md: '170px' } }}
              >
                Previously Taught
              </Typography>
              <FormControlLabel
                sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                control={
                  <Checkbox
                    sx={{
                      color: '#ADADAD',
                    }}
                    checked={checkboxGroupSelection?.teaching[item]?.prevTaught}
                    name={`${item}-previouslyTaught`}
                    onChange={(e) => {
                      setCheckboxGroupSelection({
                        ...checkboxGroupSelection,
                        teaching: {
                          ...checkboxGroupSelection.teaching,
                          [item]: {
                            ...checkboxGroupSelection.teaching[item],
                            prevTaught: e.target.checked,
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </Box>
          )}
        </Box>
      ))}

      <Typography
        sx={{
          ...contentAreaTypographyStyles,
        }}
        variant="form"
      >
        {isOnlyEdifier
          ? 'With which content area(s) do you have teaching experience? (Check all that apply.)'
          : 'In which content area(s) are you currently teaching? (Check all that apply.)'}
        <span style={{ fontSize: '14px' }}> </span>
      </Typography>

      {teachersOnboardingTextFields[24].contentAreaTeaching.map((item, index) => (
        <Box
          key={index}
          sx={{
            mb: { xs: '25px', md: 'inherit' },
            display: { xs: 'block', md: 'flex' },
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              width: { xs: '100%', md: '204px' },
              fontSize: '18px',
              fontWeight: 300,
              display: 'grid',
              alignItems: 'center',
              lineHeight: '25px',
            }}
            variant="form"
          >
            {item}
          </Typography>

          {index === 0 &&
            teachingMapItems.map((subItem, subIndex) => (
              <Typography
                key={subIndex}
                sx={{
                  width: '156px',
                  mb: '24px',
                  fontWeight: 500,
                  color: '#000',
                  minWidth: 'max-content',
                  display: { xs: 'none', md: 'inherit' },
                }}
                variant="subtitle1"
              >
                {subItem}
              </Typography>
            ))}

          {index !== 0 && (
            <Box sx={{ display: 'flex', columnGap: '10px' }}>
              <Typography
                sx={{ display: { xs: 'grid', md: 'none' }, width: { xs: '250px', md: '170px' }, alignItems: 'center' }}
              >
                Currently Teaching
              </Typography>
              <FormControlLabel
                sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                control={
                  <Checkbox
                    checked={checkboxGroupSelection.contentAreaTeaching[item].teaching}
                    sx={{
                      color: '#ADADAD',
                    }}
                    name={`${item}-teaching`}
                    onChange={(e) => {
                      setCheckboxGroupSelection({
                        ...checkboxGroupSelection,
                        contentAreaTeaching: {
                          ...checkboxGroupSelection.contentAreaTeaching,
                          [item]: {
                            ...checkboxGroupSelection.contentAreaTeaching[item],
                            teaching: e.target.checked,
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </Box>
          )}
          {isOnlyEdifier && index !== 0 && (
            <Box sx={{ display: 'flex', columnGap: '10px' }}>
              <Typography
                sx={{ display: { xs: 'grid', md: 'none' }, alignItems: 'center', width: { xs: '250px', md: '170px' } }}
              >
                Currently Coaching
              </Typography>
              <FormControlLabel
                sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                control={
                  <Checkbox
                    checked={checkboxGroupSelection.contentAreaTeaching[item].coaching}
                    sx={{
                      color: '#ADADAD',
                    }}
                    name={`${item}-coaching`}
                    onChange={(e) => {
                      setCheckboxGroupSelection({
                        ...checkboxGroupSelection,
                        contentAreaTeaching: {
                          ...checkboxGroupSelection.contentAreaTeaching,
                          [item]: {
                            ...checkboxGroupSelection.contentAreaTeaching[item],
                            coaching: e.target.checked,
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </Box>
          )}
          {isOnlyEdifier && isOnlyEdifier && index !== 0 && (
            <Box sx={{ display: 'flex', columnGap: '10px' }}>
              <Typography
                sx={{ display: { xs: 'grid', md: 'none' }, alignItems: 'center', width: { xs: '250px', md: '170px' } }}
              >
                Previously Taught
              </Typography>
              <FormControlLabel
                sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                control={
                  <Checkbox
                    sx={{
                      color: '#ADADAD',
                    }}
                    checked={checkboxGroupSelection.contentAreaTeaching[item].prevTaught}
                    name={`${item}-previouslyTaught`}
                    onChange={(e) => {
                      setCheckboxGroupSelection({
                        ...checkboxGroupSelection,
                        contentAreaTeaching: {
                          ...checkboxGroupSelection.contentAreaTeaching,
                          [item]: {
                            ...checkboxGroupSelection.contentAreaTeaching[item],
                            prevTaught: e.target.checked,
                          },
                        },
                      });
                    }}
                  />
                }
              />
            </Box>
          )}
        </Box>
      ))}

      {watch('currentStatus') === 'retired' && (
        <Box sx={{ mt: '30px' }}>
          <Typography
            variant="form"
            sx={{
              ...ifRetiredTypographyStyles,
            }}
          >
            If Retired: With which content area(s) do you have teaching experience? (Check all that apply.)
          </Typography>
          {teachersOnboardingTextFields[24].contentAreaTeaching.map(
            (item, key) =>
              key !== 0 && (
                <FormControlLabel
                  sx={{ display: 'block' }}
                  key={key}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection.ifRetired.includes(item)}
                      sx={{
                        color: '#ADADAD',
                      }}
                      label={item}
                      onChange={(e) => {
                        setCheckboxValues(item, 'ifRetired');
                      }}
                    />
                  }
                  label={item}
                />
              )
          )}
        </Box>
      )}

      <Box sx={{ mt: '30px' }}>
        <Typography
          variant="form"
          sx={{
            mr: '25px',
            lineHeight: '20px',
            fontWeight: 'bold',
            display: 'block',
            mb: '20px',
          }}
        >
          Other Areas of Expertise (Check all that apply.)
        </Typography>
        {expertiesAndResources.map(
          (item, key) =>
            key !== 0 && (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    checked={checkboxGroupSelection.expertiesAndResources.includes(item)}
                    sx={{
                      color: '#ADADAD',
                    }}
                    {...register('expertiesAndResources', {
                      validate: () => {
                        // your validation logic here :)
                        return checkboxGroupSelection.expertiesAndResources.length > 0;
                      },
                    })}
                    label={item}
                    onChange={(e) => {
                      setCheckboxValues(item, 'expertiesAndResources');
                    }}
                  />
                }
                sx={{ '& .MuiTypography-root': { minWidth: 'fit-content', maxWidth: '250px', lineHeight: '20px' } }}
                label={item}
              />
            )
        )}

        {errors && !!errors?.expertiesAndResources && checkboxGroupSelection.expertiesAndResources.length <= 0 && (
          <Typography sx={{ display: 'block', fontSize: '15px' }} variant="validation">
            Other Experties required
          </Typography>
        )}
      </Box>

      <Box sx={{ mt: '30px' }}>
        <Typography
          variant="form"
          sx={{
            ...typesOfResourcesTypographyStyles,
          }}
        >
          What types of resources have you identified and/or created on these topics?
        </Typography>
        {typeOfResources.map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: '25px', md: 'inherit' },
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                ...typographyMultiCheckboxTitleStyles,
              }}
              variant="form"
            >
              {item}
            </Typography>

            {index === 0 &&
              typesOfResources.map((subItem, subIndex) => (
                <Typography
                  key={subIndex}
                  sx={{
                    ...multiCheckboxTypographyStyles,
                  }}
                  variant="subtitle1"
                >
                  {subItem}
                </Typography>
              ))}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  For Purchase
                </Typography>
                <FormControlLabel
                  sx={{
                    width: '156px',
                    justifyContent: 'center',
                    display: { xs: 'block', md: 'inherit' },
                    marginRight: '0 !important',
                  }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection.typesOfResources[item].forPurchase}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-teaching`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          typesOfResources: {
                            ...checkboxGroupSelection.typesOfResources,
                            [item]: {
                              ...checkboxGroupSelection.typesOfResources[item],
                              forPurchase: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  Free
                </Typography>
                <FormControlLabel
                  sx={{
                    width: '156px',
                    justifyContent: 'center',
                    display: { xs: 'block', md: 'inherit' },
                    marginRight: '0 !important',
                  }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection.typesOfResources[item].free}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-coaching`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          typesOfResources: {
                            ...checkboxGroupSelection.typesOfResources,
                            [item]: {
                              ...checkboxGroupSelection.typesOfResources[item],
                              free: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box sx={{ mt: '30px' }}>
        {!edifierToEditData ? (
          <TextField
            id="personalWebsite"
            name="personalWebsite"
            label="Personal Website"
            fullWidth
            rows={5}
            {...register('personalWebsite')}
            type="text"
          />
        ) : (
          'personalWebsite' in formOtherDefaultValues && (
            <TextField
              id="personalWebsite"
              name="personalWebsite"
              label="Personal Website"
              fullWidth
              rows={5}
              {...register('personalWebsite')}
              type="text"
              defaultValue={formOtherDefaultValues?.personalWebsite}
            />
          )
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
        <Button
          sx={{
            background: '#6B9D98',
            width: { xs: '100px', sm: '164px' },
            height: { xs: '35px', sm: '48px' },
            color: 'white',
          }}
          onClick={() => {
            setActiveStep(0);
          }}
        >
          Go Back
        </Button>

        <Button
          sx={{
            background: '#6B9D98 !important',
            width: { xs: '100px', sm: '164px' },
            height: { xs: '35px', sm: '48px' },
            color: 'white',
          }}
          type="submit"
          id="index_2_2"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default OnboardingStep3;
