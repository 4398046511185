/* eslint-disable */
import React from 'react';
import { Box, Typography, Grid, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';

import 'react-phone-input-2/lib/bootstrap.css';
import PhoneInput from 'react-phone-input-2';
import { Controller } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import { stepperNextPrevBtnStyles } from '../../jsxStyles';
import { partnerAffiliationSchoolItems } from '../../_mock';
import { validatePassword } from '../../utils';

const OnboardingStep1 = ({
  stepperBoxStyles,
  teachersOnboardingTextFields,
  errors,
  isOnlyEdifier,
  preferredMethodOfCommunication,
  checkboxGroupSelection,
  edifierToEditData,
  ethnoracialData,
  setCheckboxValues,
  register,
  control,
  onboardingStepsContent,
  getValues,
  Select,
  watch,
  setValue,
  setSelectedForm,
  schoolsData,
}) => {
  return (
    <Box
      sx={{
        ...stepperBoxStyles,
      }}
    >
      <Typography variant="h3" sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif" }}>
        Introduction
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 300, lineHeight: '20.2px', mt: '14px', letterSpacing: '0.15px' }}
      >
        {onboardingStepsContent?.introText}
      </Typography>
      <Grid container spacing="19px" sx={{ mt: '10px' }}>
        {teachersOnboardingTextFields.slice(!edifierToEditData ? 0 : 1, 3).map((field, key) => (
          <Grid item xs={12} sm={6} lg={4} key={key}>
            <TextField
              sx={{
                '& .MuiFormHelperText-root': { margin: 0 },
              }}
              id={field.name}
              name={field.name}
              label={field.label}
              fullWidth
              {...register(
                field.name,
                field.name === 'email'
                  ? {
                      required: 'Email is Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address',
                      },
                    }
                  : {
                      required: `${field.label} is required`,
                      pattern: {
                        value: /^(?=.*[A-Za-z-])[A-Za-z\s-]*$/, // Alphabets, spaces, and hyphens allowed
                        message: `${field.label} should contain at least one alphabet and only alphabets,spaces,'-' are allowed`,
                      },
                      maxLength: {
                        value: 50, // Maximum length allowed, change accordingly
                        message: `${field.label} should not exceed 50 characters`,
                      },
                    }
              )}
              error={errors && !!errors[field.name]}
              helperText={errors[field.name]?.message}
              type={field?.fieldType ? field?.fieldType : 'text'}
              placeholder={field.placeholder}
              defaultValue={edifierToEditData && edifierToEditData[field.name]}
            />
          </Grid>
        ))}

        {!edifierToEditData && (
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              sx={{
                '& .MuiFormHelperText-root': { margin: 0 },
              }}
              name="password"
              label="Enter
            Password *"
              fullWidth
              {...register('password', { required: 'Password is Required', validate: validatePassword })}
              error={errors && !!errors?.password}
              helperText={errors?.password?.message}
              type="password"
              autoComplete="on"
            />
          </Grid>
        )}

        {!edifierToEditData && (
          <Grid item xs={12} sm={6} lg={4}>
            <TextField
              sx={{
                '& .MuiFormHelperText-root': { margin: 0 },
              }}
              name="confirmPassword"
              label="Confirm Password *"
              fullWidth
              {...register('confirmPassword', {
                required: `Confirm Password is required`,
                validate: (value) => {
                  const { password } = getValues();
                  return password === value || 'Passwords should match!';
                },
              })}
              error={errors && !!errors?.confirmPassword}
              helperText={errors?.confirmPassword?.message}
              type="password"
              autoComplete="on"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} lg={4}>
          <Controller
            name="phone"
            control={control}
            defaultValue={edifierToEditData && edifierToEditData['phone']}
            render={({ field }) => (
              <PhoneInput
                country={'us'}
                inputClass="input-phone"
                inputStyle={
                  errors?.phone && ((watch('phone') && watch('phone')?.length < 10) || !watch('phone'))
                    ? { height: '56px', width: '100%', border: '1px solid red' }
                    : { height: '56px', width: '100%', border: '1px solid #DEDEDE' }
                }
                placeholder="Phone *"
                defaultErrorMessage="Please Enter valid Phone Number"
                value={edifierToEditData ? edifierToEditData?.phone : field?.value}
                {...register('phone', {
                  validate: () => {
                    const { phone } = getValues();
                    // your validation logic here :)
                    return phone?.length >= 10 || 'Phone Number must be of 10 digits';
                  },
                })}
                onChange={(value) => setValue('phone', value)}
                inputProps={{
                  name: 'phone',

                  autoFocus: false,
                }}
              />
            )}
          />

          {errors.phone && (!watch('phone') || (watch('phone') && watch('phone')?.length < 10)) && (
            <span style={{ color: 'red', fontSize: 12 }}>{errors.phone.message}</span>
          )}
        </Grid>
      </Grid>

      <Typography sx={{ mt: '26px', mb: '20px', display: 'block', lineHeight: '20px' }} variant="form">
        How do you identify ethnoracially? (check all that apply)
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'auto',
            md: 'repeat(4,min-content)',
            lg: 'repeat(5,min-content)',
          },
        }}
      >
        {ethnoracialData.slice(0, 5).map((pType, key) => (
          <FormControlLabel
            key={key}
            sx={{ mr: '22px' }}
            control={
              <Checkbox
                checked={checkboxGroupSelection.ethnoRacial.includes(pType.name)}
                sx={{
                  color: '#ADADAD',
                }}
                name={pType.name}
                onChange={() => {
                  setCheckboxValues(pType.name, 'ethnoRacial');
                }}
              />
            }
            label={pType.label}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: 'auto', lg: 'repeat(3,min-content)' },
        }}
      >
        {ethnoracialData.slice(5, 8).map((pType, key) => (
          <FormControlLabel
            key={key}
            sx={{ mr: '22px' }}
            control={
              <Checkbox
                sx={{
                  color: '#ADADAD',
                }}
                {...register('ethnoRacial', {
                  validate: () =>
                    // your validation logic here :)
                    checkboxGroupSelection.ethnoRacial.length > 0,
                })}
                checked={checkboxGroupSelection.ethnoRacial.includes(pType.name)}
                name={pType.name}
                onChange={() => {
                  setCheckboxValues(pType.name, 'ethnoRacial');
                }}
              />
            }
            label={pType.label}
          />
        ))}
      </Box>
      {errors && !!errors?.ethnoRacial && checkboxGroupSelection.ethnoRacial.length <= 0 && (
        <Typography variant="validation">Ethnoracial field is required</Typography>
      )}

      {!isOnlyEdifier && schoolsData && (
        <Box sx={{ mb: '40px', mt: '10px' }}>
          <Select
            label="District / School System"
            register={register}
            field={{
              label: 'District / School System',
              name: 'districtSchoolSystem',
              requiredLabel: 'District School is Required',
            }}
            errors={errors}
            name="districtSchoolSystem"
            items={schoolsData ? schoolsData?.map((item) => item?.name) : []}
            defaultValue={
              !watch('districtSchoolSystem')
                ? edifierToEditData
                  ? edifierToEditData?.info?.districtSchoolSystem
                  : ''
                : watch('districtSchoolSystem')
            }
            watch={watch}
            onChange={(e) => setValue('districtSchoolSystem', e.target.value)}
          />
        </Box>
      )}

      {!isOnlyEdifier && (
        <>
          <Typography sx={{ mt: '26px', mb: '20px', display: 'block', fontWeight: 'bold' }} variant="form">
            Preferred method of communication
          </Typography>
          <Box>
            {preferredMethodOfCommunication.map((pType, key) => (
              <FormControlLabel
                key={key}
                sx={{ mr: '22px' }}
                control={
                  <Checkbox
                    checked={checkboxGroupSelection?.preferredMethodOfCommunication?.includes(pType)}
                    sx={{
                      color: '#ADADAD',
                    }}
                    name={pType.name}
                    onChange={() => {
                      setCheckboxValues(pType, 'preferredMethodOfCommunication');
                    }}
                  />
                }
                label={pType}
              />
            ))}
          </Box>
        </>
      )}

      <Grid container sx={{ mt: '10px', mb: '30px', rowGap: '20px' }} spacing="10">
        <Grid item xs={12} md={6} sx={{ mt: 'auto' }}>
          <Select
            label={teachersOnboardingTextFields[7].label}
            register={register}
            field={{ ...teachersOnboardingTextFields[7], requiredLabel: 'Gender is required' }}
            errors={errors}
            name={teachersOnboardingTextFields[7].name}
            items={['Woman', 'Man', 'Transgender', 'Non-binary/non-conforming', 'Prefer not to respond']}
            defaultValue={!watch('gender') ? (edifierToEditData ? edifierToEditData?.gender : '') : watch('gender')}
            watch={watch}
            onChange={(e) => setValue('gender', e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={6} sx={{ mt: 'auto' }}>
          <span style={{ fontSize: '12px', display: 'block', lineHeight: '18px', marginBottom: '10px' }}>
            Please tell us what partnership organizations you are affiliated with. If your's isn't here, please type it
            in the box here and hit enter
          </span>
          <Autocomplete
            disablePortal
            id="partner-school-autocomplete"
            options={partnerAffiliationSchoolItems}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Partner Affiliation"
                {...register('partnerSchool', {
                  required: 'Partner Affiliation is required',
                })}
                error={!watch('partnerSchool') && errors && !!errors['partnerSchool']}
                helperText={errors['partnerSchool']?.message}
                sx={{
                  '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-20px', margin: 0 },
                }}
              />
            )}
            value={
              watch('partnerSchool')
                ? watch('partnerSchool') || ''
                : (edifierToEditData && edifierToEditData?.info?.partnerSchool) || ''
            }
            freeSolo
            onInputChange={(event, newInputValue) => {
              setValue('partnerSchool', newInputValue);
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          sx={{
            ...stepperNextPrevBtnStyles,
          }}
          onClick={() => setSelectedForm('')}
        >
          Go Back
        </Button>
        <Button
          sx={{
            ...stepperNextPrevBtnStyles,
          }}
          type="submit"
          id="index_1_1"
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default OnboardingStep1;
