import React, { useState } from 'react';
import { TableCell, Typography, FormControlLabel, Switch, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { columnsToAddMarkerOn } from '../../_mock';

const TableColumn = ({
  index,
  row,
  keyName,
  searchedValue,
  Marker,
  toggleField,
  setAvailabilityPayload,
  isChangingAvailability,

  availabilityPayload,
}) => {
  const [userAvailability, setUserAvailability] = useState(row?.isAvailable);

  return (
    <TableCell
      component="th"
      scope="row"
      key={index}
      sx={{ textTransform: keyName?.toLowerCase()?.includes('email') === false && 'capitalize' }}
    >
      {columnsToAddMarkerOn.includes(keyName) && searchedValue ? (
        <Marker style={{ textTransform: 'none' }} mark={searchedValue}>
          {row[keyName]}
        </Marker>
      ) : toggleField ? (
        <FormControlLabel
          control={
            <Switch checked={userAvailability} onChange={(e) => setUserAvailability(e.target.checked)} color="main" />
          }
          label="Available"
          sx={{
            '& .MuiTypography-root': { color: 'black' },
          }}
        />
      ) : (
        <Typography variant="subtitle2" sx={{ color: keyName === 'email' && '#569CD3' }}>
          {row[keyName] && typeof row[keyName] === 'string' && row[keyName]?.toLowerCase()}
        </Typography>
      )}
      {userAvailability !== row?.isAvailable && toggleField && (
        <Box sx={{ display: 'flex', columnGap: '10px' }}>
          <LoadingButton
            variant="contained"
            loading={isChangingAvailability && row?._id === availabilityPayload._id}
            // color="white"
            disabled={isChangingAvailability && row?._id !== availabilityPayload._id}
            onClick={() => setUserAvailability(row?.isAvailable)}
            sx={{ width: '50px', fontSize: '12px' }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            variant="contained"
            // loading={isModifyingTimeSlots}
            loading={isChangingAvailability && row?._id === availabilityPayload._id}
            disabled={isChangingAvailability && row?._id !== availabilityPayload._id}
            color="main"
            onClick={() => setAvailabilityPayload({ _id: row?._id, isAvailable: userAvailability })}
            sx={{ width: '50px', fontSize: '12px' }}
          >
            Save
          </LoadingButton>
        </Box>
      )}
    </TableCell>
  );
};

export default TableColumn;
