import React from 'react';

import { Typography, Button, Box, Grid, TextField, FormControlLabel, Checkbox } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { lastStepBoxStyles } from '../../jsxStyles';

import { Select } from '../select';
import { huddleWorkItems } from '../../_mock';

const OnboardingStep3 = ({
  edifierToEditData,
  register,
  errors,
  formOtherDefaultValues,
  setActiveStep,
  checkboxGroupSelection,
  isOnlyEdifier,
  loading,
  watch,
  setValue,
  setCheckboxValues,
}) => (
  <Box
    sx={{
      ...lastStepBoxStyles,
    }}
  >
    <Typography
      sx={{
        m: '26px 0 20px 0',
        fontSize: '24px',
        color: '#000',
        fontFamily: "'Noto Sans JP'",
      }}
      variant="form"
    >
      Availability and Preferences
    </Typography>

    {isOnlyEdifier && (
      <Grid container spacing="20px" sx={{ p: '20px 0' }}>
        <Grid item xs={7}>
          <span style={{ fontSize: '12px' }}>
            How many hours per month are you interested in serving Edifying Teachers?
          </span>
          <Select
            watch={watch}
            register={register}
            field={{
              name: 'hoursPerMonth',
              requiredLabel: 'Hours/Month are required',
            }}
            defaultValue={
              formOtherDefaultValues?.hoursPerMonth
                ? formOtherDefaultValues?.hoursPerMonth
                : watch('hoursPerMonth') || ''
            }
            errors={errors}
            name={'hoursPerMonth'}
            items={['2', '3-4', '5-6', 'More than 6']}
            onChange={(e) => setValue('hoursPerMonth', e.target.value)}
          />
        </Grid>
      </Grid>
    )}
    {/* {isOnlyEdifier && (
      <Box>
        <Typography
          variant="form"
          sx={{
            mr: '25px',
            display: 'grid',
            alignItems: 'center',
            lineHeight: '20px',
            fontWeight: 500,
            mb: '20px',
            mt: '20px',
          }}
        >
          Edifying Teachers currently serves teachers in the following areas. Please check all that apply in terms of
          your interest. Please note that while we can pay you for your time, we cannot reimburse you for transportation
          costs.
        </Typography>
        {termsOfInterestMocks.map((item, key) => (
          <FormControlLabel
            sx={{ display: 'block', '& .MuiTypography-root': { fontSize: '13px !important' } }}
            key={key}
            control={
              <Checkbox
                checked={checkboxGroupSelection.termsOfInterest.includes(item)}
                sx={{
                  color: '#ADADAD',
                  // '&.Mui-checked': {
                  //   color: 'red',
                  // },
                }}
                label={item}
                onChange={() => {
                  setCheckboxValues(item, 'termsOfInterest');
                }}
              />
            }
            label={item}
          />
        ))}
      </Box>
    )} */}

    <Box>
      <Typography
        variant="form"
        sx={{
          mr: '25px',
          display: 'grid',
          alignItems: 'center',
          lineHeight: '20px',
          fontWeight: 500,
          mb: '20px',
          mt: '20px',
          color:
            errors && !!errors?.regionalHuddleWork && checkboxGroupSelection.regionalHuddleWork.length <= 0 && 'red',
        }}
      >
        In an ideal world, when would you be able to meet for a monthly coaching session and the regional huddle (Check
        all that apply)?
      </Typography>
      {huddleWorkItems.map((item, key) => (
        <FormControlLabel
          sx={{ display: 'block' }}
          key={key}
          control={
            <Checkbox
              checked={checkboxGroupSelection.regionalHuddleWork.includes(item.value)}
              {...register('regionalHuddleWork', {
                validate: () => checkboxGroupSelection.regionalHuddleWork.length > 0,
              })}
              sx={{
                color: '#ADADAD',
              }}
              label={item.label}
              onChange={() => {
                setCheckboxValues(item.value, 'regionalHuddleWork');
              }}
            />
          }
          label={item.label}
        />
      ))}

      {errors && !!errors?.regionalHuddleWork && checkboxGroupSelection.regionalHuddleWork.length <= 0 && (
        <Typography sx={{ display: 'block', fontSize: '15px' }} variant="validation">
          Your Availibility is required{' '}
        </Typography>
      )}
    </Box>

    <Grid container spacing="20px" sx={{ mt: '20px' }}>
      <Grid item xs={12}>
        <Typography variant="specs" sx={{ fontWeight: 400, mb: '17px', color: 'rgba(0, 0, 0, 0.60)' }}>
          Is there anything else we should be aware of regarding your availability to serve or your interest in Edifying
          Teachers?
        </Typography>

        {!edifierToEditData ? (
          <TextField
            // multiline
            // id={field/.name}
            id="anythingElse"
            name="anythingElse"
            label="Your Answer"
            fullWidth
            rows={5}
            // {...register('bio')}

            {...register('anythingElse')}
            type="text"
          />
        ) : (
          'anythingElse' in formOtherDefaultValues && (
            <TextField
              // multiline
              // id={field/.name}
              id="anythingElse"
              name="anythingElse"
              label="Your Answer"
              fullWidth
              rows={5}
              {...register('anythingElse')}
              // error={errors && !!errors[field.name]}
              // helperText={errors[field.name]?.message}
              type="text"
              defaultValue={formOtherDefaultValues?.anythingElse}

              // value="dijsaj"
            />
          )
        )}
      </Grid>
    </Grid>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '20px' }}>
      <Button
        sx={{
          background: '#6B9D98',
          width: { xs: '100px', sm: '164px' },
          height: { xs: '35px', sm: '48px' },
          color: 'white',
        }}
        onClick={() => setActiveStep(2)}
      >
        Go Back
      </Button>
      <LoadingButton
        sx={{
          background: '#6B9D98 !important',
          width: { xs: '100px', sm: '164px' },
          height: { xs: '35px', sm: '48px' },
          color: 'white',

          '& .MuiLoadingButton-loadingIndicator': {
            color: 'darkgreen',
          },
        }}
        type="submit"
        loading={loading}
      >
        Submit
      </LoadingButton>
    </Box>
  </Box>
);

export default OnboardingStep3;
