import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Avatar, Typography, ListItemText, ListItemAvatar, ListItemButton } from '@mui/material';
import { useQueryClient } from 'react-query';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { queryKeys } from '../../../hooks/react-query/queryKeys';
// components

function NotificationItem({ notification }) {
  const { avatar } = renderContent(notification);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const showNotificationDetails = (notification) => {
    const currentPath = window.location.pathname;
    if (notification?.title === 'Meeting Invite') {
      if (currentPath.includes('meeting-invites')) {
        queryClient.invalidateQueries({ queryKey: [queryKeys.getUserInvites] });

        return;
      }
      navigate('/dashboard/meeting-invites');
    }
    if (notification?.title === 'Pair Request') {
      if (currentPath.includes('requests')) {
        queryClient.invalidateQueries({ queryKey: [queryKeys.getSiteLeaderRequests] });

        return;
      }
      navigate('/dashboard/requests');
    }
  };
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
      }}
      onClick={() => showNotificationDetails(notification)}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={notification?.title || 'Untitled'}
        sx={{ fontSize: '12px', fontWeight: 500, '& .MuiListItemText-primary': { fontSize: '13px' } }}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'gray',
              fontWeight: 500,
              fontSize: '10px',
              pr: '20px',
              textTransform: 'capitalize',

              // color: 'text.disabled',
            }}
          >
            {notification?.message}
          </Typography>
        }
      />
      {notification?.title === 'Meeting Invite' && (
        <Box component="img" src="/assets/rightArrow.svg" sx={{ my: 'auto' }} />
      )}
    </ListItemButton>
  );
}

export default NotificationItem;

function renderContent(notification) {
  const title = <Typography variant="subtitle2">{notification.title}</Typography>;

  return {
    avatar: <AccountCircleIcon sx={{ color: 'green', height: '100%', width: '100%' }} />,
    title,
  };
}
