import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Typography from '@mui/material/Typography';

export default function DeleteDialog({ open, handleClose, deleteCoach, setDeleteOperationOpen, deleteSchool }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            width: { xs: '350px', sm: '448px' },
            height: '330px',
            borderRadius: '20PX',
          },
        }}
      >
        <DialogContent>
          <Box component="img" src="/assets/deleteIcon.svg" sx={{ mx: 'auto' }} />
          <Typography variant="mainTitles" sx={{ lineHeight: '70px', color: 'text.titles', textAlign: 'center' }}>
            Are you Sure?
          </Typography>

          <Typography variant="subtitle1" sx={{ fontWeight: 500, textAlign: 'center' }}>
            Are your sure you want to delete this {deleteSchool ? 'School' : 'Coach'}.
          </Typography>

          <Box sx={{ textAlign: 'center', mt: '29.61px' }}>
            <Button onClick={handleClose} variant="outlined" color="main">
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteCoach();
                setDeleteOperationOpen(true);

                handleClose();
              }}
              variant="contained"
              color="main"
              sx={{ ml: '9.28px' }}
            >
              Confirm
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
