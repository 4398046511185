import React, { useState } from 'react';
import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// import toast from 'react-hot-toast';
import { UserDetailedInformationDialog } from '../components/dialog';

import { EmptyRecordsBox } from '../components/empty-records-box';

import { Card } from '../sections/@dashboard/find-edifier';

// import { useQueryGetTeachersList } from '../hooks/react-query/useData';

const Teachers = () => {
  // const navigate = useNavigate();
  const pairedUsers = useOutletContext();

  const [currentTeacher, setCurrentTeacher] = useState({});
  const [displayTeacherDetails, setDisplayTeacherDetails] = useState(false);
 

  // const { data: teachersRecord, isFetching: isLoading } = useQueryGetTeachersList(userDetails?.token, navigate, toast);

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Your Teachers</title>
      </Helmet>
      <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
        Your Teachers
      </Typography>

      {pairedUsers[5] && (
        <Box sx={{ textAlign: 'center', mt: '20px' }}>
          {' '}
          <CircularProgress />
        </Box>
      )}

      <UserDetailedInformationDialog
        open={displayTeacherDetails}
        handleClose={() => setDisplayTeacherDetails(false)}
        userData={currentTeacher}
      />

      {!pairedUsers[5] && (!pairedUsers[6]?.data?.data || pairedUsers[6]?.data?.data?.length === 0) && (
        <EmptyRecordsBox />
      )}

      <Grid container spacing={'28.47px'} sx={{ mt: '10px' }}>
        {!pairedUsers[5] &&
          pairedUsers[6]?.data?.data &&
          pairedUsers[6]?.data?.data?.map(
            (list, index) =>
              list && (
                <Card
                  key={index}
                  data={list}
                  index={index}
                  userRole="coach"
                  setDisplayUserDetails={setDisplayTeacherDetails}
                  setCurrentUser={setCurrentTeacher}
                  pairedUsers
                />
              )
          )}
      </Grid>
    </Box>
  );
};

export default Teachers;
