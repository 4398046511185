import React from 'react';
import { Box, Typography } from '@mui/material';
import { cardBoxStyles, mainTitlesStyles } from '../../jsxStyles';

const EmptyRecords = () => (
  <Box
    sx={{
      ...cardBoxStyles,
      minWidth: '100%',
      mt: '30px',
      borderRadius: 0,
      boxShadow: 'none',
      height: '88px',
      p: '0',
      display: 'grid',
      alignItems: 'center',
    }}
  >
    <Typography variant="mainTitles" sx={{ ...mainTitlesStyles, textAlign: 'center', py: '20px' }}>
      No Records Found
    </Typography>
  </Box>
);

export default EmptyRecords;
