// @mui
import { Button, Box, Typography } from '@mui/material';
// components
import { loginFormContainerStyles, becomeMenteeBtnStyles } from '../../../jsxStyles';

// ----------------------------------------------------------------------

export default function LoginForm({ setSelectedForm }) {
  return (
    <Box
      sx={{
        ...loginFormContainerStyles,
      }}
    >
      <Typography variant="mainTitles" sx={{ lineHeight: 'normal' }}>
        Get Started
      </Typography>
      <Typography variant="secondaryTitles" sx={{ fontWeight: 300, lineHeight: '20.2px', mt: '25px' }}>
        The problem is most stark in the disproportionate lack of teachers of color.
      </Typography>

      <Button variant="contained" sx={{ ...becomeMenteeBtnStyles }} onClick={() => setSelectedForm('teacher')}>
        Become a Mentee
      </Button>
      <Button
        variant="contained"
        color="main"
        sx={{ borderRadius: 0, width: { xs: '250px', sm: '312px' }, height: '48px' }}
        onClick={() => setSelectedForm('coach')}
      >
        Become an Edifier
      </Button>
    </Box>
  );
}
