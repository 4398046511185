// component

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    iconSrc: '/assets/sidenavIcons/dashboard.svg',
  },
  {
    title: 'Find An Edifier',
    path: '/dashboard/find-edifiers',

    iconSrc: '/assets/sidenavIcons/findEdifier.svg',

    breadCrumbs: ['/dashboard/find-edifiers', '/dashboard/user-profile', '/dashboard/edifier-timeslots'],
  },

  {
    title: 'Your Edifier',
    path: '/dashboard/paired',

    iconSrc: '/assets/sidenavIcons/findEdifier.svg',
  },

  // {
  //   title: 'Session Logs',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/sessionLogs.svg',
  // },

  // {
  //   title: 'Check-In/Check-Out',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/sessionLogs.svg',
  // },
  {
    title: 'Mentoring Session Invites',
    path: '/dashboard/meeting-invites',
    iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  },
  {
    title: 'Upcoming Mentoring Sessions',
    path: '/dashboard/upcoming-events',
    iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  },
  // {
  //   title: 'Earn A Badge',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/sessionLogs.svg',
  // },
  // {
  //   title: 'Peer Support',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  // },
  {
    title: 'My Availability',
    path: '/dashboard/timeslots',
    iconSrc: '/assets/sidenavIcons/schedule.svg',
  },

  {
    title: 'Profile',
    path: '/dashboard/my-profile',
    iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  },

  // {
  //   title: 'Rate the Coach',
  //   path: 'NA',
  //   // path: '/dashboard/rate-coach',
  //   iconSrc: '/assets/sidenavIcons/rateCoach.svg',
  // },

  // {
  // title: 'Login',
  // path: '/login',
  // iconSrc: '/assets/sidenavIcons/rateCoach.svg',
  // },
];

export const navConfigEdifier = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    iconSrc: '/assets/sidenavIcons/dashboard.svg',
  },

  // {
  //   title: 'Session Logs',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/sessionLogs.svg',
  // },

  {
    title: 'Your Mentee(s)',
    path: '/dashboard/teachers',
    iconSrc: '/assets/sidenavIcons/sessionLogs.svg',
    breadCrumbs: ['/dashboard/teachers', '/dashboard/user-profile', '/dashboard/edifier-timeslots'],
  },

  // {
  //   title: 'Check-In/Check-Out',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/sessionLogs.svg',
  // },

  {
    title: 'Mentoring Session Invites',
    path: '/dashboard/meeting-invites',
    iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  },

  {
    title: 'Upcoming Mentoring Sessions',
    path: '/dashboard/upcoming-events',
    iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  },

  // {
  //   title: 'Peer Support',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  // },

  {
    title: 'My Availability',
    path: '/dashboard/timeslots',
    iconSrc: '/assets/sidenavIcons/schedule.svg',
  },

  {
    title: 'Profile',
    path: '/dashboard/my-profile',
    iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  },

  // {
  // title: 'Login',
  // path: '/login',
  // iconSrc: '/assets/sidenavIcons/rateCoach.svg',
  // },
];

export const navConfigSudo = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    iconSrc: '/assets/sidenavIcons/dashboard.svg',
  },
  {
    title: 'Pairing/Update Requests',
    path: '/dashboard/requests',

    iconSrc: '/assets/sidenavIcons/findEdifier.svg',
  },
  {
    title: 'Add / Change Edifier Profiles',
    path: '/dashboard/find-edifiers',
    iconSrc: '/assets/sidenavIcons/sessionLogs.svg',
  },
  {
    title: 'Add / Change Schools',
    path: '/dashboard/find-schools',
    iconSrc: '/assets/sidenavIcons/sessionLogs.svg',
  },
  {
    title: 'Send Notifications',
    path: '/dashboard/send-notifications',
    iconSrc: '/assets/sidenavIcons/sessionLogs.svg',
  },

  // {
  //   title: 'Manage Resources',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  // },

  // {
  //   title: 'Manage Availability Slots',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/rateCoach.svg',
  // },
  {
    title: "Manage Users' Availability",
    path: '/dashboard/manage-availability',
    iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  },

  {
    title: 'Manage Credentials',
    path: '/dashboard/manage-credentials',
    iconSrc: '/assets/sidenavIcons/rateCoach.svg',
  },
  {
    title: 'Session Management',
    path: '/dashboard/manage-trainings',
    iconSrc: '/assets/sidenavIcons/rateCoach.svg',
  },

  {
    title: 'Profile',
    path: '/dashboard/my-profile',
    iconSrc: '/assets/sidenavIcons/peerSupport.svg',
  },

  // {
  //   title: 'Manage Assessments',
  //   path: 'NA',
  //   iconSrc: '/assets/sidenavIcons/rateCoach.svg',
  // },
];

export const siteLeaderNav = [
  // {
  //   title: 'Dashboard',
  //   path: '/dashboard/app',
  //   iconSrc: '/assets/sidenavIcons/dashboard.svg',
  // },

  {
    title: 'District Users',
    path: '/dashboard/district-users',
    iconSrc: '/assets/sidenavIcons/dashboard.svg',
  },

  {
    title: 'Profile',
    path: '/dashboard/my-profile',
    iconSrc: '/assets/sidenavIcons/dashboard.svg',
  },
];
