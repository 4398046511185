// ----------------------------------------------------------------------

export default function Table() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.filters': {
            background: 'white',
            boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },

            borderRadius: '10px !important',
            '& .MuiInputBase-root': { height: '37px' },
            '& .MuiInputBase-input::placeholder': { fontSize: '13px !important' },
          },
        },
      },
    },
  };
}
