import { Navigate, useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
// import BlogPage from './pages/BlogPage';
import FindEdifiers from './pages/Edifiers';
import OnBoardUser from './pages/OnboardUser';
import Page404 from './pages/Page404';
import PairedUsers from './pages/PairedUsers';

import DashboardAppPage from './pages/DashboardAppPage';
import RecordedResponses from './pages/RecordedResponse';

import OnboardingAdOns from './pages/OnBoardingAdOns';
import ManageAvailability from './pages/ManageAvailability';
import SendAdminNotifications from './pages/Notifications';
import AppNotifications from './pages/AppNotifications';

import ManageTrainings from './pages/ManageTrainings';


import RoleProfile from './pages/AssociatedRoleProfile';
import EdifierTimeSlots from './pages/EdifierTimeSlots';
import MeetingInvites from './pages/MeetingInvites';
import UpcomingEvents from './pages/UpcomingEvents';
import ZoomMeetings from './pages/ZoomMeeting';
import Teachers from './pages/Teachers';

import { TimeSlots } from './components/time-management';

import EarnBadge from './pages/EarnBadge';
import RateCoach from './pages/RateCoach';
// import Surveys from './pages/Surveys';

import VerifyUser from './pages/Verify';
import OnBoard from './pages/OnBoarding';
import ResetPassword from './pages/ResetPassword';

import LoginPage from './pages/LoginPage';

import MyEdifyingJourney from './pages/MyEdifyingJourney';
import Schools from './pages/Schools';
import SiteLeaderRequests from './pages/AdminRequests';

import ManageCredentials from './pages/ManageCredentials';
import TermsAndConditions from './pages/TermsAndConditions';

// ----------------------------------------------------------------------

export default function Router() {
  const getUserFromLocalStorage = () => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      return user || null;
    } catch (error) {
      return null;
    }
  };

  const isAdmin = getUserFromLocalStorage() && getUserFromLocalStorage().role.toLowerCase() === 'admin';
  const isSiteLeader = getUserFromLocalStorage() && getUserFromLocalStorage().role.toLowerCase() === 'site-leader';

  const role = getUserFromLocalStorage()?.role?.toLowerCase();

  const routes = useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard/my-profile" />,
    },
    {
      path: '/dashboard',

      element: getUserFromLocalStorage() ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        {
          element: isSiteLeader ? <Navigate to="/dashboard/district-users" /> : <Navigate to="/dashboard/app" />,
          index: true,
        },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'paired', element: <PairedUsers /> },

        {
          path: 'find-edifiers',
          element: role === 'teacher' || isAdmin ? <FindEdifiers /> : <Navigate to="/dashboard/teachers" />,
        },

        {
          path: 'district-users',
          element: role === 'site-leader' ? <FindEdifiers /> : <Navigate to="/dashboard/user-profile" />,
        },
        { path: 'find-schools', element: isAdmin ? <Schools /> : <Navigate to="/dashboard/my-profile" /> },
        { path: 'requests', element: isAdmin ? <SiteLeaderRequests /> : <Navigate to="/dashboard/my-profile" /> },

        { path: 'earn-badge', element: <EarnBadge /> },
        { path: 'my-profile', element: <RoleProfile /> },
        { path: 'rate-coach', element: <RateCoach /> },

        { path: 'journey', element: <MyEdifyingJourney /> },

        { path: 'user-profile', element: <RoleProfile /> },
        { path: 'meeting-invites', element: <MeetingInvites /> },
        { path: 'upcoming-events', element: <UpcomingEvents /> },

        { path: 'teachers', element: role === 'teacher' ? <Navigate to="/dashboard/find-edifiers" /> : <Teachers /> },
        // {
        //   path: 'teachers',
        //   element: role === 'teacher'
        //     ? (() => {
        //         window.location.href = '/dashboard/find-edifiers';
        //         return null; // Ensures no further rendering after the redirect.
        //       })()
        //     : <Teachers />
        // },

        { path: 'notifications', element: <AppNotifications /> },

        // OnboardingAdOns

        { path: 'meeting', element: <ZoomMeetings /> },

        {
          path: 'timeslots',
          element: <TimeSlots />,
        },

        {
          path: 'edifier-timeslots',
          element: <EdifierTimeSlots />,
        },
        {
          path: 'manage-credentials',
          element: isAdmin ? <ManageCredentials /> : <Navigate to="/dashboard/my-profile" />,
        },

        {
          path: 'manage-trainings',
          element: isAdmin ? <ManageTrainings /> : <Navigate to="/dashboard/my-profile" />,
        },

        {
          path: 'send-notifications',
          element: isAdmin ? <SendAdminNotifications /> : <Navigate to="/dashboard/my-profile" />,
        },

        {
          path: 'manage-availability',
          element: isAdmin ? <ManageAvailability /> : <Navigate to="/dashboard/my-profile" />,
        },

        {
          path: 'recorded-responses',
          element: !isAdmin ? <RecordedResponses /> : <Navigate to="/dashboard/my-profile" />,
        },

        // {
        //   path: 'surveys',
        //   element: <Surveys />,
        // },
      ],
    },
    {
      path: 'login',
      element: !getUserFromLocalStorage() ? <LoginPage /> : <Navigate to="/dashboard/my-profile" />,
    },

    { path: 'addons', element: <OnboardingAdOns /> },

    {
      path: 'onboard-user',
      element: getUserFromLocalStorage() ? <Navigate to="/dashboard/my-profile" /> : <OnBoardUser />,
    },
    {
      path: 'verify',
      element: getUserFromLocalStorage() ? <Navigate to="/dashboard/my-profile" /> : <VerifyUser />,
    },

    {
      path: 'reset-password',
      element: getUserFromLocalStorage() ? <Navigate to="/dashboard/my-profile" /> : <ResetPassword />,
    },
    {
      path: 'onboard',
      element: getUserFromLocalStorage() ? <Navigate to="/dashboard/my-profile" /> : <OnBoard />,
    },
    {
      path: 'terms-and-conditions', // New route for Terms and Conditions
      element: <TermsAndConditions />,
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          element: isSiteLeader ? <Navigate to="/dashboard/district-users" /> : <Navigate to="/dashboard/app" />,
          index: true,
        },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
