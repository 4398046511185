/*eslint-disable */
import React from 'react';

import { Card, Link, Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';
import { months, days } from '../../_mock';
import { isTenMinutesRemainingOrTimePassed } from '../../utils';

const StyledTitle = styled(Link)({
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const UpcomingEventsCard = ({
  item,
  setInvitePayload,
  isLoadingCredentials,
  setCancelId,
  isCancellingMeeting,
  cancelId,
  inviteId,
  // setParticipantId,
}) => {
  return (
    <Card
      sx={{
        position: 'relative',
        boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.06)',
        width: '350px',
        height: '250px',

        p: '20px',
      }}
    >
      <StyledTitle
        color="inherit"
        variant="secondaryTitles"
        underline="hover"
        sx={{
          fontWeight: 500,
          lineHeight: 'normal',
        }}
      >
        {item?.fname + ' ' + item?.lname}
      </StyledTitle>

      <Box sx={{ display: 'flex', columnGap: '4.48px', mt: '10px' }}>
        <Box component="img" src="/assets/emailIcon.svg" />
        <Typography variant="email"> {item?.email}</Typography>
      </Box>

      <Box>
        <Typography sx={{ fontWeight: 700, fontSize: '12px', mb: '20px' }}>
          {`${new Date(item?.scheduleTime)?.getDate()} ${months[new Date(item?.scheduleTime)?.getMonth()]} ${new Date(
            item?.scheduleTime
          )?.getFullYear()} ${days[new Date(item?.scheduleTime)?.getDay()]} ${new Date(item?.scheduleTime)
            .toLocaleTimeString()
            .replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}`}
        </Typography>
        <Box sx={{ display: 'flex', columnGap: '5px', mb: '10px' }}>
          <Box component="img" src="/infoIcon.svg" sx={{ height: '20px' }} />
          <Typography
            variant="form"
            sx={{ display: 'grid', alignItems: 'center', fontWeight: '400', fontSize: '11px', mb: '10px' }}
          >
            The join button will be enabled on time and will stay enabled for an hour, By default all meetings are set
            for an hour
          </Typography>
        </Box>
        <LoadingButton
          // disabled={
          //   (inviteId._id !== item._id && isLoadingCredentials) ||
          //   !isTenMinutesRemainingOrTimePassed(item?.scheduleTime)
          // }
          variant="contained"
          color="accept"
          sx={{ color: 'white', mr: '20px' }}
          onClick={() => {
            setInvitePayload(item);

            // setParticipantId(item.userId);
          }}
          loading={isLoadingCredentials && inviteId._id === item._id}
        >
          Join Meeting
        </LoadingButton>
        <LoadingButton
          disabled={
            isTenMinutesRemainingOrTimePassed(item?.scheduleTime) ||
            isTenMinutesRemainingOrTimePassed(item?.scheduleTime, true)
          }
          variant="contained"
          color="reject"
          sx={{ color: 'white' }}
          onClick={() => setCancelId(item._id)}
          loading={isCancellingMeeting && cancelId === item._id}
        >
          Cancel Meeting
        </LoadingButton>
      </Box>
    </Card>
  );
};

export default UpcomingEventsCard;
