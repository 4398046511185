/* eslint-disable */
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { logOutUser, generateToastError } from '../../../utils';
import { queryKeys } from '../queryKeys';

// Checkin and Checkout Logic stars from here

const httpStatuses = { unauthorized: 401, error: 500 };

export const useMutateCheckInUser = (payload, navigate, toast) => {
  const queryKey = [queryKeys.getAllUsers, payload];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/users`, {
      headers: { Authorization: `Bearer ${queryKey[1].token}` },
    });

  return useQuery(queryKey, !payload.token ? logOutUser(navigate) : queryFn, {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

// Checkin and Checkout Logic stars from here
export const useMutationCheckInUser = (toast, userToken, navigate, userRole, queryClient, inviteId) => {
  const queryKey = [queryKeys.checkInUser];

  const queryFn = () =>
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/session/checkin/${inviteId}`,
      { token: userToken },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

  return useMutation(queryKey, queryFn, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 5000,
      });

      // queryClient.invalidateQueries({ queryKey: [queryKeys.userCheckInStatus] });

      queryClient.invalidateQueries({ queryKey: [queryKeys.userCheckInStatus] });
    },
    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useMutationCheckOutUser = (toast, userToken, navigate, userRole, queryClient, inviteId) => {
  const queryKey = [queryKeys.checkOutUser];
  const queryFn = () =>
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/session/checkout/${inviteId}`,
      { token: userToken },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 5000,
      });

      queryClient.invalidateQueries({ queryKey: [queryKeys.userCheckInStatus] });
    },
    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useQueryCheckinStatus = (userToken, navigate, toast, role, inviteId, queryClient) => {
  const queryKey = [queryKeys.userCheckInStatus];
  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/session/status/${inviteId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useQuery(queryKey,inviteId? queryFn:()=>{}, {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: Infinity,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.surveyStatus] });
    },

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      // generateToastError(toast, e);
    },
  });
};

// get all notifications for a user
