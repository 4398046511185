import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Box, CircularProgress } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';

import { useQueryClient } from 'react-query';
import { SelectRole } from '../components/filters';

import { Card } from '../sections/@dashboard/find-edifier';
import { cardBoxStyles, mainTitlesStyles, listGridViewIconStyles } from '../jsxStyles';
import { getUserDetails, sortByLname } from '../utils';
import { useQueryGetUsersList, useMutationSetUserAvailability } from '../hooks/react-query/useData';
import { TableView } from '../components/table';
import { UserDetailedInformationDialog } from '../components/dialog';

const ManageCredentials = () => {
  const userDetails = getUserDetails();

  const userToken = userDetails.token;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [displayUserDetails, setDisplayUserDetails] = useState(false);
  const [availabilityPayload, setAvailabilityPayload] = useState({});
  const [isListView, setIsListView] = useState(false);

  const [currentUser, setCurrentUser] = useState({});

  const [currentSelectedFilter, setCurrentSelectedFilter] = useState('All');

  const { data: coachesList, isFetching: isLoading } = useQueryGetUsersList(
    { token: userDetails?.token, role: userDetails?.token.toLowerCase() },
    navigate,
    toast
  );

  const { isLoading: isChangingAvailability, mutate: changeUserAvailability } = useMutationSetUserAvailability(
    toast,
    userToken,
    availabilityPayload,
    queryClient,
    setAvailabilityPayload
  );

  useEffect(() => {
    if (Object.keys(availabilityPayload).length > 0) {
      changeUserAvailability();
      // manageCredentials();
    }
  }, [availabilityPayload]);

  const userCard = (index, list) => (
    <Card
      availabilityPayload={availabilityPayload}
      setAvailabilityPayload={setAvailabilityPayload}
      key={index}
      isChangingAvailability={isChangingAvailability}
      data={list}
      index={index}
      setDisplayUserDetails={setDisplayUserDetails}
      manageAvailability
      setCurrentUser={setCurrentUser}
    />
  );

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Manage Availability</title>
      </Helmet>

      <UserDetailedInformationDialog
        open={displayUserDetails}
        handleClose={() => setDisplayUserDetails(false)}
        userData={currentUser}
      />

      <Container maxWidth="xl" sx={{ p: '0 !important' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
            Manage Availability
          </Typography>

          <Box sx={{ display: 'flex', columnGap: '20px' }}>
            <SelectRole
              currentSelectedFilter={currentSelectedFilter}
              setCurrentSelectedFilter={setCurrentSelectedFilter}
            />
            <Box sx={{ display: 'flex', columnGap: '10px', justifyContent: 'right', my: 'auto' }}>
              <Box
                component="img"
                src={isListView ? '/assets/listActive.svg' : '/assets/listDisable.svg'}
                sx={{ ...listGridViewIconStyles }}
                onClick={() => setIsListView(true)}
              />
              <Box
                component="img"
                src={isListView ? '/assets/gridDisable.svg' : '/assets/gridActive.svg'}
                sx={{ ...listGridViewIconStyles }}
                onClick={() => setIsListView(false)}
              />
            </Box>
          </Box>
        </Box>

        {!isLoading && (!coachesList?.data?.data || coachesList?.data?.data?.length === 0) && (
          <Box
            sx={{
              ...cardBoxStyles,
              minWidth: '100%',
              mt: '30px',
              borderRadius: 0,
              boxShadow: 'none',
              height: '88px',
              p: '0',
              display: 'grid',
              alignItems: 'center',
            }}
          >
            <Typography variant="mainTitles" sx={{ ...mainTitlesStyles, textAlign: 'center', py: '20px' }}>
              No Record Found
            </Typography>
          </Box>
        )}

        {isLoading && (
          <Box sx={{ textAlign: 'center', mt: '30px' }}>
            <CircularProgress />
          </Box>
        )}

        {!isListView && (
          <Grid container spacing={'28.47px'} sx={{ mt: '20px' }}>
            {!isLoading &&
              coachesList?.data &&
              sortByLname(
                coachesList?.data?.data.filter((list) => {
                  if (currentSelectedFilter === 'All') {
                    return true;
                  }
                  if (currentSelectedFilter === 'Edifiers' && list?.role === 'COACH') {
                    return true;
                  }
                  if (currentSelectedFilter === 'Teachers' && list?.role === 'TEACHER') {
                    return true;
                  }
                  return false;
                })
              )?.map((list, index) => userCard(index, list))}
          </Grid>
        )}

        {isListView && (
          <TableView
            tableData={coachesList?.data?.data.filter((list) => {
              if (currentSelectedFilter === 'All') {
                return true;
              }
              if (currentSelectedFilter === 'Edifiers' && list?.role === 'COACH') {
                return true;
              }
              if (currentSelectedFilter === 'Teachers' && list?.role === 'TEACHER') {
                return true;
              }
              return false;
            })}
            manageAvailability
            isChangingAvailability={isChangingAvailability}
            availabilityPayload={availabilityPayload}
            setAvailabilityPayload={setAvailabilityPayload}
          />
        )}
      </Container>
    </Box>
  );
};

export default ManageCredentials;
