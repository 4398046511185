// @mui
import {  styled } from '@mui/material/styles';
import { Box, Link, Card, Grid, Typography, CardContent } from '@mui/material';
// utils

//

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});



const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------


export default function EarnBadgeCard({ post }) {
  const {

    title,
    totalCourses,
    completedCourses,
    reviews,
    img,
   
    ratingBasis,
  } = post;

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card sx={{ position: 'relative', boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.06)' }}>
        <StyledCardMedia sx={{ height: '250px', p: '0' }}>
          <StyledCover alt={title} src={img} />
        </StyledCardMedia>

        <CardContent sx={{ p: '0', pb: '0 !important' }}>
          <Box sx={{ p: '17.59px' }}>
            <StyledTitle
              color="inherit"
              variant="secondaryTitles"
              underline="hover"
              sx={{ fontWeight: 500, lineHeight: 'normal', height: 'fit-content' }}
            >
              {title}
            </StyledTitle>
            <Box sx={{ mt: '20px' }}>
              <Typography variant="specs" sx={{ fontWeight: 400 }}>
                Total Courses: <span style={{ color: '#A94B9C', fontWeight: 600 }}>{totalCourses}</span>
              </Typography>
              <Typography variant="specs" sx={{ mt: '15px', fontWeight: 400 }}>
                Completed Courses: <span style={{ color: '#A94B9C', fontWeight: 600 }}>{completedCourses}</span>
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              columnGap: '7.42px',
              mt: '34.61px',
              pt: '15.99px',
              pb: '12.86px',
              background: '#F9FAFC',
              pl: '18.77px',
            }}
          >
            <Box component="img" src="/assets/reviewSingleStar.svg" />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography variant="specs" sx={{ fontWeight: 500, color: '#282A39' }}>
                {reviews} rating
              </Typography>
              <Typography variant="smallText">{ratingBasis}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
