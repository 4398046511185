/* eslint-disable */
import { useState, useEffect } from 'react';
// @mui
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Grid, Container, Typography, Box, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { DailyQuotes } from '../components/daily-quotes';
import { dashboardIcons } from '../_mock';
import { GoalsDialog, DownloadReportDialog } from '../components/dialog';
import Calendar from '../sections/@dashboard/app/EventsCalendar';
// import { appHelpDData, trackJourneyData } from '../_mock';
import { getUserDetails, mergeTheGoals, downloadExcelReport } from '../utils';
import { useQueryGetTotalUsers } from '../hooks/react-query/useData';
import { useMutationUpdateGoals } from '../hooks/react-query/goalsManagement';
import { dashboardStatsContainerStyles } from '../jsxStyles';
import { AppWidgetSummary, BarChart } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userDetails = getUserDetails();

  const userRole = userDetails?.role?.toLowerCase();

  const dataContext = useOutletContext();
  const [goalsModalData, setGoalsModalData] = useState({ open: false, goals: [], edifierName: '' });
  const [structuredGoals, setStructuredGoals] = useState({ goals: [], goalsCount: 0 });
  const [updatedGoalsPayload, setUpdatedGoalsPayload] = useState({});
  const [searchedValue, setSearchedValue] = useState('');
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [openDialogReportDialog, setOpenDialogReportDialog] = useState(false);
  const [reportData, setReportData] = useState({});

  const { data, isFetching: isLoading } = useQueryGetTotalUsers(userDetails?.token, navigate, userDetails?.role);

  const { mutate: submitGoals, isLoading: isSubmittingGoals } = useMutationUpdateGoals(
    updatedGoalsPayload,
    toast,
    userDetails?.token,
    queryClient,
    setGoalsModalData,
    userRole
  );

  useEffect(() => {
    if (data?.data?.data?.goals && userDetails?.role !== 'admin') {
      restructureGoalsForMapping();
    }
  }, [data?.data?.data?.goals]);

  useEffect(() => {
    if (Object.keys(updatedGoalsPayload).length > 0) {
      submitGoals();
    }
  }, [updatedGoalsPayload]);

  const pushEntriesInArray = (
    mergedObjects,
    filteredArray,
    mergedObjectsIncompleteGoals,
    filteredArrayIncompleteGoals
  ) => {
    Object.keys(mergedObjects).forEach((key, index) => {
      filteredArray.push({ id: index, ...mergedObjects[key] });
    });

    Object.keys(mergedObjectsIncompleteGoals).forEach((key, index) => {
      filteredArrayIncompleteGoals.push({ id: index, ...mergedObjectsIncompleteGoals[key] });
    });
  };
  //Mapping Goals Data to Merge The goals
  const mapGoalsData = (mergedObjects, mergedObjectsIncompleteGoals) => {
    data?.data?.data?.goals?.forEach((mainObj, index) => {
      if (!mainObj?.goals && mainObj?.goal) {
        mergeTheGoals(mainObj, mergedObjects, mergedObjectsIncompleteGoals, mainObj?.goal, userDetails, index);
      } else {
        mainObj?.goals?.map((obj) => {
          mergeTheGoals(mainObj, mergedObjects, mergedObjectsIncompleteGoals, obj, userDetails, index);
        });
      }
    });
  };
  //main function for performing merge and adding entries

  const restructureGoalsForMapping = () => {
    const mergedObjects = {};
    const mergedObjectsIncompleteGoals = {};
    const filteredArray = [];
    const filteredArrayIncompleteGoals = [];

    mapGoalsData(mergedObjects, mergedObjectsIncompleteGoals);

    pushEntriesInArray(mergedObjects, filteredArray, mergedObjectsIncompleteGoals, filteredArrayIncompleteGoals);

    setStructuredGoals({ goals: [...filteredArray], inCompleteGoals: [...filteredArrayIncompleteGoals] });
  };

  //search from the record
  const searchGoalsFromRecord = () => {
    if (searchedValue === '' || searchedValue === null) {
      if (goalsModalData?.isCompleted) {
        setGoalsModalData({ ...goalsModalData, goals: structuredGoals?.goals });
      } else {
        setGoalsModalData({ ...goalsModalData, goals: structuredGoals?.inCompleteGoals });
      }
    }

    const mergedObjects = {};
    const mergedObjectsIncompleteGoals = {};
    const filteredArray = [];
    const filteredArrayIncompleteGoals = [];

    mapGoalsData(mergedObjects, mergedObjectsIncompleteGoals);

    pushEntriesInArray(mergedObjects, filteredArray, mergedObjectsIncompleteGoals, filteredArrayIncompleteGoals);

    const searchFilterCompleteGoals =
      filteredArray?.length === 0 ? [] : filteredArray?.filter((obj) => obj.name.includes(searchedValue));

    const searchFilterIncompleteGoals =
      filteredArrayIncompleteGoals.length === 0
        ? []
        : filteredArrayIncompleteGoals?.filter((obj) => obj.name.toLowerCase().includes(searchedValue.toLowerCase()));

    setStructuredGoals({ goals: [...searchFilterCompleteGoals], inCompleteGoals: [...searchFilterIncompleteGoals] });

    if (goalsModalData?.isCompleted) {
      setGoalsModalData({
        ...goalsModalData,
        goals: searchFilterCompleteGoals,
        // edifierName: goals[key]?.name,
      });
    } else {
      setGoalsModalData({
        ...goalsModalData,

        goals: searchFilterIncompleteGoals,
      });
    }
  };

  const searchAdminReport = () => {
    if (Object.keys(reportData).length < 2) {
      toast.error('Both end and start date required');
      return;
    }

    if (reportData.endDate < reportData.startDate) {
      toast.error('End Date must be after start date');
      return;
    }
    setIsReportLoading(true);
    downloadExcelReport(
      toast,
      setIsReportLoading,
      userDetails?.token,
      setOpenDialogReportDialog,
      reportData,
      setReportData
    );
  };

  const returnGridSizes = () => {
    if (userRole === 'admin') {
      return { xs: 12 };
    } else {
      return { xs: 12, lg: 7, xl: 8 };
    }
  };
  console.log('ANALYTICS DATA', data?.data?.data);
  console.log('DASHBOARD ICONS', dashboardIcons);
  return (
    <>
      <Helmet>
        <title>Edifying Teachers | Dashboard</title>
      </Helmet>

      <Container maxWidth="xl" sx={{ p: '0 !important' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
            mb: { xs: '20px', md: 'initial' },
          }}
        >
          <Box>
            <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
              Welcome!
            </Typography>
          </Box>
          {userRole === 'admin' && (
            <LoadingButton
              variant="contained"
              color="main"
              sx={{ height: '40px', width: 'fit-content' }}
              onClick={() => {
                setOpenDialogReportDialog(true);
              }}
            >
              Download Report
            </LoadingButton>
          )}
        </Box>

        <DailyQuotes />

        {isLoading && (
          <Box sx={{ textAlign: 'center', my: '20px' }}>
            <CircularProgress />
          </Box>
        )}

        <Grid container spacing={3} sx={{ mt: '30px' }}>
          <Grid item {...returnGridSizes()}>
            {!isLoading && userRole !== 'admin' && !isLoading && (
              <Grid container sx={{ justifyContent: 'space-between', mb: '20px', minHeight: '190px' }} spacing="25">
                <Grid item xs={12} sm={4} lg={6} xl={4}>
                  <AppWidgetSummary
                    sx={{ mx: { xs: 'auto', md: 'inherit' }, height: '100%' }}
                    total={data?.data?.data?.sessions}
                    title="Total Sessions"
                    icon={<Box component="img" src="/assets/dashboard/users.svg" />}
                    loading={isLoading}
                    open={false}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={6} xl={4}>
                  <AppWidgetSummary
                    sx={{ mx: { xs: 'auto', md: 'inherit' }, height: '100%' }}
                    total={
                      !data?.data?.data?.goals ? 0 : JSON.stringify(data?.data?.data?.goals)?.split('true').length - 1
                    }
                    goals={structuredGoals?.goals}
                    icon={
                      <Box
                        component="img"
                        src="/assets/dashboard/goalsIcon.svg"
                        sx={{ width: '43px', height: '31px' }}
                      />
                    }
                    title={userDetails?.role === 'TEACHER' ? 'Goals (Completed)' : 'Goals Set by you (Completed)'}
                    loading={isLoading}
                    goalsModalData={goalsModalData}
                    setGoalsModalData={setGoalsModalData}
                    userGoals={goalsModalData?.goals}
                    isTeacher={userDetails?.role === 'TEACHER'}
                    detailedInformation
                    isCompleted
                  />
                </Grid>

                <Grid item xs={12} sm={4} lg={6} xl={4}>
                  <AppWidgetSummary
                    sx={{ mx: { xs: 'auto', md: 'inherit' }, height: '100%' }}
                    total={
                      !data?.data?.data?.goals ? 0 : JSON.stringify(data?.data?.data?.goals)?.split('false').length - 1
                    }
                    searchedValue={searchedValue}
                    searchFromRecord={searchGoalsFromRecord}
                    setSearchedValue={setSearchedValue}
                    goals={structuredGoals?.inCompleteGoals}
                    icon={<Box component="img" src="/assets/dashboard/activeGoals.svg" />}
                    title="Goals Active"
                    loading={isLoading}
                    goalsModalData={goalsModalData}
                    setGoalsModalData={setGoalsModalData}
                    userGoals={goalsModalData?.goals}
                    setUpdatedGoalsPayload={setUpdatedGoalsPayload}
                    isSubmittingGoals={isSubmittingGoals}
                    updatedGoalsPayload={updatedGoalsPayload}
                    isTeacher={userDetails?.role === 'TEACHER'}
                    detailedInformation
                    isCompleted={false}
                  />
                </Grid>

                {userDetails?.role === 'COACH' && (
                  <Grid item xs={12} sm={4} lg={6} xl={4}>
                    <AppWidgetSummary
                      sx={{ mx: { xs: 'auto', md: 'inherit' }, height: '100%' }}
                      total={data?.data?.data?.surveys?.length}
                      icon={<SportsScoreIcon sx={{ fontSize: '30px', color: 'black' }} />}
                      title={'Surveys Completed by Mentees'}
                      loading={isLoading}
                      isSurveyDetails
                      detailedInformation
                      navigate={navigate}
                    />
                  </Grid>
                )}
              </Grid>
            )}

            {/* admin side analytics */}
            {userRole === 'admin' && (
              <Box
                sx={{
                  ...dashboardStatsContainerStyles,
                }}
              >
                {!isLoading &&
                  data?.data?.data &&
                  Object.keys(data?.data?.data).map((keys, index) => (
                    <AppWidgetSummary
                      key={index}
                      sx={{ width: { xs: '300px', sm: '350px' }, mx: { xs: 'auto', md: 'inherit' }, height: '190px' }}
                      total={
                        keys === 'surveyCompletionRate' ||
                        keys === 'surveyCompletionRateCoaches' ||
                        keys === 'surveyCompletionRateTeachers' ||
                        keys === 'surveyCompletionRateSiteLeaders' ||
                        keys === 'surveyCompletionRateSchools'
                          ? data?.data?.data[keys]
                            ? data?.data?.data[keys].toFixed(2) + '%'
                            : 0 + '%'
                          : data?.data?.data[keys]
                      }
                      title={dashboardIcons[keys]?.title}
                      loading={isLoading}
                      icon={dashboardIcons[keys]?.icon}
                      open={false}
                    />
                  ))}
              </Box>
            )}

            {/* admin side analytics */}
          </Grid>
          {userRole !== 'admin' && (
            <Grid item xs={12} lg={5} xl={4}>
              <Calendar eventsData={dataContext[2]?.data?.data} />
            </Grid>
          )}
        </Grid>
        {userRole !== 'admin' && <BarChart data={data?.data?.data?.MsCs} toast={toast} />}

        <GoalsDialog
          goals={goalsModalData?.goals}
          open={goalsModalData?.display}
          handleClose={() => setGoalsModalData({ display: false })}
          setUpdatedGoalsPayload={setUpdatedGoalsPayload}
          updatedGoalsPayload={updatedGoalsPayload}
          isSubmittingGoals={isSubmittingGoals}
          searchedValue={searchedValue}
          searchFromRecord={searchGoalsFromRecord}
          isTeacher={userDetails?.role === 'TEACHER'}
          setSearchedValue={setSearchedValue}
        />

        <DownloadReportDialog
          open={openDialogReportDialog}
          handleClose={() => setOpenDialogReportDialog(false)}
          setReportData={setReportData}
          reportData={reportData}
          searchReport={searchAdminReport}
          loading={isReportLoading}
        />
      </Container>
    </>
  );
}
