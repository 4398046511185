import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

import { welcomeSessionSurveyItems, finalSurveyHeadItems, finalSessionSurveyItems } from '../../_mock';

import { stepperBoxStyles } from '../../jsxStyles';

const FinalSessionSurvey = ({ submitSurvey, apiPayload, setPayload, participantId, isLoading, prePopulatedData }) => {
  const [checkboxGroupSelection, setCheckboxGroupSelection] = useState({
    myAsessment: {
      madeMeAwareOfStengthsAndAreas: {
        stronglyAgree: false,
        agree: false,
        disagree: false,
        stronglyDisagree: false,
      },
      understandStrategiesBetter: {
        stronglyAgree: false,
        agree: false,
        disagree: false,
        stronglyDisagree: false,
      },

      usedNewInstructionStrategies: {
        stronglyAgree: false,
        agree: false,
        disagree: false,
        stronglyDisagree: false,
      },
      lookForwardToBeingAnImportantLeader: {
        stronglyAgree: false,
        agree: false,
        disagree: false,
        stronglyDisagree: false,
      },
    },
    havingEdifier: [],
  });

  useEffect(() => {
    if (prePopulatedData) {
      setCheckboxGroupSelection({
        myAsessment: { ...prePopulatedData?.edifierAsessment },
        havingEdifier: prePopulatedData?.havingEdiferMadeEasier,
      });
    }
  }, [prePopulatedData]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    // getValues,
    // setValue,
    // watch,
  } = useForm({});

  const onSubmit = (submittedData) => {
    const updatedPayload = {
      anythingElse: submittedData.anythingElse,
      havingEdiferMadeEasier: checkboxGroupSelection.havingEdifier,
      edifierAsessment: checkboxGroupSelection.myAsessment,
      title: 'Final Session Surveys',
    };

    setPayload({
      participantId,
      surveyData: updatedPayload,
    });
  };

  const controlCheckboxSelection = (item) => {
    if (checkboxGroupSelection.havingEdifier.includes(item.description)) {
      const filteredResults = checkboxGroupSelection.havingEdifier.filter((element) => element !== item.description);

      setCheckboxGroupSelection({
        ...checkboxGroupSelection,
        havingEdifier: filteredResults,
      });
      return;
    }
    setCheckboxGroupSelection({
      ...checkboxGroupSelection,
      havingEdifier: [...checkboxGroupSelection.havingEdifier, item.description],
    });
  };

  useEffect(() => {
    if (apiPayload && Object.keys(apiPayload).length > 0) {
      submitSurvey();
    }
  }, [apiPayload]);

  return (
    <Box sx={{ ...stepperBoxStyles, minWidth: '100%', mt: '30px' }}>
      <Typography
        variant="h3"
        sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif", mb: '50px', textAlign: 'center' }}
      >
        Final Session Surveys
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {finalSurveyHeadItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: '25px', md: index !== 0 && '30px' },
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                width: '200px',
                fontSize: '18px',
                fontWeight: 300,
                display: 'grid',
                alignItems: 'center',
                lineHeight: '25px',
              }}
              variant="form"
            >
              {item?.title}
            </Typography>

            {index === 0 &&
              welcomeSessionSurveyItems.map((subItem, subIndex) => (
                <Typography
                  key={subIndex}
                  sx={{
                    width: '156px',
                    mb: '24px',
                    fontWeight: 500,
                    color: '#000',
                    minWidth: 'max-content',
                    display: { xs: 'none', md: 'inherit' },
                    justifyContent: 'center',
                  }}
                  variant="subtitle1"
                >
                  {subItem}
                </Typography>
              ))}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  Strongly Agree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.myAsessment[item?.name]?.stronglyAgree || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item?.name}-stronglyAgree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          myAsessment: {
                            ...checkboxGroupSelection.myAsessment,
                            [item?.name]: {
                              stronglyAgree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Agree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.myAsessment[item?.name]?.agree || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item?.name}-agree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          myAsessment: {
                            ...checkboxGroupSelection.myAsessment,
                            [item?.name]: {
                              agree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Disagree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.myAsessment[item?.name]?.disagree || false}
                      name={`${item?.name}-disagree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          myAsessment: {
                            ...checkboxGroupSelection.myAsessment,
                            [item?.name]: {
                              disagree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                  {...register('item', {
                    validate: () =>
                      // your validation logic here :)
                      JSON.stringify(checkboxGroupSelection.myAsessment).split('true').length - 1 === 4,
                  })}
                />
              </Box>
            )}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Strongly Disagree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.myAsessment[item?.name]?.stronglyDisagree || false}
                      name={`${item?.name}-stronglyDisagree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          myAsessment: {
                            ...checkboxGroupSelection.myAsessment,
                            [item?.name]: {
                              stronglyDisagree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        ))}

        {errors && !!errors?.item && (
          <Typography variant="validation" sx={{ fontSize: '15px' }}>
            All options must be checked
          </Typography>
        )}
        <Box>
          <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
            Having an Edifier has made my year easier by: Mark all that apply
          </Typography>

          {finalSessionSurveyItems.map((item, key) => (
            <FormControlLabel
              name={item.title}
              key={key}
              sx={{
                mr: '22px',
                display: 'block ',
                '& .MuiFormControlLabel-label': {
                  textTransform: 'capitalize !important',
                  fontSize: { xs: '12px !important', sm: '14px !important' },
                },
              }}
              control={
                <Checkbox
                  checked={checkboxGroupSelection.havingEdifier.includes(item.description) || false}
                  onClick={() => {
                    controlCheckboxSelection(item);
                  }}
                  sx={{
                    color: '#ADADAD',
                  }}
                  name={'allThatApply'}
                  {...register('allThatApply', {
                    validate: () =>
                      // your validation logic here :)
                      checkboxGroupSelection.havingEdifier.length > 0,
                  })}
                />
              }
              label={item.description}
            />
          ))}

          {errors && !!errors?.allThatApply && checkboxGroupSelection?.havingEdifier.length <= 0 && (
            <Typography variant="validation" sx={{ fontSize: '15px' }}>
              Check All that apply
            </Typography>
          )}
        </Box>
        <Box>
          <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
            Anything else you would like to share (SL , LT)
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            fullWidth
            name="anythingElse"
            {...register('anythingElse')}
            defaultValue={prePopulatedData && prePopulatedData?.anythingElse}
          />
        </Box>

        {!prePopulatedData && (
          <Box sx={{ textAlign: 'right' }}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              color="accept"
              sx={{ color: 'white', mr: '20px', mt: '30px' }}
            >
              Submit
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default FinalSessionSurvey;
