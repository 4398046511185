import React from 'react';
import { svgPaths } from '../../_mock';

const ReviewStars = ({ setReviewsGiven, reviewsGiven }) => (
  <div style={{ textAlign: 'center' }}>
    <svg
      width="436"
      height="56"
      viewBox="0 0 436 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer', marginTop: '33.71px' }}
    >
      {svgPaths.map((item, key) => (
        <path
          d={item.d}
          fill={reviewsGiven !== 0 && key + 1 <= reviewsGiven ? '#F68901' : 'white'}
          stroke={reviewsGiven !== 0 && key + 1 <= reviewsGiven ? undefined : '#F68901'}
          key={key}
          onClick={() => {
            setReviewsGiven(key + 1);
          }}
        />
      ))}
    </svg>
  </div>
);

export default ReviewStars;
