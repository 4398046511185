/* eslint-disable */
import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui

import { styled } from '@mui/material/styles';
import { Typography, Button, Box, CircularProgress } from '@mui/material';
// hooks
import toast, { Toaster } from 'react-hot-toast';

import { useQueryVerifyUserEmail } from '../hooks/react-query/useData';

// components
import Logo from '../components/logo';

const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  marginTop: '0',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  paddingTop: '20px',
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const [searchParams] = useSearchParams();
  const [apiResponse, setApiResponse] = useState();
  const navigate = useNavigate();

  const onSuccess = (success) => {
    // toast.success(success?.data?.message, { duration: 10000 });

    if (success.data.message.includes('Account Verified Successfully')) {
      setApiResponse('success');
      return;
    }

    toast.success(success?.response?.data?.message, { duration: 5000 });
  };
  const onError = (error) => {
    if (error?.message === 'Network Error') {
      setApiResponse('Server Error');
      return;
    }
    setApiResponse(error?.response?.data?.message);
  };


  const { isLoading } = useQueryVerifyUserEmail(
    {
      token: searchParams.get('token'), // 10
    },
    onSuccess,
    onError
  );
  return (
    <Box sx={{ minWidth: '100vh' }}>
      <Logo
        sx={{
          position: 'fixed',
          width: '100%',
          justifyContent: 'center',
          background: 'white',
          p: '18px 0 12px 0',
        }}
      />

      {/* <Helmet>
        <title> Verify | Edifying Teachers </title>
      </Helmet> */}

      {/* <Toaster position="top-right" reverseOrder={false} /> */}
      <StyledContent sx={{ background: 'rgba(0, 0, 0, 0.06)' }}>
        {apiResponse === 'success' && (
          <Box
            sx={{
              height: '489px',
              width: '601px',
              boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
              borderRadius: '20px',
              mx: 'auto',
              background: 'white',
            }}
          >
            <Box component="img" src="/assets/emailVerifiedCheck.svg" sx={{ mx: 'auto' }} />

            <Typography variant="mainTitles" sx={{ textAlign: 'center', color: 'text.titles', mt: '12px' }}>
              Email Verified
            </Typography>
            <Typography variant="subtitle1" sx={{ textAlign: 'center', fontWeight: '400', lineHeight: '28px' }}>
              Thank you! Your email has been verified!. <br /> Your account is now active.
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
              <Button sx={{ mt: '16px' }} variant="contained" color="main" onClick={() => navigate('/login')}>
                Login to Your Account
              </Button>

              <Typography
                variant="specs"
                sx={{ textAlign: 'center', fontWeight: '400', lineHeight: '28px', mt: '57px' }}
              >
                Thank you for choosing Edifying Teachers!
              </Typography>
            </Box>
          </Box>
        )}
        {isLoading && (
          <Typography variant="h3" sx={{ mx: 'auto', textAlign: 'center' }}>
            Verifying User ... <CircularProgress />
          </Typography>
        )}
        {!isLoading && apiResponse && apiResponse !== 'success' && (
          <Typography variant="error" sx={{ textAlign: 'center' }}>
            {apiResponse}
          </Typography>
        )}
      </StyledContent>
    </Box>
  );
}
