import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { Card } from '../sections/@dashboard/find-edifier';

import { getUserDetails } from '../utils';
import { cardBoxStyles, verticalAlignStyles } from '../jsxStyles';
import { useQueryGetUserInvitesList, useMutationModifyMeetingInvite } from '../hooks/react-query/useData';

const MeetingInvites = () => {
  const userDetails = getUserDetails();
  const navigate = useNavigate();
  const [meetingInvitePayload, setMeetingInvitePayload] = useState({});
  const queryClient = useQueryClient();

  const { data: invitesData, isFetching } = useQueryGetUserInvitesList(userDetails?.token, navigate, toast);

  const { isLoading: isMeetingStatusModifying, mutate: updateMeetingStatus } = useMutationModifyMeetingInvite(
    meetingInvitePayload,
    toast,
    userDetails?.token,
    setMeetingInvitePayload,
    navigate,
    queryClient
  );

  useEffect(() => {
    if (Object.keys(meetingInvitePayload).length > 0) {
      updateMeetingStatus();
    }
  }, [meetingInvitePayload]);

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Meeting Invites</title>
      </Helmet>
      <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
        Meeting Invites
      </Typography>
      {isFetching && (
        <Box sx={{ width: '100%', textAlign: 'center', pt: '50px' }}>
          <CircularProgress />
        </Box>
      )}

      {invitesData?.data?.message.includes('No Invites') && (
        <Box
          sx={{
            ...cardBoxStyles,
            ...verticalAlignStyles,
            boxShadow: 'none',
            p: '0',
            borderRadius: '10px',
            minWidth: '100%',
            height: '70px',
            mt: '20px',
          }}
        >
          <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
            {invitesData?.data?.message}
          </Typography>
        </Box>
      )}
      {!isFetching && (
        <Grid container spacing={'28.47px'} sx={{ mt: '31px' }}>
          {invitesData?.data?.data?.length &&
            invitesData?.data?.data?.map((data, key) => (
              <Card
                key={key}
                data={data}
                index={1}
                isInviteCard
                setMeetingInvitePayload={setMeetingInvitePayload}
                meetingInvitePayload={meetingInvitePayload}
                isMeetingStatusModifying={isMeetingStatusModifying}
                userRole={userDetails?.role}
              />
            ))}
        </Grid>
      )}
    </Box>
  );
};

export default MeetingInvites;
