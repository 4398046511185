import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';

import {  welcomeSessionSurveyItems, sessionASurveyHeadItems } from '../../_mock';

import { stepperBoxStyles } from '../../jsxStyles';

const SessionSurveyA = ({ setPayload, participantId, apiPayload, submitSurvey, isLoading, prePopulatedData }) => {
  const [checkboxGroupSelection, setCheckboxGroupSelection] = useState({
    aboutEdifier: {
      edifierWellPrepared: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      tempoAndFlowFeelsRight: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      gettingPracticalAdvice: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      identifiedResourcesSlLt: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      feelsSupported: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    // getValues,
    // setValue,
    // watch,
  } = useForm({});

  const onSubmit = (submittedData) => {
    const updatedPayload = {
      edifierAsessment: checkboxGroupSelection.aboutEdifier,
      anythingElse: submittedData.anythingElse,
      title: 'Session Survey A',
    };
    setPayload({
      participantId,
      surveyData: updatedPayload,
    });
  };

  useEffect(() => {
    if (apiPayload && Object.keys(apiPayload).length > 0) {
      submitSurvey();
    }
  }, [apiPayload]);

  useEffect(() => {
    if (prePopulatedData) {
      setCheckboxGroupSelection({ aboutEdifier: prePopulatedData?.edifierAsessment });
    }
  }, [prePopulatedData]);

  return (
    <Box sx={{ ...stepperBoxStyles, minWidth: '100%', mt: '30px' }}>
      <Typography
        variant="h3"
        sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif", mb: '50px', textAlign: 'center' }}
      >
        Session Survey A
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        {sessionASurveyHeadItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: '25px', md: index !== 0 && '30px' },
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                width: '200px',
                fontSize: '18px',
                fontWeight: 300,
                display: 'grid',
                alignItems: 'center',
                lineHeight: '25px',
              }}
              variant="form"
            >
              {item?.title}
            </Typography>

            {index === 0 &&
              welcomeSessionSurveyItems.map((subItem, subIndex) => (
                <Typography
                  key={subIndex}
                  sx={{
                    width: '156px',
                    mb: '24px',
                    fontWeight: 500,
                    color: '#000',
                    minWidth: 'max-content',
                    display: { xs: 'none', md: 'inherit' },
                    justifyContent: 'center',
                  }}
                  variant="subtitle1"
                >
                  {subItem}
                </Typography>
              ))}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  Strongly Agree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.aboutEdifier[item?.name]?.stronglyAgree || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      {...register('item', {
                        validate: () =>
                          // your validation logic here :)
                          JSON.stringify(checkboxGroupSelection.aboutEdifier).split('true').length - 1 === 5,
                      })}
                      name={`${item?.name}-stronglyAgree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          aboutEdifier: {
                            ...checkboxGroupSelection.aboutEdifier,
                            [item?.name]: {
                              stronglyAgree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Agree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      {...register('item', {
                        validate: () =>
                          // your validation logic here :)
                          JSON.stringify(checkboxGroupSelection.aboutEdifier).split('true').length - 1 === 5,
                      })}
                      checked={checkboxGroupSelection?.aboutEdifier[item?.name]?.agree || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item?.name}-agree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          aboutEdifier: {
                            ...checkboxGroupSelection.aboutEdifier,
                            [item?.name]: {
                              agree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Disagree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      {...register('item', {
                        validate: () =>
                          // your validation logic here :)
                          JSON.stringify(checkboxGroupSelection.aboutEdifier).split('true').length - 1 === 5,
                      })}
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.aboutEdifier[item?.name]?.disagree || false}
                      name={`${item?.name}-disagree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          aboutEdifier: {
                            ...checkboxGroupSelection.aboutEdifier,
                            [item?.name]: {
                              disagree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Strongly Disagree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      {...register('item', {
                        validate: () =>
                          // your validation logic here :)
                          JSON.stringify(checkboxGroupSelection.aboutEdifier).split('true').length - 1 === 5,
                      })}
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.aboutEdifier[item?.name]?.stronglyDisagree || false}
                      name={`${item?.name}-stronglyDisagree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          aboutEdifier: {
                            ...checkboxGroupSelection.aboutEdifier,
                            [item?.name]: {
                              stronglyDisagree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        ))}
        {errors && !!errors?.item && (
          <Typography variant="validation" sx={{ fontSize: '15px' }}>
            All options must be checked
          </Typography>
        )}

        <Box>
          <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
            Anything else you would like to share?
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            fullWidth
            name="anythingElse"
            {...register('anythingElse')}
            defaultValue={prePopulatedData ? prePopulatedData?.anythingElse : ''}
          />
        </Box>

        {!prePopulatedData && (
          <Box sx={{ textAlign: 'right' }}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              color="accept"
              sx={{ color: 'white', mr: '20px', mt: '30px' }}
            >
              Submit
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default SessionSurveyA;
