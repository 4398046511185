import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import { welcomeSessionSurveyHeadItems, welcomeSessionSurveyItems } from '../../_mock';

import { stepperBoxStyles } from '../../jsxStyles';

const WelcomeSessionSurvey = ({ setPayload, apiPayload, submitSurvey, isLoading, participantId, prePopulatedData }) => {
  const [checkboxGroupSelection, setCheckboxGroupSelection] = useState({
    aboutSession: {
      enjoyedSession: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      lookForwardToNext: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      workingWithThisEdifier: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
      howWillImproveMyTeaching: { stronglyAgree: false, agree: false, disagree: false, stronglyDisagree: false },
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    // getValues,
    // setValue,
    // watch,
  } = useForm({});

  const onSubmit = (submittedData) => {
    setPayload({
      participantId,
      surveyData: {
        aboutSession: checkboxGroupSelection?.aboutSession,
        anythingElse: submittedData?.anythingElse,
        title: 'Welcome Session Survey',
      },
    });
  };

  useEffect(() => {
    if (apiPayload && Object.keys(apiPayload).length > 0) {
      submitSurvey();
    }
  }, [apiPayload]);

  useEffect(() => {
    if (prePopulatedData) {
      setCheckboxGroupSelection({ aboutSession: prePopulatedData?.aboutSession });
    }
  }, [prePopulatedData]);

  return (
    <Box sx={{ ...stepperBoxStyles, minWidth: '100%', mt: '30px' }}>
      <Typography
        variant="h3"
        sx={{
          fontWeight: 400,
          fontFamily: "'Noto Sans JP', sans-serif",
          mb: '50px',
          textAlign: 'center',
          lineHeight: '30px',
        }}
      >
        Welcome Session Survey
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {welcomeSessionSurveyHeadItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: '25px', md: index !== 0 && '30px' },
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                width: '200px',
                fontSize: '18px',
                fontWeight: 300,
                display: 'grid',
                alignItems: 'center',
                lineHeight: '25px',
              }}
              variant="form"
            >
              {item.title}
            </Typography>

            {index === 0 &&
              welcomeSessionSurveyItems.map((subItem, subIndex) => (
                <Typography
                  key={subIndex}
                  sx={{
                    width: '156px',
                    mb: '24px',
                    fontWeight: 500,
                    color: '#000',
                    minWidth: 'max-content',
                    display: { xs: 'none', md: 'inherit' },
                    justifyContent: 'center',
                  }}
                  variant="subtitle1"
                >
                  {subItem}
                </Typography>
              ))}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  Strongly Agree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.aboutSession[item.name]?.stronglyAgree || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      {...register('item', {
                        validate: () =>
                          JSON.stringify(checkboxGroupSelection.aboutSession).split('true').length - 1 === 4,
                      })}
                      name={`${item}-teaching`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          aboutSession: {
                            ...checkboxGroupSelection.aboutSession,
                            [item.name]: {
                              stronglyAgree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Agree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      {...register('item', {
                        validate: () =>
                          // your validation logic here :)
                          JSON.stringify(checkboxGroupSelection.aboutSession).split('true').length - 1 === 4,
                      })}
                      checked={checkboxGroupSelection?.aboutSession[item.name]?.agree || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-coaching`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          aboutSession: {
                            ...checkboxGroupSelection.aboutSession,
                            [item.name]: {
                              agree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Disagree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      {...register('item', {
                        validate: () =>
                          JSON.stringify(checkboxGroupSelection.aboutSession).split('true').length - 1 === 4,
                      })}
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.aboutSession[item.name]?.disagree || false}
                      name={`${item}-disagree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          aboutSession: {
                            ...checkboxGroupSelection.aboutSession,
                            [item.name]: {
                              disagree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Strongly Disagree
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      {...register('item', {
                        validate: () =>
                          JSON.stringify(checkboxGroupSelection.aboutSession).split('true').length - 1 === 4,
                      })}
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.aboutSession[item.name]?.stronglyDisagree || false}
                      name={`${item}-stronglyDisagree`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          aboutSession: {
                            ...checkboxGroupSelection.aboutSession,
                            [item.name]: {
                              stronglyDisagree: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        ))}

        {errors && !!errors?.item && (
          <Typography variant="validation" sx={{ fontSize: '15px' }}>
            All options must be checked
          </Typography>
        )}

        <Box>
          <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
            Is there anything else you would like to share?
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            fullWidth
            name="anythingElse"
            {...register('anythingElse')}
            defaultValue={prePopulatedData ? prePopulatedData?.anythingElse : ''}
          />
        </Box>

        {!prePopulatedData && (
          <Box sx={{ textAlign: 'right' }}>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              color="accept"
              sx={{ color: 'white', mr: '20px', mt: '30px' }}
            >
              Submit
            </LoadingButton>
          </Box>
        )}
      </form>
    </Box>
  );
};

export default WelcomeSessionSurvey;
