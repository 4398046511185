import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';

import LoadingButton from '@mui/lab/LoadingButton';
import { useQueryClient } from 'react-query';
import { cardBoxStyles, adminRequestBoxStyles } from '../jsxStyles';
import { getUserDetails } from '../utils';
import { useQueryGetSideLeaderRequests, useMutationUpdateSiteLeaderRequest } from '../hooks/react-query/site-leader';
import { EmptyRecordsBox } from '../components/empty-records-box';

const SiteLeaderRequests = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userDetails = getUserDetails();

  const [payload, setPayload] = useState({});

  const { data: sideLeaderRequests, isFetching: isLoading } = useQueryGetSideLeaderRequests(
    userDetails?.token,
    navigate,
    toast
  );

  const { isLoading: isSiteLeaderRequestUpdating, mutate: updateUserRequest } = useMutationUpdateSiteLeaderRequest(
    payload,
    toast,
    userDetails?.token,
    navigate,
    setPayload,
    queryClient
  );

  useEffect(() => {
    if (Object.keys(payload).length > 0) {
      updateUserRequest();
    }
  }, [payload]);

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Edifiers</title>
      </Helmet>

      <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
        Requests
      </Typography>
      {((!isLoading && !sideLeaderRequests?.data?.data) || sideLeaderRequests?.data?.data?.length <= 0) && (
        <EmptyRecordsBox />
      )}

      {isLoading && (
        <Box sx={{ textAlign: 'center', height: 'fit-content', my: 'auto' }}>
          <CircularProgress
            sx={{ '&.MuiCircularProgress-root': { height: '40px !important', width: '40px !important' } }}
          />
        </Box>
      )}

      {sideLeaderRequests?.data?.data &&
        sideLeaderRequests?.data?.data?.map((data, index) => (
          <Box
            key={index}
            sx={{
              ...cardBoxStyles,
              ...adminRequestBoxStyles,
            }}
          >
            <Typography sx={{ color: 'text.titles', fontWeight: 'bold' }}>{data?.message}</Typography>

            <Box sx={{ display: 'flex', columnGap: '20px', justifyContent: 'flex-end' }}>
              {' '}
              <LoadingButton
                variant="contained"
                color="accept"
                sx={{ color: 'white' }}
                onClick={() => setPayload({ id: data?._id, status: 'ACCEPTED' })}
                loading={isSiteLeaderRequestUpdating && data?._id === payload?.id}
              >
                Approve
              </LoadingButton>
              <LoadingButton
                variant="contained"
                color="reject"
                sx={{ color: 'white' }}
                onClick={() => setPayload({ id: data?._id, status: 'REJECTED' })}
                loading={isSiteLeaderRequestUpdating && data?._id === payload?.id}
              >
                Reject
              </LoadingButton>
            </Box>
          </Box>
        ))}
    </Box>
  );
};

export default SiteLeaderRequests;
