import React from 'react';
import Quote from 'inspirational-quotes';

import { Typography, Box } from '@mui/material';

const Quotes = () =>  (
    <Box
      sx={{
        minHeight: '147px',
        background: '#FFF',
        boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
        borderRadius: '20px',
        p: { xs: '20px', md: '34px 14.62px 10.93px 47.01px' },
      }}
    >
      <Typography variant="h5" sx={{ color: '#769C98', fontWeight: 700 }}>
        Daily Quote
      </Typography>
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: '400', mb: '10px', mt: '10px' }}>
          {Quote.getQuote().text}
        </Typography>
        <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
          -{Quote.getQuote().author}
        </Typography>
      </Box>
      <Box component="img" src="/assets/quoteImage.svg" sx={{ ml: 'auto' }} />
    </Box>
  )

export default Quotes;
