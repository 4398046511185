import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';

export default function LimitTags({ placeholder, setGoals, errors,setValue }) {


  const handleChange = (event, value) => {

    setValue('goals',value)
    setGoals([...value]);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={[]}
        onChange={handleChange}
        freeSolo
        renderInput={(params) => (
          <TextField
            sx={{
              '& .MuiButtonBase-root': { background: 'white !important' },
            }}
            {...params}
            variant="filled"
            label={placeholder}
            // placeholder={placeholder}
          />
        )}
        error={(errors && !!errors?.goals).toString()}  // Convert boolean to string
        />
      {errors && !!errors?.goals && (
        <Typography variant="validation" sx={{ fontSize: '15px', position: 'absolute' }}>
          {errors?.goals?.message}
        </Typography>
      )}
    </div>
  );
}
