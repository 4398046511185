/*eslint-disable */
import { Axios } from 'axios';
import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
const SignatureCanvas = () => {
  const canvasRef = useRef(null);
  const [ctx, setCtx] = useState(null);
  const [drawing, setDrawing] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [lastPos, setLastPos] = useState({ x: 0, y: 0 });
  const [erasing, setErasing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      setCtx(context);
      context.strokeStyle = '#222222';
      context.lineWidth = 2; // Adjust line width for drawing
    }
  }, []);

  const handleMouseDown = (e) => {
    if (!erasing) {
      setDrawing(true);
      setLastPos(getMousePos(e));
    }
  };

  const handleMouseUp = () => {
    setDrawing(false);
  };

  const handleMouseMove = (e) => {
    if (drawing || erasing) {
      const currentMousePos = getMousePos(e);
      setMousePos(currentMousePos);
      renderCanvas(currentMousePos);
    }
  };

  const handleTouchStart = (e) => {
    if (!erasing) {
      setDrawing(true);
      setLastPos(getTouchPos(e));
    }
  };

  const handleTouchMove = (e) => {
    if (drawing || erasing) {
      const currentTouchPos = getTouchPos(e);
      setMousePos(currentTouchPos);
      renderCanvas(currentTouchPos);
    }
  };

  const handleTouchEnd = () => {
    if (!erasing) {
      setDrawing(false);
    }
  };

  const getMousePos = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    return {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  };

  const getTouchPos = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    return {
      x: e.touches[0].clientX - rect.left,
      y: e.touches[0].clientY - rect.top,
    };
  };

  const renderCanvas = (currentPos) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      context.beginPath();
      if (drawing) {
        context.moveTo(lastPos.x, lastPos.y);
        context.lineTo(currentPos.x, currentPos.y);
        context.stroke();
        setLastPos(currentPos);
      } else if (erasing) {
        context.globalCompositeOperation = 'destination-out';
        context.arc(currentPos.x, currentPos.y, 10, 0, Math.PI * 2, false);
        context.fill();
        context.globalCompositeOperation = 'source-over';
      }
    }
  };

  const clearCanvas = (e) => {
    e.preventDefault();
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const toggleEraser = (e) => {
    e.preventDefault()
    setErasing(!erasing);
  };

  const submitSignature = (e) => {
    e.preventDefault();
    const canvas = canvasRef.current;
    if (canvas) {
      const dataUrl = canvas.toDataURL();

      axios
        .get(dataUrl, { responseType: 'arraybuffer' })
        .then((response) => {
          const file = new File([response.data], 'signatures', { type: 'image/png' });

        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

      // You can use the svgString as needed (e.g., send it to a server)
    }
  };

  return (
    <div>
      <div className="row" id="digital-signature">
        <div className="col-md-12">
          <canvas
            ref={canvasRef}
            id="sig-canvas"
            width="300"
            height="40"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            Get a better browser, bro.
          </canvas>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <button
            className={`btn ${erasing ? 'active' : ''}`}
            onClick={toggleEraser}
            style={{ fontSize: '12px', fontWeight: 'bold' }}
          >
            {erasing ? 'Erasing' : 'Erase Signature'}
          </button>
          <button className="btn" onClick={clearCanvas} style={{ fontSize: '12px', fontWeight: 'bold' }}>
            Clear Signature
          </button>
          <button className="btn" onClick={submitSignature} style={{ fontSize: '12px', fontWeight: 'bold' }}>
            Submit Signature
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignatureCanvas;
