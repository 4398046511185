import React from 'react';
import { Box, Typography } from '@mui/material';

const EarnedBadgesCard = ({ item }) => {
  const { src, title, description } = item;
  return (
    <Box
      sx={{
        p: '46.04px 12.9px 37.77px 12.92px',
        height: '340.792px',
        width: '100%',
        textAlign: 'center',
        borderRadius: '20px',
        background: '#FFFFFF',
        boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
      }}
    >
      <Box component="img" src={src} sx={{ mx: 'auto' }} />
      <Typography variant="secondaryTitles" sx={{ fontWeight: 700, mt: '9.17px' }}>
        {title}
      </Typography>
      <Typography variant="subtitle2" sx={{ color: '#769C98', lineHeight: '19px' }}>
        {description}
      </Typography>
    </Box>
  );
};

export default EarnedBadgesCard;
