import React, { useEffect } from 'react';
import { Box, List, Button, Typography } from '@mui/material';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';

import Scrollbar from '../../../components/scrollbar';

import { useMutationReadAllNotifications } from '../../../hooks/react-query/useData';

const NotificationsDropdownContent = ({
  
  NotificationItem,
  token,
  alwaysViewMode,
  allNotificationsData,
  handleClose
}) => {
  const queryClient = useQueryClient();
  const {  mutate: readAllNotifications } = useMutationReadAllNotifications(toast, token, queryClient);

  useEffect(() => {
    if (!alwaysViewMode) {
      readAllNotifications();
    }
  }, []);

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '10px',
        boxShadow: '0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%);',
        mt: '28.66px',
        maxHeight: '600px',
      }}
    >
      <Box sx={{ textAlign: 'right',pr:'10px' }}>
        <CloseIcon sx={{cursor:'pointer'}} onClick={handleClose}/>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Typography
          variant="subtitle1"
          sx={{ color: 'text.titles', p: '0 0 0 24.17px', lineHeight: '70px', fontFamily: 'palatino' }}
        >
          Notifications
        </Typography>
        {/* <Typography variant="body2" sx={{ color: 'text.primary' }}>
            You have {totalUnRead} unread messages
          </Typography> */}
      </Box>

      {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

      <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
        <List disablePadding>
          {allNotificationsData.map((notification, index) => (
            <NotificationItem key={index} notification={notification} />
          ))}
        </List>
      </Scrollbar>

      <Box sx={{ p: 1, background: '#F3FAFF', borderRadius: '0px 0px 20px 20px' }}>
        <Button fullWidth disableRipple sx={{ fontSize: '12px', fontWeight: 500 }}>
          View All Notifications
        </Button>
      </Box>
    </Box>
  );
};

export default NotificationsDropdownContent;
