import React from 'react';
import { Typography, Box } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';

const desiredTimeAvailability = [
  'Weekday Early Morning',
  'Weekday During the School Day',
  'Weekday Evening',
  'Weekend Afternoon',
];

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0B87AC',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })
);

const PreferenceSettings = () => (
    <Box
      sx={{
        width: '100%',
        height: '279.472px',
        background: '#FFFFFF',
        borderRadius: '20px',
        boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: '0 34px 0 41.07px',
          p: '23.66px 0 21px 0',

          borderBottom: '1px solid #E2E7F1',
        }}
      >
        <Typography variant="normal">Usage</Typography>
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
          // label="iOS style
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          m: '0 34px 0 41.07px',
          p: '23.66px 0 21px 0',

          borderBottom: '1px solid #E2E7F1',
        }}
      >
        <Typography variant="normal">Time of Learning</Typography>

        <Box sx={{ display: 'flex', columnGap: '25.79px' }}>
          <TextField
            id=""
            name="time-slot"
            label="Select time slot"
            select
            sx={{
              width: '308px',
              height: '54px',
              '& .MuiFormLabel-root': {
                color: '#282A39 !important',
                fontWeight: '600 !important',
                fontSize: '18px !important',
                opacity: 0.3,
              },
              '& .MuiOutlinedInput-notchedOutline':{fontSize:'18px'}
            }}
            // InputLabelProps={{
            //   sx: { color: '#282A39', fontWeight: 600, fontSize: '18px', opacity: 0.3 ,
            // },
            // }}
          >
            {desiredTimeAvailability?.map((item, key) => (
              <MenuItem value={item} key={key}>
                {item}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
            // label="iOS style
          />
        </Box>
      </Box>
    </Box>
  );

export default PreferenceSettings;
