// overridden material ui styles with JSX props

export const verticalAlignStyles = {
  display: 'grid',
  textAlign: 'center',
  alignItems: 'center',
};

export const inputBaseTextColor = {
  '& .MuiInputBase-input': { color: '#569cd3' },
};

export const loginFormContainerStyles = {
  borderRadius: '30px',
  background: 'white',
  boxShadow: '0px 0px 10px 4px rgba(0, 0, 0, 0.10)',
  width: { xs: '350px', sm: '455px' },
  height: '386px',
  p: '72px 21px 0 21px',
  textAlign: 'center',
  mx: 'auto',
};

export const becomeMenteeBtnStyles = {
  borderRadius: 0,
  width: { xs: '250px', sm: '312px' },
  height: '48px',
  background: '#6B9D98 !important',
  mb: '16px',
  mt: '21px',
};

export const overrideBadgeStyles = {
  minWidth: '8px !important',
  height: '8px',
  borderRadius: '50%',
  p: '0',
  right: '5px',
  top: '3px',
};

export const trackTrainingJourneySubheader = {
  display: 'flex',
  columnGap: '15.54px',
  color: '#769C98',
  fontSize: '10px',
  fontWeight: 500,
};
export const certificatesContainerStyles = {
  borderRadius: '20px',
  p: '38.85px 102.16px',
  background: 'white',
  boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
};

export const stepRoundBoxStyles = {
  // background: '#85C4E7',
  border: '6px solid #FFF',
  borderRadius: '50px',
  fontSize: '34px',
  textAlign: 'center',
  display: 'grid',
  alignItems: 'center',
  fontFamily: "'Roboto', sans-serif",
  cursor: 'pointer',
};

export const listGridViewIconStyles = {
  height: '24px',
  width: '24px',
  cursor: 'pointer',
};

export const mainTitlesStyles = {
  mb: '5px',
  color: 'text.titles',
  display: 'block',
};

export const loginFormStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: { xs: '350px', sm: '408px' },
  p: '59px 43px 72.98px 38px',
  boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
  minHeight: '500px',
  maxHeght: '600px',

  // maxHeight:'500px',
  borderRadius: '20px',
  my: 'auto',
  background: 'white',
  mx: 'auto',
};

export const filterContainerStyles = {
  display: 'flex',
  columnGap: '17px',
  flexDirection: { xs: 'column', md: 'row' },
  rowGap: '20px',
};

export const addBtnStyles = {
  height: 'fit-content',
  my: 'auto',
  width: 'max-content',
  ml: { md: 'inherit', xs: 'auto' },
};

export const listGridViewContainerStyles = {
  display: 'flex',
  mt: '23.63px',
  justifyContent: 'space-between',
  flexDirection: { xs: 'column', md: 'row', rowGap: '20px' },
};

export const fullScreenDialogStyles = {
  '& .MuiPaper-root': {
    height: 'max-content',
    borderRadius: '20px',
    minWidth: { xs: '100%', md: 'fit-content' },
  },
};

export const cardBoxStyles = {
  width: '100%',
  maxWidth: '965px',
  borderRadius: '30px',
  background: 'white !important',
  p: { xs: '20px', sm: '38px 31px 46px 39px' },
  boxShadow: '0px 0px 10px 4px rgba(0, 0, 0, 0.10)',
  mx: 'auto',
};

export const stepperBoxStyles = {
  width: '100%',
  maxWidth: '965px',
  borderRadius: '30px',
  background: 'white',
  p: { xs: '20px', sm: '38px 31px 46px 39px' },
  boxShadow: '0px 0px 10px 4px rgba(0, 0, 0, 0.10)',
  mx: 'auto',
};

export const lastStepBoxStyles = {
  width: '100%',
  maxWidth: '965px',
  borderRadius: '30px',
  background: 'white',
  p: { xs: '25px 25px 30px 30px', sm: '38px 31px 46px 39px' },
  boxShadow: '0px 0px 10px 4px rgba(0, 0, 0, 0.10)',
  mx: 'auto',
};

export const ifRetiredTypographyStyles = {
  mr: '25px',
  display: 'grid',
  alignItems: 'center',
  lineHeight: '20px',
  fontWeight: 500,
  mb: '20px',
  mt: '20px',
};

export const typesOfResourcesTypographyStyles = {
  mr: '25px',
  display: 'grid',
  alignItems: 'center',
  lineHeight: '20px',
  fontWeight: 'bold',
  mb: '20px',
};
export const contentAreaTypographyStyles = {
  display: 'block',
  m: '40px 0 51px 0',
  fontSize: '24px',
  color: '#000',
  fontFamily: "'Noto Sans JP'",
  lineHeight: '35px',
};

export const gradeLevelTypographyStyles = {
  display: 'block',
  m: '26px 0 51px 0',
  fontSize: '24px',
  color: '#000',
  fontFamily: "'Noto Sans JP'",
  lineHeight: '35px',
};

export const multiCheckboxTypographyStyles = {
  width: '156px',
  mb: '24px',
  justifyContent: 'center',
  fontWeight: 500,
  color: '#000',
  minWidth: 'max-content',
  display: { xs: 'none', md: 'inherit' },
};

export const boxShadowStyles = {
  width: '100%',
  maxWidth: '965px',
  borderRadius: '30px',
  background: 'white',
  p: { xs: '20px', sm: '38px 31px 46px 39px' },
  boxShadow: '0px 0px 10px 4px rgba(0, 0, 0, 0.10)',
  mx: 'auto',
};

export const typographyMultiCheckboxTitleStyles = {
  width: '204px',
  fontSize: '18px',
  fontWeight: 300,
  display: 'grid',
  alignItems: 'center',
  lineHeight: '25px',
};
export const stepPageTitleStyles = {
  m: '26px 0 20px 0',
  fontSize: '24px',
  color: '#000',
  fontFamily: "'Noto Sans JP'",
};

export const multiCheckBoxMainTitleStyles = {
  m: '26px 0 40px 0',
  color: '#000',
  fontWeight: 300,
  letterSpacing: '0.15px',
};

export const imageBoxStyles = {
  width: '242px',
  height: '242px',
  background: 'rgba(0, 0, 0, 0.41)',
  borderRadius: '50%',
  textAlign: 'center',
  display: 'grid',
  alignItems: 'center',
  mt: '30px',
  mx: 'auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '242px 242px',
};

export const profileListDisplayItemsStyle = {
  marginLeft: '20px',
  color: '#8E71AD',
  fontWeight: '500',
  lineHeight: '24px',
  maxWidth: '400px',
};

export const manualTimeSlotsBoxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '20px',
  background: '#FFF',

  boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
  p: '24px 26px 24px 23px',
  mb: '28px',
};

export const imageBoxCoachProfileStyles = {
  borderRadius: '50%',
  height: { xs: '200px', sm: '250px' },
  width: { xs: '200px', sm: '250px' },

  minWidth: { xs: '200px', sm: '250px' },

  mx: 'auto',
  border: '1px solid lightgray',
};

export const profileBoxContainer = {
  boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
  borderRadius: '20px',
  background: 'white',
  width: '100%',
  mt: { xs: '15px', sm: '20px' },
};

export const availabilityTextStyles = {
  fontWeight: 500,
  letterSpacing: '0.2px',
  textDecoration: 'underline',
  display: 'flex',
  columnGap: '15px',
  cursor: 'pointer',
  minWidth: 'max-content',
  alignItems: 'center',
  '&:hover': {
    color: '#A94B9C',
  },
};

export const userInteractionBoxStyles = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  columnGap: '25px',
  rowGap: '15px',
  justifyContent: 'flex-end',
  mt: { xs: '20px', md: 'auto' },
  mb: { xs: '20px', md: 0 },
  textAlign: 'right',
};
export const userProfileBottomSectionStyles = {
  p: '20px 45px 36px 46px',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: { xs: 'column', sm: 'row' },
  columnGap: '20px',
  background: '#8E71AD',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  rowGap: '20px',
};
export const followMeBtnStyles = {
  fontWeight: 600,
  lineHeight: 'normal',
  color: 'white',
  mr: '15px',
  my: 'auto',
  minWidth: 'max-content',
};

export const expertiesAndResourcesStyles = {
  background: '#EDF7FF',
  border: '1px solid #98D1FD',
  p: '8.37px 21.46px',
  borderRadius: '3px',
  height: 'fit-content',
};

export const dashboardStatsContainerStyles = {
  display: 'flex',
  justifyContent: 'center',
  rowGap: '20px',
  columnGap: '20px',
  flexDirection: { xs: 'column', md: 'row' },
  flexWrap: 'wrap',
};

export const newMessageReceivedIdentifierStyles = {
  position: 'absolute',
  bottom: '70px',
  zIndex: 10000000,
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  width: '120px',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'none',
};

export const stepperNextPrevBtnStyles = {
  background: '#6B9D98 !important',
  width: { xs: '100px', sm: '164px' },
  height: { xs: '35px', sm: '48px' },
  color: 'white',
};

export const userProfileImageStyles = {
  mx: { xs: 'auto', md: 'initial' },

  borderRadius: '10px',
};
export const adminRequestBoxStyles = {
  minWidth: '100%',
  mt: '30px',
  borderRadius: '20px',
  height: { xs: 'max-content', md: '88px' },
  p: { xs: '20px', md: '0 30px' },
  display: 'grid',
  rowGap: '20px',
  alignItems: 'center',
  gridTemplateColumns: { xs: '1fr', md: 'auto auto' },
  justifyContent: 'space-between',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
};
