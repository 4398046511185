/*eslint-disable */
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';

import { svgPaths } from '../../_mock';

const ReviewStars = ({ setReviewsGiven, reviewsGiven, id, setEdifierRankings, edifierRankings }) => {
  const setEdifierRankingsPayload = (key) => {
    if (key + 1 in edifierRankings && edifierRankings[key + 1] === id) {
      delete edifierRankings[key + 1];
      setEdifierRankings({...edifierRankings});
      return;
    }
    let newObj = {};
    Object.keys(edifierRankings).forEach((keys) => {
      if (edifierRankings[keys] !== id) {
        newObj = { ...newObj, [keys]: edifierRankings[keys] };
      }
    });

    newObj = { ...newObj, [key + 1]: id };

    setEdifierRankings(newObj);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography color="inherit" variant="secondaryTitles" sx={{ fontSize: '16px', lineHeight: '20px', mt: '20px' }}>
        Rank Edifier
      </Typography>
      <svg
        width="150"
        height="56"
        viewBox="0 0 250 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: 'pointer', textAlign: 'center' }}
      >
        {svgPaths.map(
          (item, key) =>
            key <= 2 && (
              <g
                key={key}
                style={{
                  pointerEvents: key + 1 in edifierRankings && edifierRankings[key + 1] !== id && 'none',
                  opacity: key + 1 in edifierRankings && edifierRankings[key + 1] !== id && '0.5',
                }}
              >
                <path
                  d={item.d}
                  fill={key + 1 in edifierRankings && edifierRankings[key + 1] === id ? '#F68901' : 'lightgray'}
                  stroke={key + 1 in edifierRankings && edifierRankings[key + 1] === id ? undefined : '#F68901'}
                  onClick={() => {
                    // setReviewsGiven(key + 1);
                    setEdifierRankingsPayload(key);
                  }}
                />
                {key < svgPaths.length - 1 && ( // Add numbers between stars except for the last one
                  <text
                    x={(key + 0.5) * item.x} // Adjust positioning of the number based on your design
                    y="40" // Adjust positioning of the number based on your design
                    fill="black" // Change fill to black
                    fontSize="24" // Adjust font size based on your design
                    fontWeight="bold" // Adjust font weight based on your design
                    textAnchor="middle"
                    onClick={() => {
                      setEdifierRankingsPayload(key);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {key + 1}
                  </text>
                )}
              </g>
            )
        )}
      </svg>
    </div>
  );
};

export default ReviewStars;
