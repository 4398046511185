import React from 'react';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

import { LoadingButton } from '@mui/lab';


import Typography from '@mui/material/Typography';

export default function AlertDialog({ open, handleClose, setReportData, reportData, searchReport,loading }) {
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const handleStartDateChange = (date) => {
    setReportData({ ...reportData, startDate: date });
  };

  const handleEndDateChange = (date) => {
    setReportData({ ...reportData, endDate: date });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            width: { md: '700px', sm: '448px', xs: '350px' },
            height: '330px',
            borderRadius: '20px',
            py: '20px',
          },
        }}
      >
        <Box sx={{ textAlign: 'right', pr: '20px', cursor: 'pointer' }} onClick={handleClose}>
          <CloseIcon />
        </Box>
        <Typography variant="mainTitles" sx={{ color: '#A94B9C', fontSize: '25px', textAlign: 'center' }}>
          Download Report
        </Typography>
        <DialogContent>
          <Box sx={{ display: 'flex', height: 'fit-content', columnGap: '10px', my: '30px', justifyContent: 'center' }}>
            <Box sx={{ position: 'relative' }}>
              <span style={{ fontSize: '12px', display: 'block', position: 'absolute', top: '-20px' }}>Start Date</span>
              <TextField
                size="small"
                type="date"
                id="start-date"
                onChange={(e) => handleStartDateChange(new Date(e.target.value))}
              />
            </Box>

            <Box sx={{ position: 'relative' }}>
              <span style={{ fontSize: '12px', display: 'block', position: 'absolute', top: '-20px' }}>End Date</span>
              <TextField
                size="small"
                type="date"
                id="end-date"
                onChange={(e) => handleEndDateChange(new Date(e.target.value))}
              />
            </Box>
            <LoadingButton
              variant="contained"
              loading={loading}
              color="main"
              onClick={searchReport}
              sx={{ height: '30px', mt: 'auto', mb: 'auto' }}
            >
              Download
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
