import React from 'react';
import { Box, Typography } from '@mui/material';

const AboutCoach = ({ bio }) => (
  <Box sx={{ p: {xs:0,sm:'20px',md:'42.84px 52.48px 33.42px 54.28px'} }}>
    <Typography variant="subtitle1" sx={{ fontWeight: 500, lineHeight: '24px' }}>
      {bio ?? "This user didn't set any bio yet"}
    </Typography>
  </Box>
);

export default AboutCoach;
