/* eslint-disable */

import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { logOutUser, generateToastError } from '../../../utils';
import { queryKeys } from '../queryKeys';

// Checkin and Checkout Logic stars from here

const httpStatuses = { unauthorized: 401, error: 500 };

export const useQueryGetMeetingCredentialsToJoin = (inviteId, userToken, navigate, toast, setInviteId) => {
  const queryKey = [queryKeys.getMeetingCredentials];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/meeting/join/${inviteId}`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useQuery(queryKey, queryFn, {
    enabled: false,
    // retry: 0,
    cacheTime: 0,

    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onSuccess: (e) => {
      if (!e?.data?.data?.joinUrl) {
        toast.error('There is an Error while fetching meeting credentials, Please contact Administrator!');
        setInviteId('')
      }
    },
    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);

      setInviteId('');
    },
  });
};

export const useMutationCancelAMeeting = (cancelId, toast, userToken, navigate, queryClient) => {
  const queryKey = [queryKeys.cancelAMeeting];
  const queryFn = () =>
    axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/meeting/cancel/${cancelId}`,
      {},
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.getUpcomingMeetingsList] });
    },
    onError: (error) => {
      if (error?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, error);
    },
  });
};

export const useQueryGetUpcomingMeetingsList = (userToken, navigate, toast) => {
  const queryKey = [queryKeys.getUpcomingMeetingsList];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/meeting/upcoming-meetings`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    cacheTime: 0,

    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

//Join and Cancel Meeting flow//
