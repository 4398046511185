/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox'; // Import Checkbox
import { useForm } from 'react-hook-form';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import toast from 'react-hot-toast';
import { cardBoxStyles } from '../jsxStyles';
import { useQueryGetUsersList, useMutationSendBulkNotifications } from '../hooks/react-query/useData';

import { getUserDetails } from '../utils';

const Notifications = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({});

  const userDetails = getUserDetails();
  const navigate = useNavigate();

  const { data: usersList, isFetching: isLoading } = useQueryGetUsersList(
    { token: userDetails?.token, role: userDetails?.token.toLowerCase() },
    navigate,
    toast
  );
  const [bulkMessagesPayload, setBulkMessagesPayload] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState({
    edifiers: false,
    teachers: false,
    siteLeaders: false,
    all: false,
  });

  const { isLoading: isSendingNotifications, mutate: sendMessages } = useMutationSendBulkNotifications(
    bulkMessagesPayload,
    toast,
    userDetails?.token,
    setBulkMessagesPayload,
    setSelectedUsers,
    setValue,
    setSelectedRoles
  );

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedRoles((prevRoles) => ({
      ...prevRoles,
      [name]: checked,
    }));
  };

  const onSubmit = (data) => {
    const userIds = [];

    selectedUsers?.forEach((user) => {
      userIds.push(user?._id);
    });

    setBulkMessagesPayload({ message: data?.message, users: userIds });
  };

  useEffect(() => {
    if (Object.keys(bulkMessagesPayload).length > 0) {
      sendMessages();
    }
  }, [bulkMessagesPayload]);

  // Filter users based on selected roles
  useEffect(() => {
    if (usersList?.data?.data) {
      let filteredUsers = [];

      if (selectedRoles.all) {
        filteredUsers = [...usersList?.data?.data];
        setSelectedUsers(filteredUsers);

        return;
      }
      if (selectedRoles.edifiers) {
        filteredUsers = usersList?.data?.data.filter((user) => user.role === 'COACH');
      }
      if (selectedRoles.teachers) {
        filteredUsers = [...filteredUsers, ...usersList?.data?.data.filter((user) => user.role === 'TEACHER')];
      }
      if (selectedRoles.siteLeaders) {
        filteredUsers = [...filteredUsers, ...usersList?.data?.data.filter((user) => user.role === 'SITE-LEADER')];
      }
      setSelectedUsers(filteredUsers);
    }
  }, [selectedRoles, usersList]);

  return (
    <Box
      sx={{
        width: { xs: '350px', sm: '400px' },
      }}
    >
      <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
        Send Notifications
      </Typography>

      {isLoading && (
        <Box sx={{ textAlign: 'center' }}>
          {' '}
          <CircularProgress />
        </Box>
      )}

      {!isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              ...cardBoxStyles,
              mt: 0,
              borderRadius: '20px',
              p: { xs: '20px', sm: '30px 30px' },
              ml: 0,
              width: { xs: '300px', sm: '400px' },

              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            }}
          >
            <TextField
              label="Type Message Here ..."
              value={watch('message') || ''}
              variant="standard"
              sx={{ width: { xs: '250px', sm: '350px' }, mb: '20px' }}
              {...register('message', {
                required: `Please type your message`,
              })}
              error={errors && !!errors?.message}
              helperText={errors?.message?.message}
            />

            <Autocomplete
              limitTags={5}
              multiple
              id="tags-standard"
              options={usersList?.data?.data}
              getOptionLabel={(option) => `${option.fname} ${option.lname}`}
              onChange={(event, value) => setSelectedUsers(value)}
              value={selectedUsers}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Users"
                  placeholder="All Users"
                  {...register('users', {
                    validate: () => selectedUsers?.length > 0 || 'Please select user',
                  })}
                  error={errors && !!errors?.users && selectedUsers.length === 0}
                  helperText={errors?.users?.message}
                />
              )}
            />

            <FormGroup sx={{ mt: '20px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedRoles.edifiers}
                    onChange={handleCheckboxChange}
                    name="edifiers"
                    sx={{
                      '& .MuiSvgIcon-root': { color: '#5EC4C7' },
                    }}
                  />
                }
                label="Edifiers"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedRoles.teachers}
                    onChange={handleCheckboxChange}
                    name="teachers"
                    sx={{
                      '& .MuiSvgIcon-root': { color: '#5EC4C7' },
                    }}
                  />
                }
                label="Teachers"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedRoles.siteLeaders}
                    onChange={handleCheckboxChange}
                    name="siteLeaders"
                    sx={{
                      '& .MuiSvgIcon-root': { color: '#5EC4C7' },
                    }}
                  />
                }
                label="Site Leaders"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedRoles.all}
                    onChange={handleCheckboxChange}
                    name="all"
                    sx={{
                      '& .MuiSvgIcon-root': { color: '#5EC4C7' },
                    }}
                  />
                }
                label="Everyone"
              />
            </FormGroup>

            <LoadingButton
              loading={isSendingNotifications}
              type="submit"
              variant="contained"
              color="main"
              sx={{ mt: '20px' }}
            >
              Send Message
            </LoadingButton>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default Notifications;
