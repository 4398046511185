/* eslint-disable */
import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

function App({ meetingDetails, userName, email, startSessionLog, isUserCheckedIn }) {
  const [showNav, setShowNav] = useOutletContext();

  var sdkKey = process.env.REACT_APP_SDK_KEY;
  var meetingNumber = meetingDetails?.meetingId;
  var passWord = meetingDetails?.password;
  var userName = userName;
  var userEmail = email;
  var registrantToken = '';
  var zakToken = '';
  var leaveUrl = `${window.location.origin}/dashboard/upcoming-events`;

  useEffect(() => {
    window.ZoomMtg.setZoomJSLib('https://source.zoom.us/2.18.0/lib', '/av');
    window.ZoomMtg.preLoadWasm();
    window.ZoomMtg.prepareWebSDK();
    window.ZoomMtg.i18n.load('en-US');
    window.ZoomMtg.i18n.reload('en-US');
  }, []);

  function getSignature(e) {
    startMeeting(meetingDetails?.signature);
    if (!isUserCheckedIn) {
      startSessionLog();
    }
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block';
    setShowNav(true);

    window.ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        window.ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,

          success: (success) => {},
          error: (error) => {
            console.log(error);
          },
        });
      },

      error: (error) => {
        console.log(error);
      },
    });
  }
  useEffect(() => {
    let thisInterval = setInterval(() => {
      try {
        if (document.getElementsByClassName('footer__leave-btn').length > 0) {
          clearInterval(thisInterval);
          document.getElementsByClassName('footer__leave-btn')[0].addEventListener('click', () => {

            document.getElementById('zoom-modal').style.display = 'block';

            const disableLeaveButton = setInterval(() => {
              try {
                if (document.getElementsByClassName('leave-meeting-options__btn--default').length > 0) {
                  const leaveBtnElement = document.getElementsByClassName('leave-meeting-options__btn--default')[0];

                  leaveBtnElement.style.pointerEvents = 'none';
                  leaveBtnElement.style.opacity = '0.5';

                  clearInterval(disableLeaveButton);
                }
              } catch (err) {}
            }, 200);
          });
        }
      } catch (err) {
        console.log('The err', err);
      }
    }, 200);

    return () => {
      // Cleanup: remove the event listener when the component unmounts
      document.getElementsByClassName('footer__leave-btn')[0]?.removeEventListener('click');
    };
  }, []);

  return (
    <div className="App">
      <main>
        <button onClick={getSignature} style={{ fontWeight: 600 }}>
          Join Meeting
        </button>
      </main>
    </div>
  );
}

export default App;
