import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { EventsCard } from '../components/card';
import { cardBoxStyles, mainTitlesStyles } from '../jsxStyles';

import { getUserDetails, meetingIsNotInPast, isTenMinutesRemainingOrTimePassed } from '../utils';
import { ZoomSdk } from '../components/zoom-meetings';
import {
  useMutationCheckInUser,
  useQueryCheckinStatus,
  useMutationCheckOutUser,
} from '../hooks/react-query/checkinCheckout';
import { useMutateSubmitFirstSession, useQueryGetSurveyStatus } from '../hooks/react-query/useData';

import { useQueryGetMeetingCredentialsToJoin, useMutationCancelAMeeting } from '../hooks/react-query/meetings';
import {
  FirstSessionLog,
  WelcomeSessionSurvey,
  SessionSurveyA,
  SessionSurveyB,
  SessionSurveyC,
  FinalSessionSurveys,
} from '../components/surveys';

const UpcomingEvents = () => {
  const queryClient = useQueryClient();
  const userDetails = getUserDetails();
  const navigate = useNavigate();
  const [invitePayload, setInvitePayload] = useState('');
  const [cancelId, setCancelId] = useState('');

  const [meetingCount, setMeetingCount] = useState(-1);

  const [apiPayload, setPayload] = useState({});

  const currentUserRole = userDetails?.role.toLowerCase();

  const currentTime = new Date();

  const upcomingMeetings = useOutletContext();

  const { mutate: startSessionLog } = useMutationCheckInUser(
    toast,
    userDetails?.token,
    navigate,
    '',
    queryClient,
    invitePayload._id
  );

  const { mutate: cancelAMeeting, isLoading: isCancellingMeeting } = useMutationCancelAMeeting(
    cancelId,
    toast,
    userDetails?.token,
    navigate,
    queryClient
  );

  const {
    data: meetingCredentials,
    refetch: getMeetingCredentialsToJoin,
    isFetching: isLoadingCredentials,
  } = useQueryGetMeetingCredentialsToJoin(invitePayload._id, userDetails?.token, navigate, toast, setInvitePayload);

  const { data, refetch: getUserCheckInStatus } = useQueryCheckinStatus(
    userDetails?.token,
    navigate,
    toast,
    currentUserRole,
    invitePayload._id,
    queryClient
  );

  const { mutate: checkoutUser } = useMutationCheckOutUser(
    toast,
    userDetails?.token,
    navigate,
    currentUserRole,
    queryClient,
    invitePayload._id
  );

  const {
    mutate: submitSurvey,
    isLoading: isSurveySubmitting,
    isError,
  } = useMutateSubmitFirstSession(
    apiPayload,
    navigate,
    toast,
    userDetails?.token,
    checkoutUser,
    data?.data?.data?._id,
    queryClient
  );

  const { data: surveyStatus, refetch: getSurveyStatus } = useQueryGetSurveyStatus(
    userDetails?.token,
    navigate,
    toast,
    data?.data?.data?._id
  );

  useEffect(() => {
    upcomingMeetings[3]();
  }, []);

  useEffect(() => {
    getSurveyStatus();
  }, [data?.data?.data?._id]);

  useEffect(() => {
    if (Object.keys(invitePayload).length > 0) {
      getMeetingCredentialsToJoin();
      getUserCheckInStatus();
    }
  }, [invitePayload]);

  useEffect(() => {
    if (cancelId) {
      cancelAMeeting();
    }
  }, [cancelId]);

  useEffect(() => {
    if (upcomingMeetings[2]?.data?.data) {
      let counter = 0;

      upcomingMeetings[2]?.data?.data.forEach((element) => {
        if (
          meetingIsNotInPast(element, currentTime) &&
          !isTenMinutesRemainingOrTimePassed(element?.scheduleTime, true)
        ) {
          counter += 1;
        }
      });

      setMeetingCount(counter);
    }
  }, [upcomingMeetings[2]?.data?.data]);

  const showSurveyToUser = () => {
    const allProps = {
      apiPayload,
      setPayload,
      isLoading: isSurveySubmitting,
      submitSurvey,
      participantId: invitePayload?.userId,
    };
    if (currentUserRole === 'teacher') {
      if (data?.data?.data?.lastSession) {
        return <FinalSessionSurveys {...allProps} />;
      }
      if (data?.data?.data?.totalCompletedSessions <= 2 || !data?.data?.data?.totalCompletedSessions) {
        return <WelcomeSessionSurvey {...allProps} />;
      }
      if (data?.data?.data?.totalCompletedSessions % 3 === 0) {
        return <SessionSurveyA {...allProps} />;
      }
      if (data?.data?.data?.totalCompletedSessions % 3 === 1) {
        return <SessionSurveyB {...allProps} />;
      }
      if (data?.data?.data?.totalCompletedSessions % 3 === 2) {
        return <SessionSurveyC {...allProps} />;
      }
    }
    return <FirstSessionLog {...allProps} firstLog={data?.data?.data?.totalCompletedSessions === 0} />;
  };

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Upcoming Events</title>
      </Helmet>
      {!surveyStatus?.data?.data?.surveyData && !isError && (
        <Box id="zoom-modal" sx={{ overflowY: 'scroll', height: '100%' }}>
          {showSurveyToUser()}
        </Box>
      )}

      <Typography variant="mainTitles" sx={{ mb: '20px', color: 'text.titles', display: 'block' }}>
        Upcoming Meetings
      </Typography>

      {(upcomingMeetings[2]?.data?.data !== undefined || upcomingMeetings[2]?.data?.message) &&
        (!upcomingMeetings[2]?.data?.data || upcomingMeetings[2]?.data?.data?.length === 0 || meetingCount === 0) && (
          <Box
            sx={{
              ...cardBoxStyles,
              minWidth: '100%',
              mt: '30px',
              borderRadius: 0,
              boxShadow: 'none',
              height: '88px',
              p: '0',
              display: 'grid',
              alignItems: 'center',
            }}
          >
            <Typography variant="mainTitles" sx={{ ...mainTitlesStyles, textAlign: 'center', py: '20px' }}>
              No Scheduled Meetings Found
            </Typography>
          </Box>
        )}

      {upcomingMeetings[4] && (
        <Box sx={{ textAlign: 'center', height: 'fit-content', my: 'auto' }}>
          <CircularProgress
            sx={{ '&.MuiCircularProgress-root': { height: '40px !important', width: '40px !important' } }}
          />
        </Box>
      )}

      {(!meetingCredentials?.data?.message.includes('Meeting Details Successfull') ||
        meetingCredentials?.data?.data?.joinUrl === null) && (
        <Grid container sx={{ mt: '30px', rowGap: '30px' }}>
          {upcomingMeetings[2]?.data?.data?.map(
            (item, index) =>
              meetingIsNotInPast(item, currentTime) &&
              !isTenMinutesRemainingOrTimePassed(item?.scheduleTime, true) && (
                <Grid key={index} item xs={12} md={6} xl={4}>
                  <EventsCard
                    item={item}
                    setInvitePayload={setInvitePayload}
                    isLoadingCredentials={isLoadingCredentials}
                    setCancelId={setCancelId}
                    isCancellingMeeting={isCancellingMeeting}
                    cancelId={cancelId}
                    inviteId={invitePayload}
                  />
                </Grid>
              )
          )}
        </Grid>
      )}
      {!isLoadingCredentials &&
        meetingCredentials?.data?.message.includes('Meeting Details Successfull') &&
        meetingCredentials?.data?.data?.joinUrl != null && (
          <ZoomSdk
            meetingDetails={meetingCredentials?.data?.data}
            userName={userDetails?.fname}
            email={userDetails?.email}
            startSessionLog={startSessionLog}
            isUserCheckedIn={data?.data?.data?.checkin}
            inviteId={invitePayload._id}
            isSurveySubmitted={surveyStatus?.data?.data?.surveyData}
          />
        )}
    </Box>
  );
};

export default UpcomingEvents;
