// ----------------------------------------------------------------------

export default function Typography() {
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          minWidth:'max-content !important',
          '& .MuiFormControlLabel-label': {
            fontFamily: "'Roboto', sans-serif",
            color:'rgba(0, 0, 0, 0.60)',
            minWidth:'max-content',

            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '12px' /* 85.714% */,
            letterSpacing: '0.15px',
          },
        },
      },
    },
  };
}
