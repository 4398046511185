import React from 'react';

import { Dialog, Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function EdifierDetailsDialog({ open, handleClose, userData }) {
  //   useEffect(() => {
  //     if (Object.keys(editedSchoolData).length !== 0) {
  //       modifySchool();
  //     }
  //   }, [editedSchoolData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            width: { xs: '350px', sm: '649px' },
            height: 'max-content',
            borderRadius: '20px',
            minHeight: '300px',
            padding: '30px',
          },
        }}
      >
        <Box sx={{ textAlign: 'right' }}>
          <CloseIcon sx={{ fontSize: '20px', cursor: 'pointer' }} onClick={handleClose} />
        </Box>
        <Typography variant="specs" sx={{ mt: '15px' }}>
          Expertise:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            columnGap: '3.87px',
            mt: '8.28px',
            flexWrap: 'wrap',
            rowGap: '10px',
            pr: '20px',
            pb: userData?.info?.expertiesAndResources && '30px',
          }}
        >
          {userData?.info?.expertiesAndResources?.map((item, key) => (
            <Box
              key={key}
              sx={{
                background: '#EDF7FF',
                border: '1px solid #98D1FD',
                p: '3.08px 10.7px',
                borderRadius: '3px',
              }}
            >
              <Typography variant="specs" sx={{ color: '#8D71AC', fontWeight: 500, whiteSpace: 'break-spaces' }}>
                {item}
              </Typography>
            </Box>
          ))}
        </Box>{' '}
      </Dialog>
    </div>
  );
}
