/*eslint-disable */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import Button from '@mui/material/Button';

import DialogContent from '@mui/material/DialogContent';

import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';

import CloseIcon from '@mui/icons-material/Close';

import Checkbox from '@mui/material/Checkbox';

import { fullScreenDialogStyles, cardBoxStyles, filterContainerStyles } from '../../jsxStyles';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function SurveysDialog({
  open,
  handleClose,
  goals,
  setUpdatedGoalsPayload,
  isSubmittingGoals,
}) {
  const [currentUserGoals, setCurrentUserGoals] = useState({});
  const [defaultSearchIndex, setDefaultSearchIndex] = useState(0);

  useEffect(() => {
    if (goals) {
      setCurrentUserGoals(JSON.parse(JSON.stringify(goals)));
    }
  }, [goals]);

  const refactorGoalsPayloadForBacked = () => {
    const changedGoals = [];

    currentUserGoals.map((goalsOfCurUser, index) => {
      goalsOfCurUser?.goals?.forEach((goal, subIndex) => {
        const refactoredGoal = { ...goal };

        const _id = refactoredGoal?._id;

        delete refactoredGoal['_id'];
        if (goal?.isCompleted !== goals[index]?.goals[subIndex]?.isCompleted) {
          changedGoals?.push({ _id: _id, goal: refactoredGoal });
        }
      });
    });

    const newPayload = {
      goals: changedGoals,
      // surveyId,
    };

    setUpdatedGoalsPayload({ ...newPayload });
  };

  const resetGoals = () => {
    setCurrentUserGoals(JSON.parse(JSON.stringify(goals)));
  };

  const isGoalCompleted = (goalIndex, index) => {
    return goals[goalIndex]?.goals && goals[goalIndex]['goals'][index]?.isCompleted;
  };

  return (
    <div>
      <Dialog
        open={open || false}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ...fullScreenDialogStyles,
          '& .MuiPaper-root': {
            height: '700px',
            p: '30px',
            maxWidth: { xs: '100%', sm: '600px' },

            minWidth: { xs: '90%', sm: 'inherit' },
          },
        }}
      >
        <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', ml: 'auto' }} />

        <Box component="img" src="/assets/dashboard/goalsIcon.svg" sx={{ width: '81px', height: '79px', mx: 'auto' }} />
        <Typography
          variant="mainTitles"
          sx={{ textAlign: 'center', color: 'text.titles', fontSize: '25px', mt: '20px' }}
        >
          Goals
        </Typography>
        <Box sx={{ display: 'flex', columnGap: '20px', justifyContent: 'center', mt: '30px' }}>
          {goals && (
            <Autocomplete
              id="tags-standard"
              options={goals}
              getOptionLabel={(option) => option?.name}
              onChange={(event, value, i) => setDefaultSearchIndex(value?.id)}
              // value={[{fname:'usman',lname:'haider'},{fname:'muhammad',lname:'masham'}]}
              defaultValue={goals && goals[defaultSearchIndex]}
              renderInput={(params) => (
                <TextField {...params} size="small" placeholder="Select User" sx={{ width: '233px' }} />
              )}
            />
          )}
{/* 
          <Button variant="contained" color="main">
            Search
          </Button> */}
        </Box>

        <Typography
          variant="mainTitles"
          sx={{ fontSize: '18px', color: 'text.titles', mt: '20px', textAlign: 'center' }}
        >
          {' '}
          {currentUserGoals[defaultSearchIndex]?.name
            ? `${currentUserGoals[defaultSearchIndex]?.name}'s Tasks Here`
            : ''}
        </Typography>

        <DialogContent
          sx={{
            p: { xs: '0 !important', sm: 'inherit' },
            '&.MuiDialogContent-root': {
              pb: '26px !important',
            },
          }}
        >
          {/* {!isTeacher && (
            <Box sx={{ ...filterContainerStyles, justifyContent: 'center', mt: '20px' }}>
              <TextField
                placeholder="Search Mentees"
                className="filters"
                sx={{ width: '150px' }}
                onChange={(e) => setSearchedValue(e.target.value.toLowerCase())}
              />

              <Button
                variant="contained"
                color="main"
                sx={{ height: 'fit-content', my: 'auto', width: 'max-content' }}
                onClick={() => searchFromRecord()}
              >
                Search
              </Button>
            </Box>DefaultSearchIndex
          )} */}
          <Box style={{ padding: '20px', textAlign: 'left' }}>
            {JSON.stringify(currentUserGoals) !== JSON.stringify(goals) && (
              <Box sx={{ display: 'flex', columnGap: '10px', justifyContent: 'flex-end' }}>
                <LoadingButton
                  variant="contained"
                  sx={{ background: 'white', color: 'black', height: '30px' }}
                  loading={isSubmittingGoals}
                  onClick={() => resetGoals()}
                >
                  Cancel
                </LoadingButton>
                <LoadingButton
                  loading={isSubmittingGoals}
                  color="main"
                  sx={{ height: '30px' }}
                  variant="contained"
                  onClick={() => refactorGoalsPayloadForBacked()}
                >
                  Save
                </LoadingButton>
              </Box>
            )}

            {currentUserGoals?.length > 0 &&
              goals &&
              currentUserGoals?.map(
                (goalObj, goalIndex) =>
                  defaultSearchIndex === goalObj?.id && (
                    <div key={goalIndex}>
                      {goalObj?.goals?.map((goal, index) => (
                        <Box
                          key={index}
                          sx={{
                            ...cardBoxStyles,
                            minWidth: '100%',
                            mt: '5px',
                            borderRadius: '10px',
                            height: '50px',
                            p: '0 10px',
                            mb: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            background: isGoalCompleted(goalIndex, index) && '#5EC4C7',
                          }}
                        >
                          {isGoalCompleted(goalIndex, index) && (
                            <Box component="img" src="/assets/dashboard/completedGoalIcon.svg" sx={{ mr: '10px' }} />
                          )}
                          {!isGoalCompleted(goalIndex, index) && (
                            <Checkbox
                              {...label}
                              checked={currentUserGoals[goalIndex]['goals'][index]?.isCompleted}
                              onChange={(e) => {
                                currentUserGoals[goalIndex]['goals'][index].isCompleted = e.target.checked;

                                setCurrentUserGoals([...currentUserGoals]);
                              }}
                              sx={{
                                '& .MuiSvgIcon-root': { color: '#5EC4C7' },
                                pointerEvents: isGoalCompleted(goalIndex, index) && 'none',
                                opacity: isGoalCompleted(goalIndex, index) && 0.5,
                              }}
                            />
                          )}
                          <span
                            style={{
                              color: isGoalCompleted(goalIndex, index) && 'white',
                              fontSize: isGoalCompleted(goalIndex, index) && '18px',
                              fontWeight: isGoalCompleted(goalIndex, index) && 600,
                            }}
                          >
                            {goal?.title}
                          </span>
                        </Box>
                      ))}
                    </div>
                  )
              )}
          </Box>{' '}
        </DialogContent>
      </Dialog>
    </div>
  );
}
