import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';

import ResetPassword from '../../pages/ResetPasswordAdmin';

export default function AlertDialog({ open, handleClose, isLoading, setOpen, editData, setEditData }) {
  const [previewImage, setPreviewImage] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  //   useEffect(() => {
  //     if (Object.keys(editedSchoolData).length !== 0) {
  //       modifySchool();
  //     }
  //   }, [editedSchoolData]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            width: { xs: '350px', sm: '649px' },
            height: 'max-content',
            borderRadius: '20PX',
          },
        }}
      >
        <ResetPassword
          setPreviewImage={setPreviewImage}
          setSelectedImage={setSelectedImage}
          previewImage={previewImage}
          selectedImage={selectedImage}
          setOpen={setOpen}
          editData={editData}
          setEditData={setEditData}
          isLoading={isLoading}
        />
      </Dialog>
    </div>
  );
}
