import { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
// @mui
import io from 'socket.io-client';
import { useQueryClient } from 'react-query';

import { styled } from '@mui/material/styles';
import { getUserDetails } from '../../utils';
import { useQueryGetUpcomingMeetingsList } from '../../hooks/react-query/meetings';
import { useQueryGetPairedEdifiers } from '../../hooks/react-query/pairing';

import { useQueryGetNotifications } from '../../hooks/react-query/useData';

//

//
import Header from './header';
import Nav from './nav';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  minHeight: '100%',

  paddingLeft: '35px',
  paddingRight: '35px',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [socketInstance, setSocketInstance] = useState(null);

  const [showNav, setShowNav] = useState(false);

  const userDetails = getUserDetails();

  const {
    data: eventsData,
    refetch: refetchEventsData,
    isLoading,
  } = useQueryGetUpcomingMeetingsList(userDetails?.token, navigate, toast);
  const queryClient = useQueryClient();

  const { data: notificationsData, refetch: refetchNotifications } = useQueryGetNotifications(
    toast,
    userDetails?.token,
    navigate,
    queryClient
  );

  const {
    isFetching: isLoadingEdifiersPair,
    data,
    refetch: refetchEdifiersPair,
  } = useQueryGetPairedEdifiers(userDetails?.token, navigate, toast);

  useEffect(() => {
    refetchEdifiersPair();
  }, [userDetails?.token]);

  useEffect(() => {
    if (!socketInstance) {
      setSocketInstance(io.connect(process.env.REACT_APP_SERVER_URL, { query: { token: userDetails?.token } }));
    }

    socketInstance?.on('connect', () => {
      console.log('Connected!');

      // Emitting a "send message" event
    });
  }, []);

  useEffect(() => {
    socketInstance?.on('notification', (message) => {
      toast(message?.message, {
        icon: '👏',
      });
      refetchNotifications();
    });
  }, [socketInstance]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} showNav={showNav} data={notificationsData} />

      <Nav openNav={open} onCloseNav={() => setOpen(false)} showNav={showNav} />

      <Main>
        <Outlet
          context={[
            showNav,
            setShowNav,
            eventsData,
            refetchEventsData,
            isLoading,
            isLoadingEdifiersPair,
            data,
            refetchEdifiersPair,
            socketInstance,
          ]}
        />
      </Main>
    </StyledRoot>
  );
}
