/*eslint-disable*/

/* This page is a generic page used in 3 cases
1) Teachers getting paired with edifiers
2)Admin updating and viewing edifiers
3)Site-leaders updating and viewing users based on their district */

import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Box, Button, CircularProgress, TextField } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Card } from '../sections/@dashboard/find-edifier';
import { UserDetailedInformationDialog } from '../components/dialog';
// import { CheckinCheckout } from '../components/checkin';
import { getUserDetails, sortByLname } from '../utils';
import { useQueryGetCoachesList, useQueryDeleteCoach } from '../hooks/react-query/useData';
import {
  listGridViewIconStyles,
  filterContainerStyles,
  addBtnStyles,
  listGridViewContainerStyles,
  cardBoxStyles,
} from '../jsxStyles';

import { useMutationPairWithEdifier } from '../hooks/react-query/pairing';
import { DeleteDialog, DeleteOperationDialog, AddCoachDialog } from '../components/dialog';
import { TableView } from '../components/table';

const FindAnEdifier = () => {
  const userDetails = getUserDetails();

  const pairedUsers = useOutletContext();

  const userRole = userDetails.role.toLowerCase();
  const userToken = userDetails.token;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [deleteOperationOpen, setDeleteOperationOpen] = useState(false);
  const [addEdifierOpen, setAddEdifierOpen] = useState(false);
  const [editEdifierOpen, setEditEdifierOpen] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [coachesRecord, setCoachesRecord] = useState([]);

  const [edifierRankings, setEdifierRankings] = useState({});

  const [reviewsGiven, setReviewsGiven] = useState(0);

  const [isListView, setIsListView] = useState(false);

  const [displayEdifierDetails, setDisplayEdifierDetails] = useState(false);

  const [itemToDeleteId, setItemToDeleteId] = useState('');

  const [currentEdifier, setCurrentEdifier] = useState({});

  const [edifierToEditData, setEdifierToEditData] = useState({});

  const [rankedEdifierPayload, setRankedEdifierPayload] = useState({});

  const { data: coachesList, isFetching: isLoading } = useQueryGetCoachesList(
    { token: userDetails?.token, role: userDetails?.role.toLowerCase() },
    navigate,
    toast
  );

  const { isLoading: isSendingPairRequest, mutate: pairWithEdifier } = useMutationPairWithEdifier(
    toast,
    userDetails?.token,
    navigate,
    // searchParams.get('id'),
    queryClient,
    rankedEdifierPayload,
    setRankedEdifierPayload
  );

  const { isLoading: isDeletingCoach, refetch: deleteCoach } = useQueryDeleteCoach(
    { token: userDetails?.token, _id: itemToDeleteId },
    navigate,
    setDeleteOperationOpen,
    toast,
    queryClient,
    userRole
  );

  useEffect(() => {
    if (checkIfRecordIsNotNullFromBe(coachesList)) {
      setCoachesRecord([]);
    } else {
      setCoachesRecord(coachesList?.data?.data);
    }
  }, [coachesList]);

  useEffect(() => {
    if (Object.keys(rankedEdifierPayload).length > 0) {
      pairWithEdifier();
    }
  }, [rankedEdifierPayload]);

  const checkIfRecordIsNotNullFromBe = (coachesList) => {
    return (
      coachesList?.data?.data?.includes(null) &&
      JSON.stringify(coachesList?.data?.data).split(null)?.length - 1 === coachesList?.data?.data?.length
    );
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteOperationClose = () => {
    setDeleteOperationOpen(false);
  };

  const handleAddCoachClose = () => {
    setAddEdifierOpen(false);
    setEditEdifierOpen(false);
    setEdifierToEditData({});
  };

  const searchFromRecord = () => {
    if (searchedValue === '') {
      if (checkIfRecordIsNotNullFromBe(coachesList)) {
        setCoachesRecord([]);
      } else {
        setCoachesRecord(coachesList?.data?.data);
      }
      return;
    }
    const filtered = checkIfRecordIsNotNullFromBe(coachesList)
      ? []
      : coachesList?.data?.data.filter((item) => item?.fname?.toLowerCase().includes(searchedValue));

    setCoachesRecord(filtered);
  };

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Edifiers</title>
      </Helmet>

      <Container maxWidth="xl" sx={{ padding: '0 !important' }}>
        <DeleteDialog
          open={open && itemToDeleteId !== ''}
          handleClose={handleClose}
          deleteCoach={deleteCoach}
          setDeleteOperationOpen={setDeleteOperationOpen}
        />
        <DeleteOperationDialog
          open={deleteOperationOpen}
          handleDeleteOperationClose={handleDeleteOperationClose}
          isDeleting={isDeletingCoach}
        />
        <AddCoachDialog
          open={addEdifierOpen || (editEdifierOpen && Object.keys(edifierToEditData)?.length > 0)}
          isEdit={editEdifierOpen}
          handleClose={handleAddCoachClose}
          toast={toast}
          userRole={userRole}
          edifierToEditData={edifierToEditData}
          userToken={userToken}
          setEdifierToEditData={setEdifierToEditData}
          queryClient={queryClient}
        />

        {/* Displaying users tags in a modal */}
        <UserDetailedInformationDialog
          open={displayEdifierDetails}
          handleClose={() => setDisplayEdifierDetails(false)}
          userData={currentEdifier}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            rowGap: '20px',
          }}
        >
          <Box>
            {userRole === 'teacher' && (
              <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
                Suggested Edifiers
              </Typography>
            )}

            {userRole === 'admin' && (
              <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
                Edifiers
              </Typography>
            )}

            {userRole === 'site-leader' && (
              <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
                District users{' '}
              </Typography>
            )}

            {/* <Typography variant="subtitles" sx={{ display: 'flex' }}>
              <Box component="img" src="/assets/calendarIcon.svg" sx={{ mr: '7.32px' }} />
              Member since July 11, 2021
            </Typography> */}
          </Box>
          {/* <CheckinCheckout /> */}

          {userRole === 'admin' && (
            <Button variant="contained" color="main" sx={{ ...addBtnStyles }} onClick={() => setAddEdifierOpen(true)}>
              Add new Edifiers
            </Button>
          )}
        </Box>
        <Box
          sx={{
            ...listGridViewContainerStyles,
            justifyContent: userRole === 'teacher' ? 'flex-end' : 'space-between',
          }}
        >
          {userRole !== 'teacher' && (
            <Box sx={{ ...filterContainerStyles }}>
              <TextField
                placeholder="Search Edifiers by Name"
                className="filters"
                sx={{ minWidth: '233px', maxWidth: { xs: '100%', md: '233px' } }}
                onChange={(e) => setSearchedValue(e.target.value.toLowerCase())}
              />

              <Button
                variant="contained"
                color="main"
                sx={{ height: 'fit-content', my: 'auto', width: 'max-content' }}
                onClick={() => searchFromRecord()}
              >
                Search
              </Button>
            </Box>
          )}

          <Box sx={{ display: 'flex', columnGap: '10px' }}>
            <Box
              component="img"
              src={isListView ? '/assets/listActive.svg' : '/assets/listDisable.svg'}
              sx={{ ...listGridViewIconStyles }}
              onClick={() => setIsListView(true)}
            />
            <Box
              component="img"
              src={isListView ? '/assets/gridDisable.svg' : '/assets/gridActive.svg'}
              sx={{ ...listGridViewIconStyles }}
              onClick={() => setIsListView(false)}
            />
          </Box>
        </Box>
        {userRole !== 'admin' && userRole != 'site-leader' && coachesRecord?.length !== 0 && (
          <>
            <Typography variant="secondaryTitles" sx={{ mt: '7.95px', lineHeight: '50px' }}>
              Highest Rated Match
            </Typography>
            <Typography sx={{ fontWeight: 500 }}>
              Our large community of Edifiers has been narrowed down to these three stellar humans. We have selected
              them based on several factors that we know will make them a wonderful mentoring fit for you.
              <br /> Now it's your turn to tell us who you would like to work with. Please review the profiles of each
              suggested Edifier below to get to know them. After you have reviewed their profiles, please tell us who
              you think your best match is. Rank each Edifier and save your rankings. Then we will do our best to pair
              you with your highest ranked available Edifier!{' '}
            </Typography>
          </>
        )}
        {isLoading && (
          <Box sx={{ textAlign: 'center', mt: '30px' }}>
            <CircularProgress />
          </Box>
        )}

        {coachesRecord?.length === 0 && !isLoading && !isListView && (
          <Box
            sx={{
              ...cardBoxStyles,
              minWidth: '100%',
              mt: '30px',
              borderRadius: 0,
              boxShadow: 'none',
              height: '88px',
              p: '0',
              display: 'grid',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="mainTitles"
              sx={{ lineHeight: 'auto', color: 'text.titles', display: 'block', textAlign: 'center' }}
            >
              No Matching Edifier Found
            </Typography>
          </Box>
        )}

        {!isListView && (
          <Grid container spacing={'28.47px'} sx={{ mt: '31px' }}>
            {coachesRecord &&
              sortByLname(coachesRecord)?.map(
                (list, index) =>
                  list && (
                    <Card
                      key={index}
                      data={list}
                      index={index}
                      setOpen={setOpen}
                      setItemToDeleteId={setItemToDeleteId}
                      setEdifierToEditData={setEdifierToEditData}
                      setEditEdifierOpen={setEditEdifierOpen}
                      userRole={userDetails?.role.toLowerCase()}
                      setDisplayUserDetails={setDisplayEdifierDetails}
                      searchedValue={searchedValue}
                      setCurrentUser={setCurrentEdifier}
                      setReviewsGiven={setReviewsGiven}
                      reviewsGiven={reviewsGiven}
                      setEdifierRankings={setEdifierRankings}
                      edifierRankings={edifierRankings}
                      setRankedEdifierPayload={setRankedEdifierPayload}
                      rankedEdifierPayload={rankedEdifierPayload}
                      isSendingPairRequest={isSendingPairRequest}
                      notPaired={JSON.stringify(pairedUsers[6]?.data?.data)?.includes(list?._id) === false}
                      rank
                    />
                  )
              )}
          </Grid>
        )}

        {isListView && (
          <TableView
            tableData={coachesRecord}
            setItemToDeleteId={setItemToDeleteId}
            setOpen={setOpen}
            setEditData={setEdifierToEditData}
            setEditOpen={setEditEdifierOpen}
            userRole={userDetails?.role.toLowerCase()}
            searchedValue={searchedValue}
          />
        )}
      </Container>
    </Box>
  );
};

export default FindAnEdifier;
