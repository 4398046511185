// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = " 'Montserrat', sans-serif"; // Google Font
const FONT_SECONDARY = 'palatino'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightRegularMedium: 500,

  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 500,
    lineHeight: '20.83px',
    fontSize: '24px',
    display: 'block',
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    // color:'#a94b9c',
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '18px',
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitles: {
    fontWeight: 500,
    lineHeight: 'norma',
    fontSize: pxToRem(13),
    color: 'text.light',
  },

  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: '16px',
  },
  subtitle2: {
    fontWeight: 500,
    lineHeight: '20px',
    fontSize: '14px',
    color: '#282939',
    letterSpacing: '0.2px',
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  form: {
    color: 'rgba(0, 0, 0, 0.60)',
    fontFamily: "'Roboto', sans-serif",
    fontSize: '14px',
    lineHeight: '12px' /* 85.714% */,
    letterSpacing: '0.15px',
    fontWeight: 'bold',
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },

  mainTitles: {
    fontWeight: 700,
    fontFamily: FONT_SECONDARY,
    fontSize: '32px',

    '@media (max-width: 780px)': {
      fontSize: '25px',
    },

    display: 'block',
  },

  secondaryTitles: {
    fontWeight: 600,
    fontFamily: FONT_PRIMARY,
    fontSize: '20px',
    lineHeight: '70px',

    display: 'block',
    '@media (max-width: 780px)': {
      fontSize: '16px',
    },
  },

  validation: {
    color: '#FF4842',
    fontWeight: 400,
    fontSize: '12px',
  },

  error: {
    fontWeight: 600,
    fontFamily: FONT_PRIMARY,
    fontSize: '20px',
    lineHeight: '70px',

    display: 'block',
    color: 'red',
  },

  email: {
    fontWeight: 600,
    fontFamily: FONT_PRIMARY,
    fontSize: '10px',
    color: '#569CD3',
    display: 'grid',
    alignItems: 'center',
  },

  specs: {
    fontWeight: 600,
    fontFamily: FONT_PRIMARY,
    fontSize: '12px',
    color: '#000',
    display: 'block',
    lineHeight: 'normal',
  },
  smallText: {
    color: '#769C98',
    fontFamily: FONT_PRIMARY,
    fontSize: '10px',
    fontWeight: 500,
  },
  normal: {
    color: '#282B39',
    fontFamily: FONT_PRIMARY,
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '65px',
  },
  large: {
    fontSize: '40px',
    fontWeight: 500,
    color: '#282A39',
  },
  hightLightedText: {
    display: 'block',
    padding: '10px',
    color: '#FFF',
    fontWeight: 700,
    lineHeight: '31px',
    fontSize: '24px',
    borderRadius: '10px',
    border: '1px solid #98D1FD',
    height: '62px',
    background: '#569CD3',
    '@media (max-width: 780px)': {
      fontSize: '16px',
    },
  },
};

export default typography;
