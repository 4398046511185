
// ----------------------------------------------------------------------

export default function FormControl(theme) {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            color: theme.palette.text.primary,
          },
        },
      },
    },
  };
}
