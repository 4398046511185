import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';

import { useQueryClient } from 'react-query';
import { SelectRole } from '../components/filters';

import { Card } from '../sections/@dashboard/find-edifier';
import { cardBoxStyles, mainTitlesStyles } from '../jsxStyles';
import { getUserDetails, sortByLname } from '../utils';
import { useMutationManageCredentials, useQueryGetUsersList } from '../hooks/react-query/useData';

import { ManageCredentialsDialog, UserDetailedInformationDialog } from '../components/dialog';

const ManageCredentials = () => {
  const userDetails = getUserDetails();

  const userToken = userDetails.token;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [manageCredentialsOpen, setManageCredentialsOpen] = useState(false);

  const [displayUserDetails, setDisplayUserDetails] = useState(false);

  const [currentUser, setCurrentUser] = useState({});

  const [userToEdit, setUserToEdit] = useState({});
  const [editedUserData, setEditedUserData] = useState({});

  const [currentSelectedFilter, setCurrentSelectedFilter] = useState('All');

  const handleClose = () => {
    setManageCredentialsOpen(false);
  };

  const { data: coachesList, isFetching: isLoading } = useQueryGetUsersList(
    { token: userDetails?.token, role: userDetails?.token.toLowerCase() },
    navigate,
    toast
  );

  const { isLoading: isManageCredentialsLoading, mutate: manageCredentials } = useMutationManageCredentials(
    editedUserData,
    toast,
    handleClose,
    userToken,
    setUserToEdit,
    navigate,
    queryClient
  );

  useEffect(() => {
    if (Object.keys(editedUserData).length > 0) {
      manageCredentials();
    }
  }, [editedUserData]);

  const userCard = (index, list) => (
    <Card
      key={index}
      data={list}
      index={index}
      setUserToEdit={setUserToEdit}
      setDisplayUserDetails={setDisplayUserDetails}
      manageCredentials
      setManageCredentials={setManageCredentialsOpen}
      setCurrentUser={setCurrentUser}
    />
  );

  const filterBaseResults = (index, list) => {
    if (currentSelectedFilter === 'All') {
      return userCard(index, list);
    }

    if (currentSelectedFilter === 'Edifiers' && list?.role === 'COACH') {
      return userCard(index, list);
    }

    if (currentSelectedFilter === 'Teachers' && list?.role === 'TEACHER') {
      return userCard(index, list);
    }
    return null;
  };

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Manage Credentials</title>
      </Helmet>
      <ManageCredentialsDialog
        userToEdit={userToEdit}
        open={Object.keys(userToEdit).length > 0 && manageCredentialsOpen}
        handleClose={handleClose}
        manageCredentials={manageCredentials}
        isLoading={isManageCredentialsLoading}
        setEditedUserData={setEditedUserData}
        toast={toast}
      />

      <UserDetailedInformationDialog
        open={displayUserDetails}
        handleClose={() => setDisplayUserDetails(false)}
        userData={currentUser}
      />

      <Container maxWidth="xl" sx={{ p: '0 !important' }}>
        {/* <Toaster position="top-right" reverseOrder={false} /> */}

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
            Manage Credentials
          </Typography>

          <SelectRole
            currentSelectedFilter={currentSelectedFilter}
            setCurrentSelectedFilter={setCurrentSelectedFilter}
          />
        </Box>

        {!isLoading && (!coachesList?.data?.data || coachesList?.data?.data?.length === 0) && (
          <Box
            sx={{
              ...cardBoxStyles,
              minWidth: '100%',
              mt: '30px',
              borderRadius: 0,
              boxShadow: 'none',
              height: '88px',
              p: '0',
              display: 'grid',
              alignItems: 'center',
            }}
          >
            <Typography variant="mainTitles" sx={{ ...mainTitlesStyles, textAlign: 'center', py: '20px' }}>
              No Record Found
            </Typography>
          </Box>
        )}

        {isLoading && (
          <Box sx={{ textAlign: 'center', mt: '30px' }}>
            <CircularProgress />
          </Box>
        )}
        <Grid container spacing={'28.47px'} sx={{ mt: '20px' }}>
          {coachesList?.data &&
            sortByLname(coachesList?.data?.data)?.map((list, index) => filterBaseResults(index, list))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ManageCredentials;
