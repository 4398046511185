/* eslint-disable */
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { logOutUser, generateToastError } from '../../../utils';
import { queryKeys } from '../queryKeys';

// Checkin and Checkout Logic stars from here

const httpStatuses = { unauthorized: 401, error: 500 };

export const useMutationUpdateGoals = (payload, toast, userToken, queryClient, setGoalsModalData, role) => {
  const queryKey = [queryKeys.forgotPassword, payload];
  const queryFn = () =>
    axios.put(
      `${process.env.REACT_APP_SERVER_URL}${role === 'teacher' ? '/api/teacher/goals' : '/api/edifier/goals'}`,
      payload,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );

  return useMutation(queryKey, queryFn, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });
      setGoalsModalData({ open: false, goals: [], edifierName: '' });
      queryClient.invalidateQueries({ queryKey: [queryKeys.totalUsers] });
    },
    onError: (error) => {
      if (error?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, error);
    },
  });
};

// get all notifications for a user
