/*eslint-disable*/

import React, { useState } from 'react';
import { Grid, Container, Typography, Box, CircularProgress } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Card } from '../sections/@dashboard/find-edifier';
import { UserDetailedInformationDialog } from '../components/dialog';
// import { CheckinCheckout } from '../components/checkin';
import { getUserDetails } from '../utils';
import {
  cardBoxStyles,
} from '../jsxStyles';

const FindAnEdifier = () => {
  const userDetails = getUserDetails();

  const userRole = userDetails.role.toLowerCase();
  const userToken = userDetails.token;

  const navigate = useNavigate();
  const pairedUsers = useOutletContext();

  const queryClient = useQueryClient();

  const [isListView, setIsListView] = useState(false);

  const [displayEdifierDetails, setDisplayEdifierDetails] = useState(false);

  const [itemToDeleteId, setItemToDeleteId] = useState('');

  const [currentEdifier, setCurrentEdifier] = useState({});
  

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Edifiers</title>
      </Helmet>

      {/* Displaying users tags in a modal */}
      <UserDetailedInformationDialog
        open={displayEdifierDetails}
        handleClose={() => setDisplayEdifierDetails(false)}
        userData={currentEdifier}
      />
      <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
        Paired Edifiers
      </Typography>

      {pairedUsers[5] && (
        <Box sx={{ textAlign: 'center', mt: '30px' }}>
          <CircularProgress />
        </Box>
      )}

      <Grid container spacing={'28.47px'} sx={{ mt: '31px' }}>
        {pairedUsers[6]?.data?.data &&
          pairedUsers[6]?.data?.data?.map(
            (list, index) => list && <Card key={index} data={list} index={index} userRole={userRole} pairedUsers />
          )}
      </Grid>

      {!pairedUsers[5] && (pairedUsers[6]?.data?.data.length === 0 || !pairedUsers[6]?.data?.data) && (
        <Box
          sx={{
            ...cardBoxStyles,
            minWidth: '100%',
            mt: '30px',
            borderRadius: 0,
            boxShadow: 'none',
            height: '88px',
            p: '0',
            display: 'grid',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="mainTitles"
            sx={{ lineHeight: 'auto', color: 'text.titles', display: 'block', textAlign: 'center' }}
          >
            No Pairs Found
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FindAnEdifier;
