/* eslint-disable */

import React, { useState } from 'react';
import { Typography, Box, Button, CircularProgress } from '@mui/material';
// import { edifierProfileSocialMediaIcons } from '../../../_mock';
import toast from 'react-hot-toast';
import { FileUpload } from '../../../components/file-upload';

import { imageBoxCoachProfileStyles } from '../../../jsxStyles';

const CoachInfo = ({
  userDetails,
  setOpenRestPassword,
  fromRateCoach,
  isLoading,
  setEditData,
  setSelectedVideo,
  setPreviewVideo,
  selectedVideo,
  previewVideo,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    // Handle image load error here, if needed
    setImageLoaded(true); // Set to true to hide loader even if there's an error
  };

  const saveVideo = () => {
    if (selectedVideo.size > 50 * 1024 * 1024) {
      toast.error('Please make sure Video size is lesser than 50MB');
      return;
    }
    const formData = new FormData();
    formData.append('video', selectedVideo);

    setEditData({ formData });
  };

  return (
    <Box
      sx={{
        boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
        borderRadius: '20px',
        background: 'white',
        width: '100%',
        p: { xs: '20px', sm: '22.59px 20px 63px 33px', md: '47.59px 30px 47.59px 69.2px' },
      }}
    >
      {userDetails?.role !== 'ADMIN' && !fromRateCoach && (
        <Box
          sx={{
            display: 'flex',
            textAlign: 'right',
            mb: { xs: '30px', md: '0' },
            width: 'fit-content',
            justifyContent: 'flex-end',
            width: '100%',
            columnGap: '10px',
          }}
        >
          {selectedVideo && !isLoading && (
            <Button variant="contained" color="main" onClick={() => saveVideo()}>
              Save
            </Button>
          )}

          {selectedVideo && !isLoading && (
            <Button
              variant="contained"
              color="main"
              onClick={() => {
                setSelectedVideo('');
                setPreviewVideo('');
              }}
            >
              Discard
            </Button>
          )}
          <FileUpload
            setPreviewFile={setPreviewVideo}
            setSelectedFile={setSelectedVideo}
            previewFile={previewVideo}
            isLoading={isLoading}
            selectedFile={selectedVideo}
            isVideo
          />
          <Button variant="contained" color="main" onClick={() => setOpenRestPassword(true)}>
            Edit Profile
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          columnGap: '67.87px',
          flexDirection: { xs: 'column', md: 'row' },
          rowGap: '25px',
        }}
      >
        <Box sx={{ my: 'auto' }}>
          <Box
            component="img"
            src={userDetails?.image ? `${userDetails?.image}?${Date.now()}` : '/assets/ProfileAvatar.png'}
            onLoad={handleImageLoad}
            onError={handleImageError}
            sx={{
              ...imageBoxCoachProfileStyles,
              display: !imageLoaded && 'none',
            }}
          />

          {!imageLoaded && (
            <Box
              sx={{
                ...imageBoxCoachProfileStyles,

                textAlign: 'center',
              }}
            >
              <Box sx={{ minHeight: '250px', display: 'grid', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            </Box>
          )}
          {/* <Box sx={{ display: 'flex', columnGap: '9.76px', justifyContent: 'center', mt: '24.3px' }}>
            {edifierProfileSocialMediaIcons?.map((item, key) => (
              <Box
                key={key}
                component="img"
                src={item.img}
                sx={{ cursor: 'pointer' }}
                onClick={() => window.open(item.link, '_blank')}
              />
            ))}
          </Box> */}
        </Box>
        <Box
          sx={{
            display: userDetails?.role === 'ADMIN' && 'flex',
            flexDirection: userDetails?.role === 'ADMIN' && 'column',
            justifyContent: userDetails?.role === 'ADMIN' && 'center',
            pointerEvents: 'none',
          }}
        >
          <Typography variant="large" sx={{ textTransform: 'capitalize' }}>
            {!userDetails?.fname ? userDetails?.email?.split('@')[0] : userDetails?.fname + ' ' + userDetails?.lname}{' '}
            <span style={{ fontWeight: 400, fontSize: '27px' }}>
              ({fromRateCoach ? 'Edifier' : userDetails?.role === 'COACH' ? 'Edifier' : userDetails?.role})
            </span>
          </Typography>
          <Box sx={{ display: 'flex', columnGap: '10.46px' }}>
            <Box component="img" src="/assets/emailIcon.svg" sx={{ width: '25px', height: '25px', my: 'auto' }} />
            <Typography
              variant="email"
              sx={{
                fontSize: { xs: '13px', sm: '20px' },
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',

                whiteSpace: 'normal', // Allow text to wrap
                wordBreak: 'break-all', // Break long words
              }}
            >
              {' '}
              {userDetails?.email}
            </Typography>
          </Box>

          {userDetails?.role !== 'ADMIN' && (
            <>
              <Typography variant="h5" sx={{ lineHeight: 'normal', mt: '24.49px', mb: '17.12px', fontWeight: 600 }}>
                Expertise
              </Typography>

              <Box sx={{ display: 'flex', columnGap: '6.42px', mt: '8.28px', flexWrap: 'wrap', rowGap: '10px' }}>
                {userDetails?.info?.expertiesAndResources?.map((item, key) => (
                  <Box
                    key={key}
                    sx={{
                      background: '#EDF7FF',
                      border: '1px solid #98D1FD',
                      p: '8.37px 21.46px',
                      borderRadius: '3px',
                    }}
                  >
                    <Typography variant="specs" sx={{ color: '#8D71AC', fontWeight: 500, fontSize: '16px' }}>
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </>
          )}
          {userDetails?.role !== 'ADMIN' && (
            <Box sx={{ display: 'flex', columnGap: '16.43px', mt: '39.11px', mb: '32.13px' }}>
              <Box component="img" src="/assets/locationIcon.svg" />

              <Typography variant="h5" sx={{ fontWeight: 500, m: '0', textTransform: 'capitalize' }}>
                {userDetails?.info?.city ? userDetails?.info?.city : 'Baltimore'},{' '}
                {userDetails?.info?.state ? userDetails?.info?.state : 'Maryland'}, United States
              </Typography>
            </Box>
          )}
          {/* {fromRateCoach && ( */}

          {/* )} */}
        </Box>
      </Box>

      {selectedVideo && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <video controls width="70%">
            <source src={URL.createObjectURL(selectedVideo)} type={URL.createObjectURL(selectedVideo)?.type} />
            Your browser does not support the video tag.
          </video>
        </Box>
      )}
    </Box>
  );
};

export default CoachInfo;
