/* eslint-disable */

import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const menuOptions = ['Edit', 'Delete'];

export default function MenuListComposition({
  setMenuOpen,
  setItemToDeleteId,
  id,
  data,
  setEdifierToEditData,
  setEditEdifierOpen,
  setManageCredentials,
  manageCredentials,
  setUserToEdit
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={{ minWidth: 'fit-content', color: 'black' }}
        >
          <MoreVertIcon />
        </Button>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="right-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper sx={{ background: '#569CD3' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{ width:!manageCredentials? '99px':'130px' }}
                  >
                    {!manageCredentials ? (
                      menuOptions?.map((item, index) => (
                        <MenuItem
                          onClick={(e) => {
                            index === 0 && setEditEdifierOpen(true);

                            index === 0 && setEdifierToEditData(data);

                            handleClose(e);

                            index === 1 && setMenuOpen(true);
                            index === 1 && setItemToDeleteId(id);
                          }}
                          variant="form"
                          sx={{
                            color: 'white',
                            letterSpacing: 'normal',
                            fontSize: '14px',
                            '&:hover': { background: '#8E71AD' },
                          }}
                          key={index}
                        >
                          {item}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem
                        onClick={(e) => {
                          setUserToEdit(data)
                          setManageCredentials(true)
                          handleClose(e);
                        }}
                        variant="form"
                        sx={{
                          color: 'white',
                          letterSpacing: 'normal',
                          fontSize: '14px',
                          '&:hover': { background: '#8E71AD' },
                        }}
                      >
                        Change Role
                      </MenuItem>
                    )}
                    {/* 
                    <MenuItem
                      onClick={handleClose}
                      variant="form"
                      sx={{ color: 'white', letterSpacing: 'normal', fontSize: '14px' }}
                    >
                      Delete
                    </MenuItem> */}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
