import { useState, useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Box, Typography } from '@mui/material';
import { meetingIsNotInPast } from '../../../utils';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const MyCalendar = ({ eventsData }) => {
  const [events, setEvents] = useState([]);

  const currentTime = new Date();
  useEffect(() => {
    // Your event data

    // Map the eventData into the format required by react-big-calendar
    const formattedEvents = eventsData?.map((event) => ({
      id: event._id,
      title: event.name,
      start: new Date(event.scheduleTime),
      scheduleTime: event.scheduleTime,
      end: new Date(event.scheduleTime),
      allDay: false, // Assuming events are not all-day events
    }));

    const timeWiseFilteredItems = formattedEvents?.filter((item) => meetingIsNotInPast(item, currentTime));

    setEvents(timeWiseFilteredItems);
  }, [eventsData]);

  return (
    <Box
      sx={{
        p: { sm: '30px', lg: '10px', xl: '30px' },
        maxWidth: { xs: '400px', lg: 'initial' },
        background: 'white',
        boxShadow: '0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%)',
        borderRadius: '10px',
        minHeight: '400px',
        maxHeight: 'fit-content',
      }}
      id="events-calendar"
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: { xs: '20px', sm: 'initial' } }}>
        <Typography sx={{ color: 'text.titles' }} variant="h5">
          Upcoming Meetings!
        </Typography>

        <Box component="img" src="/assets/bigCalendarIcon.svg" />
      </Box>
      <Calendar localizer={localizer} events={events} startAccessor="start" endAccessor="end" style={{ height: 500 }} />
    </Box>
  );
};

export default MyCalendar;
