import React, { useState,useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { Reviews } from '../reviews';

export default function RateCoachDialog({ open, setOpen }) {
  const scroll='paper';
  const [reviewsGiven, setReviewsGiven] = useState(0);


  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
          '& .MuiPaper-root': { minWidth: '731.599px', height: '800px' },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant="mainTitles" sx={{ lineHeight: '70px', color: '#A94B9C' }}>
            Rate the Coach{' '}
          </Typography>

          <Box sx={{ display: 'flex', columnGap: '16.98px' }}>
            <Box component="img" src="/assets/reviewSingleStar.svg" sx={{ height: '44.534px', width: '44.534px' }} />

            <Box>
              <Typography variant="normal" sx={{ lineHeight: '20px', mb: '4.93px', display: 'block' }}>
                4.4 rating
              </Typography>
              <Typography variant="smallText" sx={{ fontSize: '14px' }}>
                Overall rating based on 61 reviews
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers={scroll === 'paper'}
          sx={{
            '&.MuiDialogContent-root': { pt: '36.12px !important' },
            borderTop: 'none ',
          }}
        >
          <Box sx={{ width: '100%', height: '217.083px', borderRadius: '20px', border: '1px solid #D6E0E8' }}>
            <Typography variant="subtitle1" sx={{ fontSize: '30px', textAlign: 'center', pt: '40.94px' }}>
              How was you experience?
            </Typography>

            <Reviews setReviewsGiven={setReviewsGiven} reviewsGiven={reviewsGiven} />
          </Box>
          <Typography variant="secondaryTitles" sx={{ lineHeight: 'normal', mt: '31.53px' }}>
            Describe your experience
          </Typography>

          <TextField
            variant="outlined"
            multiline
            fullWidth
            sx={{ mt: '20.04px' }}
            placeholder="How was your experience?"
            inputProps={{
              sx: {
                '&::placeholder': {
                  color: '#282A39',
                  fontSize: '20px',
                  fontWeight: 600,
                  opacity: 0.3, // otherwise firefox shows a lighter color
                },
              },
            }}
            rows={7}
          />
        </DialogContent>

        <DialogActions sx={{ p: '24px 34.6px 30.32px 0' }}>
          <Box>
            <Button variant="outlined" color="main" sx={{ mr: '20px' }}>
              Cancel
            </Button>

            <Button variant="contained" color="main">
              Submit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
