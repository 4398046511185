import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel, Checkbox, TextField, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';

import { TagsInput } from '../customInput';
import { survey1Items, survey1SecondItems } from '../../_mock';
import { stepperBoxStyles } from '../../jsxStyles';

const Surveys = ({ setPayload, apiPayload, isLoading, submitSurvey, participantId, firstLog }) => {
  const [checkboxGroupSelection, setCheckboxGroupSelection] = useState({
    touchOn: {
      "M's": { Modeling: false, Mentoring: false, Mindset: false, Mastering: false },
      "C's": { CollaborationWithConnection: false, CulturallySustainingConversation: false, CareerNavigation: false },
    },
    sharedWeekSummary: false,
  });
  const [goals, setGoals] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    // getValues,
    setValue,
    // watch,
  } = useForm({});

  useEffect(() => {
    if (Object.keys(apiPayload).length > 0) {
      submitSurvey();
    }
  }, [apiPayload]);

  const onSubmit = (submittedData) => {
    let myObj = checkboxGroupSelection.touchOn["M's"];
    const MS = Object.keys(myObj).filter((key) => myObj[key] && myObj[key]);

    myObj = checkboxGroupSelection.touchOn["C's"];

    const CS = Object.keys(myObj).filter((key) => myObj[key] && myObj[key]);

    let updatedPayloadObj = { survey: false, Ms: MS, Cs: CS };

    updatedPayloadObj = { ...updatedPayloadObj, ...submittedData };

    updatedPayloadObj.survey = checkboxGroupSelection.sharedWeekSummary;

    setPayload({
      participantId,
      surveyData: { ...updatedPayloadObj, title: firstLog ? '1st Session Log' : 'All Sessions Log' },
    });
  };

  return (
    <Box sx={{ ...stepperBoxStyles, minWidth: '100%', overflow: 'scroll' }}>
      <Box
        sx={{ textAlign: 'right', cursor: 'pointer' }}
        onClick={() => {
          document.getElementById('zoom-modal').style.display = 'none';
        }}
      >
        <CloseIcon />
      </Box>
      <Typography
        variant="h3"
        sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif", mb: '50px', textAlign: 'center' }}
      >
        {firstLog ? '1st Session Log' : 'All Sessions Log'}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={'20px'} sx={{ mb: '50px' }}>
          <Grid item xs={12} md={4}>
            <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
              Ask your mentee to describe their goals(s):
            </Typography>
            <TagsInput
              placeholder="Separate Goals using Enter"
              setGoals={setGoals}
              goals={goals}
              register={register}
              errors={errors && errors}
              setValue={setValue}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <Box sx={{ display: 'grid', alignItems: 'flex-end', height: '100%' }}>
              <TextField
                variant="outlined"
                type="date"
                label="Date"
                fullWidth
                focused
                name="date"
                {...register('date', { required: `Date is required` })}
                error={errors && !!errors?.date}
                helperText={errors?.date?.message}
                sx={{ '& .MuiFormHelperText-root': { position: 'absolute !important', bottom: '-20px !important' } }}
              />
            </Box>
          </Grid> */}
        </Grid>

        <Typography
          variant="h3"
          sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif", mb: '30px', lineHeight: '40px' }}
        >
          During the session did you touch on - check all that apply
        </Typography>

        {['', "M's"].map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: '25px', md: 'inherit' },
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{
                width: '104px',
                fontSize: '18px',
                fontWeight: 300,
                display: 'grid',
                alignItems: 'center',
              }}
              variant="form"
            >
              {item}
            </Typography>

            {index === 0 &&
              survey1Items.map((subItem, subIndex) => (
                <Typography
                  key={subIndex}
                  sx={{
                    width: '156px',
                    mb: '24px',
                    fontWeight: 500,
                    color: '#000',
                    minWidth: 'max-content',
                    display: { xs: 'none', md: 'inherit' },
                    justifyContent: 'center',
                  }}
                  variant="subtitle1"
                >
                  {subItem}
                </Typography>
              ))}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  Modeling
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.touchOn[item]?.Modeling || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-Modeling`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              Modeling: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Mentoring
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.touchOn[item]?.Mentoring || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-Mentoring`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              Mentoring: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Mindset
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.touchOn[item]?.Mindset || false}
                      name={`${item}-Mindset`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              Mindset: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Mastering
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.touchOn[item]?.Mastering || false}
                      name={`${item}-Mastering`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              Mastering: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        ))}

        {['', "C's"].map((item, index) => (
          <Box
            key={index}
            sx={{
              mb: { xs: '25px', md: 'inherit' },
              display: { xs: 'block', md: 'flex' },
              justifyContent: 'space-between',
              lineHeight: '120px',
            }}
          >
            <Typography
              sx={{
                width: '104px',
                fontSize: '18px',
                fontWeight: 300,
                display: 'grid',
                alignItems: 'center',
              }}
              variant="form"
            >
              {item}
            </Typography>

            {index === 0 &&
              survey1SecondItems.map((subItem, subIndex) => (
                <Typography
                  key={subIndex}
                  sx={{
                    width: '156px',
                    mb: '24px',
                    fontWeight: 500,
                    color: '#000',
                    minWidth: 'max-content',
                    display: { xs: 'none', md: 'inherit' },
                    justifyContent: 'center',
                    mt: '50px',
                  }}
                  variant="subtitle1"
                >
                  {subItem}
                </Typography>
              ))}

            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    width: { xs: '250px', md: '170px' },
                    alignItems: 'center',
                  }}
                >
                  Collaboration with connection to resource/person(s)
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.touchOn[item]?.CollaborationWithConnection || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-CollaborationWithConnection`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              CollaborationWithConnection: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                  Culturally sustaining conversation
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      checked={checkboxGroupSelection?.touchOn[item]?.CulturallySustainingConversation || false}
                      sx={{
                        color: '#ADADAD',
                      }}
                      name={`${item}-CulturallySustainingConversation`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              CulturallySustainingConversation: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
            {index !== 0 && (
              <Box sx={{ display: 'flex', columnGap: '10px' }}>
                <Typography
                  sx={{
                    display: { xs: 'grid', md: 'none' },
                    alignItems: 'center',
                    width: { xs: '250px', md: '170px' },
                  }}
                >
                 Career Navigation
                </Typography>
                <FormControlLabel
                  sx={{ width: '156px', justifyContent: 'center', display: { xs: 'block', md: 'inherit' } }}
                  control={
                    <Checkbox
                      sx={{
                        color: '#ADADAD',
                      }}
                      checked={checkboxGroupSelection?.touchOn[item]?.CareerNavigation || false}
                      name={`${item}-CareerNavigation`}
                      onChange={(e) => {
                        setCheckboxGroupSelection({
                          ...checkboxGroupSelection,
                          touchOn: {
                            ...checkboxGroupSelection.touchOn,
                            [item]: {
                              ...checkboxGroupSelection.touchOn[item],
                              CareerNavigation: e.target.checked,
                            },
                          },
                        });
                      }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
        ))}
        <Typography
          variant="h3"
          sx={{ fontWeight: 400, fontFamily: "'Noto Sans JP', sans-serif", my: '30px', lineHeight: '30px' }}
        >
          Please note: No other information will be shared with your school leadership. We at Edifying Teachers hold
          confidentiality in high regard.{' '}
        </Typography>

        <Box>
          <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
            Are there themes that emerged from our session that you would like to share with your school leaders?
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            fullWidth
            name="themes"
            {...register('themes', { required: `This is required` })}
            error={errors && !!errors?.themes}
            helperText={errors?.themes?.message}
          />
        </Box>
        <Box>
          <Typography sx={{ mt: '26px', mb: '5px', display: 'block', lineHeight: '20px' }} variant="form">
            Verify that you have shared this week's survey during the end of the session by checking the box.
          </Typography>
          <Checkbox
            checked={checkboxGroupSelection.sharedWeekSummary || false}
            sx={{
              color: '#ADADAD',
            }}
            onChange={(e) =>
              setCheckboxGroupSelection({ ...checkboxGroupSelection, sharedWeekSummary: e.target.checked })
            }
          />
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            color="accept"
            sx={{ color: 'white', mr: '20px', mt: '30px' }}
          >
            Submit
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default Surveys;
