import React, { useState } from 'react';
import {Container, Typography, Box } from '@mui/material';
import { Info, CoachDetails } from '../sections/@dashboard/coach-profile';
import { ChatFeature } from '../components/chat';
import { RateCoach,ResetPasswordDialog } from '../components/dialog';
import { mockUserData } from '../_mock';

const FindAnEdifier = () => {
  const [open, setOpen] = useState(false);
  const [openRestPassword, setOpenRestPassword] = useState(false);

  const [rateCoachOpen, setRateCoachOpen] = useState(false);
  const userDetails = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : mockUserData;

const handleClose = () => { setOpenRestPassword(false) }

  return (
    <Box>
      <ResetPasswordDialog open={openRestPassword} handleClose={handleClose}/>
      <ChatFeature open={open} setOpen={setOpen} />

      <RateCoach open={rateCoachOpen} setOpen={setRateCoachOpen} />

      <Container maxWidth="xl" sx={{p:'0 !important'}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
              {/* {userDetails?.role==='ADMIN' ?  'My Edifier(s) Profile':''} */}
              My Profile
            </Typography>
          </Box>
          {/* <Button variant="contained" color="main" sx={{ height: 'fit-content', my: 'auto' }}>
            Check Out
          </Button> */}
        </Box>
        <Info
          setOpen={setOpen}
          setRateCoachOpen={setRateCoachOpen}
          userDetails={userDetails}
          setOpenRestPassword={setOpenRestPassword}
          openRestPassword={openRestPassword}
          fromRateCoach
        />

        {userDetails?.role !== 'ADMIN' && <CoachDetails bio={userDetails?.info?.interestBio!==''?userDetails?.info?.interestBio:userDetails?.info?.anythingElse}/>}
      </Container>
    </Box>
  );
};

export default FindAnEdifier;
