/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Typography, Button, Box } from '@mui/material';
// hooks

import { Step1, Step2, Step3, Step4 } from '../components/onboarding-steps/index';

import { Select } from '../components/select';
import { useQueryGetSchoolsList } from '../hooks/react-query/schools';

import {
  onBoardingProcessSteps,
  teachersOnboardingTextFields,
  teachingExperienceItems,
  onboardingEarnedDegreesCheckboxData,
  programTypeMock,
  ethnoracialData,
  institutionDataMocks,
  preferredMethodOfCommunication,
  teachingInfoInEdifiersCase,
  teachingInfoInTeachersCase,
  contentAreaEdifiersCase,
  contentAreaTeachersCase,
  onboardingStepsContent,
  seperateBoolValues,
} from '../_mock';
import { useMutationOnboardRole, useMutationModifyCoachRecord } from '../hooks/react-query/useData';
import { stepRoundBoxStyles, stepperBoxStyles } from '../jsxStyles';
// components

const fieldNotToInclude = ['lname', 'confirmPassword'];

const fieldsNotToCheck = ['_id', 'role', 'email'];

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  background: '#EDF6F3',
}));

const StyledContent = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  marginTop: '0',
  minHeight: '100vh',
  display: 'flex',

  // justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  paddingTop: '20px',
}));

let otherDefaultValues = {};

// ----------------------------------------------------------------------

export default function LoginPage({
  handleClose,
  userRole,
  edifierToEditData,
  setEdifierToEditData,
  userToken,
  isOnlyEdifier,
  queryClient,
  setSelectedForm,
}) {
  const { data: schoolsData } = useQueryGetSchoolsList();

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setError,
    getValues,
    setValue,
    watch,
  } = useForm({});

  const [formData, setFormData] = useState({});

  const [checkboxGroupSelection, setCheckboxGroupSelection] = useState({
    ethnoRacial: [],
    degrees: [],
    educatorProgramType: [],
    institution: [],
    teaching: isOnlyEdifier
      ? {
          ...teachingInfoInEdifiersCase,
        }
      : { ...teachingInfoInTeachersCase },
    contentAreaTeaching: isOnlyEdifier
      ? {
          ...contentAreaEdifiersCase,
        }
      : {
          ...contentAreaTeachersCase,
        },

    ifRetired: [],
    expertiesAndResources: [],

    typesOfResources: {
      'Blog Posts': { forPurchase: false, free: false },
      'Curricular Resources': { forPurchase: false, free: false },
      'Lesson Plans': { forPurchase: false, free: false },
      'Podcast Episodes': { forPurchase: false, free: false },
      Videos: { forPurchase: false, free: false },
    },
    termsOfInterest: [],

    regionalHuddleWork: [],
    preferredMethodOfCommunication: [],
  });

  const [formOtherDefaultValues, setOtherFormDefaultValues] = useState({});

  const { mutate, isLoading } = useMutationOnboardRole(formData, toast, handleClose, userRole, queryClient, navigate);
  const { mutate: modifyCoach, isLoading: isModifyingCoach } = useMutationModifyCoachRecord(
    formData,
    toast,
    handleClose,
    userToken,
    setEdifierToEditData,
    navigate,
    queryClient,
    userRole
  );

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      (async () => {
        if (edifierToEditData) {
          modifyCoach();
        } else {
          mutate();
        }
      })().catch((err) => {
        console.error(err);
      });
    }
  }, [formData]);

  // if the case is edit
  useEffect(() => {
    const boolValues = {};

    otherDefaultValues = {};
    if (edifierToEditData) {
      Object.keys(edifierToEditData.info).map((key) => {
        if (seperateBoolValues.includes(key)) {
          boolValues[key] = edifierToEditData.info[key];
        } else {
          otherDefaultValues = { ...otherDefaultValues, [key]: edifierToEditData.info[key] };
        }
        return null;
      });
      setCheckboxGroupSelection(boolValues);
      setOtherFormDefaultValues(otherDefaultValues);
    }
  }, [edifierToEditData]);
  // if the case is edit

  // Onboarding form submit data
  const onSubmit = (submittedData) => {
    let siteLeaderModifiedData = {};
    if (window.event.submitter.id.includes('_')) {
      const step = parseInt(window.event.submitter.id.split('_')[1], 10);
      setActiveStep(step);
      return;
    }

    const data = submittedData;

    delete data?.expertiesAndResources;
    delete data?.regionalHuddleWork;
    delete data?.degrees;
    delete data?.ethnoRacial;
    delete data?.institution;

    const basicData = {
      // name: data.name,
      // fname: data.fname,
      // lname : data.lname,
      fname: data.fname,
      lname: data.lname,
      email: data.email,
      password: data.password,
      phone: data.phone,
      gender: data.gender,
      info: '',
      role: isOnlyEdifier ? 'coach' : 'teacher',
    };

    let questions = {};
    Object.keys(data).forEach((key) => {
      if (key in basicData === false && fieldNotToInclude.includes(key) === false) {
        questions[key] = data[key];
      }
    });
    if (edifierToEditData) {
      questions = { ...edifierToEditData?.info, ...questions, ...checkboxGroupSelection };
    } else {
      questions = { ...questions, ...checkboxGroupSelection };
    }

    basicData.info = questions;
    // basicData.name=basicData.fname+" "+basicData.lname
    // delete basicData['fname'];
    // delete basicData['lname'];

    if (edifierToEditData) {
      basicData['id'] = edifierToEditData._id;
    }

    if (edifierToEditData) {
      delete basicData['email'];
      delete basicData['password'];
      delete basicData['role'];
      if (userRole === 'site-leader') {
        // siteLeaderModifiedData
        Object.keys(edifierToEditData)?.map((key) => {
          if (
            edifierToEditData[key] !== basicData[key] &&
            fieldsNotToCheck.includes(key) === false &&
            typeof edifierToEditData[key] !== 'object'
          ) {
            siteLeaderModifiedData = { ...siteLeaderModifiedData, [key]: basicData[key] };
          }

          if (key === 'info') {
            Object.keys(edifierToEditData[key])?.map((subKeys) => {
              if (basicData[key][subKeys] && edifierToEditData[key][subKeys] != basicData[key][subKeys]) {
                siteLeaderModifiedData = {
                  ...siteLeaderModifiedData,
                  info: { ...siteLeaderModifiedData['info'], [subKeys]: basicData[key][subKeys] },
                };
              }
            });
            if (siteLeaderModifiedData?.info) {
              siteLeaderModifiedData = {
                ...siteLeaderModifiedData,
                info: { ...basicData.info, ...siteLeaderModifiedData['info'] },
              };
            }
          }
        });
      }
    }

    setFormData(
      edifierToEditData && userRole === 'site-leader'
        ? { userId: edifierToEditData._id, changes: siteLeaderModifiedData }
        : basicData
    );
  };

  const setCheckboxValues = (item, attributeName) => {
    if (checkboxGroupSelection[attributeName].includes(item)) {
      const existingValues = checkboxGroupSelection[attributeName];

      const modifiedArray = existingValues.filter((arrItem) => arrItem !== item);

      setCheckboxGroupSelection({ ...checkboxGroupSelection, [attributeName]: modifiedArray });
      return;
    }
    setCheckboxGroupSelection({
      ...checkboxGroupSelection,
      [attributeName]: [...checkboxGroupSelection[attributeName], item],
    });
  };

  return (
    <StyledRoot>
      <Box sx={{ width: '100%', px: { xs: '0', sm: '30px', md: '50px', lg: '117px' } }}>
        <StyledContent>
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ display: 'flex', my: '31px', width: '100%', justifyContent: 'center' }}>
                {onBoardingProcessSteps.map((item, index) => (
                  <Box
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: index !== onBoardingProcessSteps.length - 1 ? 'auto auto' : 'auto',
                    }}
                    key={index}
                  >
                    <Box
                      sx={{
                        height: '90px',
                        display: 'grid',
                      }}
                    >
                      <Button
                        sx={{
                          ...stepRoundBoxStyles,
                          background: activeStep === index ? '#85C4E7' : 'none',
                          color: activeStep === index ? 'white' : '#676767',
                          fontWeight: activeStep === index ? 400 : 300,
                          width: activeStep === index ? '98px' : '72px',
                          height: activeStep === index ? '98px' : '72px',
                          my: 'auto',
                        }}
                        // If going previous then should allow the user

                        onClick={() => activeStep > index && setActiveStep(index)}
                        type={activeStep > index ? 'button' : 'submit'}
                        id={`index_${index}`}
                      >
                        0{index + 1}
                      </Button>
                    </Box>
                    {index !== onBoardingProcessSteps.length - 1 && (
                      <Box component="img" src="/assets/whiteBorder.svg" sx={{ my: 'auto' }} />
                    )}

                    <Typography
                      sx={{
                        textAlign: 'center',
                        mt: '5px',
                        display: 'grid',
                        alignItems: 'end',
                        fontSize: activeStep !== index ? '12px' : 'inherit',
                        fontFamily: "'Noto Sans JP'",
                        maxWidth: '70px',
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>
                ))}
              </Box>
              {activeStep === 0 && (
                <Step1
                  schoolsData={schoolsData?.data?.data}
                  stepperBoxStyles={stepperBoxStyles}
                  teachersOnboardingTextFields={teachersOnboardingTextFields}
                  errors={errors}
                  isOnlyEdifier={isOnlyEdifier}
                  preferredMethodOfCommunication={preferredMethodOfCommunication}
                  checkboxGroupSelection={checkboxGroupSelection}
                  edifierToEditData={edifierToEditData}
                  ethnoracialData={ethnoracialData}
                  setCheckboxValues={setCheckboxValues}
                  register={register}
                  control={control}
                  setError={setError}
                  onboardingStepsContent={onboardingStepsContent}
                  getValues={getValues}
                  Select={Select}
                  watch={watch}
                  setValue={setValue}
                  setSelectedForm={setSelectedForm}
                />
              )}

              {activeStep === 1 && (
                <Step2
                  onboardingEarnedDegreesCheckboxData={onboardingEarnedDegreesCheckboxData}
                  edifierToEditData={edifierToEditData}
                  institutionDataMocks={institutionDataMocks}
                  programTypeMock={programTypeMock}
                  register={register}
                  errors={errors}
                  formOtherDefaultValues={formOtherDefaultValues}
                  teachersOnboardingTextFields={teachersOnboardingTextFields}
                  teachingExperienceItems={teachingExperienceItems}
                  checkboxGroupSelection={checkboxGroupSelection}
                  setCheckboxGroupSelection={setCheckboxGroupSelection}
                  isOnlyEdifier={isOnlyEdifier}
                  setActiveStep={setActiveStep}
                  getValues={getValues}
                  setValue={setValue}
                  watch={watch}
                />
              )}

              {activeStep === 2 && (
                <Step3
                  edifierToEditData={edifierToEditData}
                  register={register}
                  errors={errors}
                  formOtherDefaultValues={formOtherDefaultValues}
                  teachersOnboardingTextFields={teachersOnboardingTextFields}
                  teachingExperienceItems={teachingExperienceItems}
                  checkboxGroupSelection={checkboxGroupSelection}
                  isOnlyEdifier={isOnlyEdifier}
                  setActiveStep={setActiveStep}
                  setCheckboxGroupSelection={setCheckboxGroupSelection}
                  setValue={setValue}
                />
              )}

              {activeStep === 3 && (
                <Step4
                  edifierToEditData={edifierToEditData}
                  register={register}
                  errors={errors}
                  formOtherDefaultValues={formOtherDefaultValues}
                  teachersOnboardingTextFields={teachersOnboardingTextFields}
                  teachingExperienceItems={teachingExperienceItems}
                  checkboxGroupSelection={checkboxGroupSelection}
                  isOnlyEdifier={isOnlyEdifier}
                  setActiveStep={setActiveStep}
                  setCheckboxGroupSelection={setCheckboxGroupSelection}
                  loading={isLoading || isModifyingCoach}
                  setValue={setValue}
                  watch={watch}
                  setCheckboxValues={setCheckboxValues}
                />
              )}
            </form>
          </Box>
        </StyledContent>
      </Box>
    </StyledRoot>
  );
}
