import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ items, tabs }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ style: { background: '#A94B9C' } }}
          sx={{ pl: '42.95px' }}
        >
          {items.map((tabItem, index) => (
            <Tab
            key={index}
              label={tabItem}
              {...a11yProps(index)}
              sx={{ color: '#769C98', '&.Mui-selected': { color: 'text.titles' } }}
            />
          ))}
        </Tabs>
      </Box>

      {tabs.map((tabComponent, index) => (
        <CustomTabPanel value={value} index={index} key={index}>
          {tabComponent.component}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
