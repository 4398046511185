import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { logOutUser, generateToastError } from '../../../utils';
import { queryKeys } from '../queryKeys';

const httpStatuses = { unauthorized: 401, error: 500 };

export const useQueryGetSchoolsList = (payload, toast) => {
  const queryKey = [queryKeys.getSchoolsList, payload];

  const queryFn = () => axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/schools`);

  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      generateToastError(toast, e);
    },
  });
};

export const useMutationAddSchool = (payload, toast, handleAddSchoolClose, userToken, queryClient, navigate) => {
  const queryKey = [queryKeys.addSchools, payload];
  const queryFn = () =>
    axios.post(`${process.env.REACT_APP_SERVER_URL}/api/admin/school/add`, payload?.formData, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success.data.message}`, {
        duration: 10000,
      });
      handleAddSchoolClose();
      queryClient.invalidateQueries({ queryKey: [queryKeys.getSchoolsList] });
    },
    onError: (error) => {
      try {
        if (error?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
          logOutUser(navigate);
          return;
        }
        generateToastError(toast, error);

        handleAddSchoolClose();
      } catch (e) {
        toast.error('The Error ', e);
      }
    },
  });
};

export const useMutationModifySchoolRecord = (
  payload,
  toast,
  handleClose,
  userToken,
  setEdifierToEditData,
  navigate,
  queryClient
) => {
  const queryKey = [queryKeys.modifyCoachRecord, payload];
  const queryFn = () =>
    axios.put(`${process.env.REACT_APP_SERVER_URL}/api/admin/school/update`, payload?.formData, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success.data.message}`, {
        duration: 3000,
      });
      setEdifierToEditData({});
      queryClient.invalidateQueries({ queryKey: [queryKeys.getSchoolsList] });

      handleClose();
    },
    onError: (error) => {
      try {
        if (error?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
          logOutUser(navigate);
          return;
        }
        generateToastError(toast, error);
      } catch (e) {
        toast.error('The errror ', e);
      }
    },
  });
};

export const useQueryDeleteSchool = (payload, navigate, setDeleteOperationOpen, toast, queryClient) => {
  const queryKey = [queryKeys.deleteCoach, payload];

  const queryFn = ({ queryKey }) =>
    axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/admin/school/delete/${queryKey[1]._id}`, {
      headers: { Authorization: `Bearer ${queryKey[1].token}` },
    });

  return useQuery(queryKey, !payload.token ? logOutUser(navigate) : queryFn, {
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,

    staleTime: Infinity,
    onSuccess: () => {
      setDeleteOperationOpen(false);

      queryClient.invalidateQueries({ queryKey: [queryKeys.getSchoolsList] });
    },

    onError: (e) => {
      setDeleteOperationOpen(false);

      if (e?.message.includes('Network Error')) {
        toast.error('Backend Service Not Found', { duration: 5000 });
        return;
      }

      if (e.response.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
      }

      if (e?.response?.status === httpStatuses.error) {
        toast.error('Either Server is Down or Item is already deleted', { duration: 3000 });
      }
    },
  });
};
