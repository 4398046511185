import React from 'react';
import { Box } from '@mui/material';
import { Tabs } from '../../../components/tabs';
import AboutCoach from './AboutCoach';

const CoachInfo = ({ bio }) => (
  <Box
    sx={{
      width: '100%',
      boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
      borderRadius: '20px',
      background: 'white',
      mt: '30px',
    }}
  >
    <Tabs items={['About']} tabs={[{ component: <AboutCoach bio={bio !== '' ? bio : undefined} /> }]} />
  </Box>
);

export default CoachInfo;
