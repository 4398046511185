import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: 'none',
          },
        },
        sizeLarge: {
          height: 48,
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
          background: 'red',
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },

        videoMain: {
          width: '135px',
          height: '130px',
          background: '#EDF7FF',
          color: 'black',
          fontWeight: '300',
          fontSize: '17px',
          display: 'block',
          borderRadius: '10px',
          border: '2px solid #98D1FD',
        },

        uploadMain: {
          width: '187px',
          height: '39px',
          background: 'white',
          color: '#8E71AD',
          fontWeight: '300',
          fontSize: '14px',
          display: 'block',
          borderRadius: '10px',
          border: '2px solid #5EC4C7',
        },


        outlinedInherit: {
          border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
