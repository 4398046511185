import { useEffect, useRef } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const useFirebaseMessaging = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  const notificationRef = useRef(null);

  const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const currentToken = await getToken(messaging);
        return currentToken;
      }
    } catch (err) {
      console.error('Error:', err);
    }
    return null;
  };

  const handleNotification = (payload) => {
    const { title, body } = payload.notification;
    notificationRef.current = new Notification(title, { body });
  };

  useEffect(() => {
    requestNotificationPermission();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && notificationRef.current) {
        notificationRef.current.close();
        notificationRef.current = null;
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    onMessage(messaging, (payload) => {
      handleNotification(payload);
    });

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [messaging]);

  return {
    messaging,
    requestNotificationPermission,
  };
};

export default useFirebaseMessaging;
