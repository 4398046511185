import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import toast, { Toaster, ToastBar } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';


import CloseIcon from '@mui/icons-material/Close';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { reactAppClientId } from './config';
import './styles/styles.css';

// ----------------------------------------------------------------------

export default function App() {
  const queryClient = new QueryClient();

  //  const messaging = firebase.messaging();

  return (
    <HelmetProvider>
      <Toaster position="top-right" reverseOrder={false} limit={2}>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <button type="button" onClick={() => toast.dismiss(t.id)}>
                    <CloseIcon sx={{ fontSize: '16px' }} />
                  </button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen/> */}

        <GoogleOAuthProvider clientId={reactAppClientId}>
          <BrowserRouter>
            <ThemeProvider>
              <ScrollToTop />
              <StyledChart />
              <Router />
            </ThemeProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}
