import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, IconButton } from '@mui/material';
import { setUserDetails } from '../utils';

const AppNotifications = () => {
  const [account, setAccount] = useState({});

  useEffect(() => {
    setUserDetails(setAccount);
  }, []);

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    const splittedName = name?.split(' ');

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: splittedName[1][0] ? `${splittedName[0][0]}${splittedName[1][0]}` : `${splittedName[0][0]}`,
    };
  }
  return (
    <div>
      {' '}
      <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
        Notifications
      </Typography>
      <Box
        sx={{
          border: '2px solid #98D1FD',
          background: '#EDF7FF',
          borderRadius: '10px',
          py: '10px',
          px: '20px',
          display: 'flex',
          columnGap: '20px',
        }}
      >
        <IconButton
          sx={{
            p: 0,
          }}
        >
          <Avatar {...stringAvatar('Usman Haider')} sx={{ height: '80px', width: '80px' }} />
        </IconButton>
        <Box sx={{ display: 'grid' }}>
          <Typography sx={{ fontWeight: 'bold' }}>Usman Wants to send a Meeting Invitation</Typography>
          <Typography sx={{ color: '#7C9698' }}>6 days Ago</Typography>
        </Box>
      </Box>
    </div>
  );
};

export default AppNotifications;
