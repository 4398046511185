/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, TextField, Typography, CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { BreadCrumbs } from '../breadcrumbs';
import toast, { useToasterStore } from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable option
import { days } from '../../_mock';
import { LoadingButton } from '@mui/lab';

import {
  getUserDetails,
  formatDateTimeSlots,
  formattedSlotsForCalendar,
  accessUserCalendar,
  isDateInCurrentWeek,
  getHourlyFormat,
} from '../../utils';
import {
  userQueryGetTimeSlots,
  useMutationModifyTimeSlots,
  useMutationSendInviteNotifications,
  useQueryGetSentInvites,
} from '../../hooks/react-query/useData';

import useGoogleSignIn from '../../hooks/google-signin/useGoogleSignin';

import { cardBoxStyles, mainTitlesStyles } from '../../jsxStyles';

import { TimeSlotsDialog } from '../dialog';

const TOAST_LIMIT = 2;

const AvailabilityCalendar = ({ isEdifier }) => {
  const calendarRef = useRef(null);

  const { toasts } = useToasterStore();

  const [searchParams] = useSearchParams();

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [currentUserGoogleEvents, setCurrentUserGoogleEvents] = useState([]);

  let [validatedDateFields, setValidatedDateFields] = useState({ startDate: false, endDate: false });

  const { login, user } = useGoogleSignIn(accessUserCalendar, isEdifier, setCurrentUserGoogleEvents);

  const [availabilitySlots, setAvailabilitySlots] = useState([]);
  const [openTimeSlotsDialog, setOpenTimeSlotsDialog] = useState(false);

  const [refactoredSlots, setRefactoredSlots] = useState([]);
  const [manualSlots, setManualSlots] = useState({ start: '', end: '', title: '' });

  const [inviteNotificationPayload, setInviteNotificationPayload] = useState({});

  let [refactoredInvites, setRefactoredInvites] = useState({});

  const userDetails = getUserDetails();
  const userToken = userDetails.token;
  const name = searchParams?.get('name');

  const { isFetching: isTimeslotsLoading, data: timeSlotsData } = userQueryGetTimeSlots(
    toast,
    userToken,
    searchParams.get('id') ? searchParams.get('id') : userDetails.id
  );

  const { mutate: modifySlots, isLoading: isModifyingTimeSlots } = useMutationModifyTimeSlots(
    refactoredSlots,
    toast,
    userToken,
    setRefactoredSlots
  );

  const {
    data,
    isLoading: isNotificationSending,
    mutate: sendMeetingNotification,
  } = useMutationSendInviteNotifications(inviteNotificationPayload, toast, userToken, queryClient);

  const { data: sentInvitesList, isFetching: isLoadingSentInvites } = useQueryGetSentInvites(
    userToken,
    navigate,
    toast
  );

  useEffect(() => {
    sentInvitesList?.data?.data.filter((item) => {
      if (item.userId === searchParams.get('id') && isDateInCurrentWeek(item?.scheduleTime)) {
        refactoredInvites = { ...refactoredInvites, [item?.scheduleTime]: item };
        return item;
      }
    });

    setRefactoredInvites(refactoredInvites);
  }, [sentInvitesList]);

  useEffect(() => {
    setAvailabilitySlots([]);

    if (timeSlotsData?.data?.data[0]?.availabilitySlots) {
      const formattedSlots = formattedSlotsForCalendar(timeSlotsData?.data?.data[0]?.availabilitySlots);

      setAvailabilitySlots(formattedSlots);
    }
  }, [timeSlotsData]);

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  useEffect(() => {
    if (refactoredSlots?.slots) {
      modifySlots();
    }
  }, [refactoredSlots]);

  useEffect(() => {
    if (Object.keys(inviteNotificationPayload).length > 0) {
      sendMeetingNotification();
    }
  }, [inviteNotificationPayload]);

  // in case of user try adding slot over a slot
  const isSlotOverlap = (newSlot) => {
    // Check if the new slot overlaps with any existing slots
    return availabilitySlots.some(
      (existingSlot) =>
        (newSlot.start >= existingSlot.start && newSlot.start < existingSlot.end) ||
        (newSlot.end > existingSlot.start && newSlot.end <= existingSlot.end) ||
        (newSlot.start <= existingSlot.start && newSlot.end >= existingSlot.end)
    );
  };

  // in case of user try adding slot over a slot

  const handleSelectSlot = ({ start, end }) => {
    // Handle slot selection logic, e.g., add the selected slot to availabilitySlots
    const newSlot = {
      start,
      end,
      title: 'Available',
    };

    if (!isSlotOverlap(newSlot)) {
      setAvailabilitySlots([...availabilitySlots, newSlot]);
    } else {
      alert('Slot overlaps, Please select a different time.');
    }
  };

  const handleRemoveSlot = (selectedSlot) => {
    // Handle logic to remove the selected slot from availabilitySlots

    const updatedSlots = availabilitySlots.filter((slot) => !isSlotsEqual(slot, selectedSlot));

    setAvailabilitySlots(updatedSlots);
  };

  const isSlotsEqual = (slot1, slot2) => {
    return slot1.start.toString() === slot2.start.toString();
  };

  const saveAvailableTimeSlots = () => {
    const formattedSlots = formatDateTimeSlots(availabilitySlots);

    setRefactoredSlots({ slots: formattedSlots });
  };

  function getDayIndex(day) {
    const daysOrder = days;
    return daysOrder.indexOf(day);
  }

  // manually entered slots through date time input fields
  const handleManualSlots = () => {
    if (!manualSlots.end || !manualSlots.start) {
      toast.error('Start/End Date Required');

      if (!manualSlots.end) {
        validatedDateFields['endDate'] = true;
      }
      if (!manualSlots.start) {
        validatedDateFields['startDate'] = true;
      }
      setValidatedDateFields({ ...validatedDateFields });
      return;
    }

    if (
      new Date(manualSlots.end).getDate() != new Date(manualSlots.start).getDate() ||
      new Date(manualSlots.end).getFullYear() != new Date(manualSlots.start).getFullYear()
    ) {
      toast.error('Date and Year must be the same');

      setValidatedDateFields({ startDate: true, endDate: true });

      return;
    }
    if (new Date(manualSlots.end) < new Date(manualSlots.start)) {
      toast.error('Please select a greater date-time in end time slot');

      setValidatedDateFields({ endDate: true });

      return;
    }
    const newSlot = { start: new Date(manualSlots.start), end: new Date(manualSlots.end), title: manualSlots?.title };

    const isOverlapping = availabilitySlots.some((slot) => {
      return (
        (newSlot.start >= slot.start && newSlot.start < slot.end) ||
        (newSlot.end > slot.start && newSlot.end <= slot.end) ||
        (newSlot.start <= slot.start && newSlot.end >= slot.end)
      );
    });

    if (isOverlapping) {
      toast.error('Slot overlaps with existing slots. Choose another time.');
      setValidatedDateFields({ startDate: true, endDate: true });

      return;
    }

    toast.success('Slot added to Calendar, Press Save for Updating');
    setAvailabilitySlots([
      ...availabilitySlots,
      { start: new Date(manualSlots.start), end: new Date(manualSlots.end), title: manualSlots.title },
    ]);

    setManualSlots({ start: '', end: '', title: '' });
  };

  const handleValidation = (manualSlots, value, type) => {
    if (type === 'start') {
      setManualSlots({ ...manualSlots, start: value });

      if (validatedDateFields?.startDate) {
        setValidatedDateFields({ ...validatedDateFields, startDate: false });
      }
    } else {
      setManualSlots({ ...manualSlots, end: value });

      if (validatedDateFields?.endDate) {
        setValidatedDateFields({ ...validatedDateFields, endDate: false });
      }
    }
  };

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | {isEdifier ? 'User Availability' : 'My Availability'}</title>
      </Helmet>
      {isEdifier && (
        <BreadCrumbs
          breadCrumbs={
            userDetails?.role === 'COACH'
              ? [
                  { title: 'Your Teachers', link: '/dashboard/teachers' },
                  {
                    title: searchParams.get('name'),
                    link: `/dashboard/user-profile?id=${searchParams.get('id')}&role=edifier`,
                  },
                  { title: `${searchParams.get('name')}'s Availability` },
                ]
              : [
                  { title: 'Find an Edifier', link: '/dashboard/find-edifiers' },
                  {
                    title: searchParams.get('name'),
                    link: `/dashboard/user-profile?id=${searchParams.get('id')}&role=teacher`,
                  },
                  { title: `${searchParams.get('name')}'s Availability` },
                ]
          }
        />
      )}
      {currentUserGoogleEvents?.length > 0 && isEdifier && (
        <>
          <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block', mt: '30px' }}>
            My Google Schedule
          </Typography>

          <div className="timeslots-calendar timeslots-calendar-google">
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              dayHeaderFormat={{ weekday: 'short' }} // Display only the day names (e.g., Sun, Mon, Tue)
              selectable={false}
              // select={handleSelectSlot}
              events={currentUserGoogleEvents}
              headerToolbar={{
                // left: 'prev,next today',
                center: 'title',
                right: 'timeGridWeek,timeGridDay',
              }}
              eventContent={(eventContent) => {
                return (
                  <div>
                    <strong>{eventContent.timeText}</strong>
                    {!isEdifier && (
                      <button
                        style={{ position: 'absolute', right: '7px', top: '7px', background: 'none', border: 'none' }}
                        onClick={() => handleRemoveSlot(eventContent.event)}
                      >
                        <img src="/assets/closeIcon.svg" alt="close-icon" style={{ cursor: 'pointer' }} />
                      </button>
                    )}
                  </div>
                );
              }}
              eventBackgroundColor="#8E71AD"
              eventBorderColor="#8E71AD"
            />
          </div>
        </>
      )}
      {user && currentUserGoogleEvents && currentUserGoogleEvents?.length === 0 && isEdifier && (
        <Box
          sx={{
            ...cardBoxStyles,
            minWidth: '100%',
            mt: '30px',
            borderRadius: 0,
            boxShadow: 'none',
            height: '88px',
            p: '0',
            display: 'grid',
            alignItems: 'center',
          }}
        >
          <Typography variant="mainTitles" sx={{ ...mainTitlesStyles, textAlign: 'center', py: '20px' }}>
            No Events found on your Google Calendar
          </Typography>
        </Box>
      )}

      {isEdifier && !user && (
        <Box sx={{ textAlign: 'center', mb: '40px', mt: '20px' }}>
          <Button variant="contained" onClick={() => login()}>
            <Box component="img" src="/assets/googleIcon.svg" sx={{ mr: '10px' }} />
            Connect With Google to view your Calendar
          </Button>
        </Box>
      )}

      <TimeSlotsDialog
        availabilitySlots={availabilitySlots}
        open={openTimeSlotsDialog}
        getDayIndex={getDayIndex}
        handleClose={() => setOpenTimeSlotsDialog(false)}
        setInviteNotificationPayload={setInviteNotificationPayload}
        edifierId={searchParams.get('id')}
        isNotificationSending={isNotificationSending}
        inviteNotificationPayload={inviteNotificationPayload}
        refactoredInvites={refactoredInvites}
        isLoadingSentInvites={isLoadingSentInvites}
        isEdifier={userDetails?.role === 'COACH'}
      />

      <Box className="timeslots-calendar timeslots-calendar-google2" sx={{ mt: isEdifier && '30px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column', md: 'row' } }}>
          <Typography
            variant="mainTitles"
            sx={{
              mb: '5px',
              color: 'text.titles',
              display: 'block',
              textTransform: 'capitalize',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            {isEdifier ? `${name} ` : 'My '}Availability
          </Typography>
          {!isEdifier && (
            <Box
              sx={{
                textAlign: 'right',
                mb: '26px',
                display: 'flex',
                columnGap: '12px',
                justifyContent: 'right',
                height: '35px',
              }}
            >
              {/* <LoadingButton
                variant="outlined"
                color="main"
                // onClick={() => saveAvailableTimeSlots()}
                sx={{ width: '118px' }}
              >
                Delete All
              </LoadingButton> */}

              <LoadingButton
                variant="contained"
                loading={isModifyingTimeSlots}
                color="main"
                onClick={() => saveAvailableTimeSlots()}
                sx={{ width: '118px' }}
              >
                Save Slot
              </LoadingButton>
            </Box>
          )}

          {isEdifier && (
            <Button
              variant="contained"
              color="main"
              sx={{
                height: '40px',
                mt: '20px',
                mb: { xs: '20px', md: '0' },
                width: 'fit-content',
                mx: { xs: 'auto', md: '0' },
              }}
              onClick={() => setOpenTimeSlotsDialog(true)}
            >
              Schedule Meeting
            </Button>
          )}
        </Box>
        {/* {!isEdifier && (
          <Box
            sx={{
              ...manualTimeSlotsBoxStyles,
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <TextField
                label="Start Time"
                id="start-date-time"
                error={validatedDateFields?.startDate}
                name="start-date-time"
                type="datetime-local"
                sx={{ width: '243px', marginLeft: '13px' }}
                InputLabelProps={{ shrink: true }}
                value={manualSlots.start}
                onChange={(e) => handleValidation(manualSlots, e.target.value, 'start')}
              />

              <TextField
                error={validatedDateFields?.endDate}
                label="End Time"
                id="end-date-time"
                name="end-date-time"
                type="datetime-local"
                sx={{ width: '243px', marginLeft: '13px' }}
                InputLabelProps={{ shrink: true }}
                value={manualSlots.end}
                onChange={(e) => handleValidation(manualSlots, e.target.value, 'end')}
              />
            </Box>

            <LoadingButton
              variant="contained"
              color="main"
              sx={{ height: '39px', my: 'auto' }}
              onClick={() => handleManualSlots()}
            >
              Add Slot
            </LoadingButton>
          </Box>
        )} */}
        {isTimeslotsLoading && (
          <Box sx={{ textAlign: 'center', position: 'relative', top: '200px' }}>
            <CircularProgress />
          </Box>
        )}
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          dayHeaderFormat={{ weekday: 'short' }} // Display only the day names (e.g., Sun, Mon, Tue)
          selectable={!isEdifier}
          select={handleSelectSlot}
          ref={calendarRef}
          events={availabilitySlots}
          headerToolbar={{
            // left: 'prev,next today',
            center: 'title',
            right: 'timeGridWeek,timeGridDay',
          }}
          eventContent={(eventContent) => {
            return (
              <div>
                <strong>{eventContent.timeText}</strong>
                {!isEdifier && (
                  <button
                    style={{ position: 'absolute', right: '7px', top: '7px', background: 'none', border: 'none' }}
                    onClick={() => handleRemoveSlot(eventContent.event)}
                  >
                    <img src="/assets/closeIcon.svg" alt="close-icon" style={{ cursor: 'pointer' }} />
                  </button>
                )}
              </div>
            );
          }}
          eventBackgroundColor="#8E71AD"
          eventBorderColor="#8E71AD"
          scrollTime={'08:00:00'}
          dayHeaderContent={(args) => {
            // Format the date as XX/XX
            const formattedDate =
              args.date.getDate().toString().padStart(2, '0') +
              '/' +
              (args.date.getMonth() + 1).toString().padStart(2, '0');
            // Combine short day name and formatted date using JSX
            return (
              <div>
                <span>{args.text}</span>
                <br />
                <span>{formattedDate}</span>
              </div>
            );
          }}
          // slotDuration="00:15:00" // Set the slot duration to 15 minutes
        />
      </Box>
    </Box>
  );
};

export default AvailabilityCalendar;
