/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Helmet } from 'react-helmet-async';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import toast, { Toaster } from 'react-hot-toast';
import Typography from '@mui/material/Typography';
import { loginFormStyles } from '../jsxStyles';
import { useQueryVerifyLoginEmail, useMutationForgotPassword } from '../hooks/react-query/useData';
import useFirebaseMessaging from '../firebase';

export default function SignIn() {
  const [loginCredentials, setLoginCredentials] = useState({});

  const [forgetPasswordCredentials, setForgetPasswordCredentials] = useState({});

  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { requestNotificationPermission } = useFirebaseMessaging();

  const onSuccess = (success) => {
    if (success.data.message.includes('login successfully')) {
      localStorage.setItem('user', JSON.stringify(success?.data?.data));
      navigate('/dashboard/my-profile');
    }
  };

  const onError = (error) => {
    if (error?.message === 'Network Error') {
      toast.error('Server Error');
      return;
    }
    toast.error(error?.response?.data?.message, { duration: 5000 });
  };

  const {
    refetch: loginCurrentUser,
    data: loginData,
    isLoading: loginLoading,
  } = useQueryVerifyLoginEmail(loginCredentials, onSuccess, onError);

  const { mutate: forgotMyPassword, isLoading: isForgetPasswordsLoading } = useMutationForgotPassword(
    forgetPasswordCredentials,
    toast,
    setIsLogin
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({});

  useEffect(() => {
    if (Object.keys(loginCredentials).length > 0) {
      loginCurrentUser();
    }
  }, [loginCredentials]);

  useEffect(() => {
    if (Object.keys(forgetPasswordCredentials).length > 0) {
      forgotMyPassword();
    }
  }, [forgetPasswordCredentials]);

  const onSubmit = (data) => {
    (async () => {
      const token = await requestNotificationPermission();

      if (token) {
        setLoginCredentials({ ...data, deviceToken: String(token) });
      } else {
        setLoginCredentials({ ...data });
      }
    })().catch((err) => {
      setLoginCredentials({ ...data });
    });
  };

  const forgotPasswordSubmit = async (data) => {
    setForgetPasswordCredentials({ email: data?.email });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        background: '#F9FAFC',
        justifyContent: 'center',
      }}
    >
      <Helmet>
        <title>Edifying Teachers | {isLogin ? 'Login' : 'Forgot Password'}</title>
      </Helmet>
      {/* <Toaster /> */}
      <Box
        sx={{
          backgroundImage: { xs: '', sm: 'url(/assets/loginFormBg.svg)' },
          width: '644px',
          height: '648px',
          display: 'grid',
          my: 'auto',
          backgroundRepeat: 'no-repeat',
          backgroundSize: { xs: 'contain', sm: 'cover' },
        }}
      >
        <Box
          sx={{
            ...loginFormStyles,
          }}
        >
          <Box component="img" src="/assets/newLogo.png" sx={{ mx: 'auto', height: '90px' }} />
          <Typography variant="mainTitles" sx={{ color: 'text.titles', minWidth: 'max-content', mt: '5.71px' }}>
            {isLogin ? 'Login to Your Account' : 'Forgot Your Password'}{' '}
          </Typography>
          <Box
            component="form"
            onSubmit={isLogin ? handleSubmit(onSubmit) : handleSubmit(forgotPasswordSubmit)}
            noValidate
            sx={{ mt: 1, width: '100%' }}
          >
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              {...register('email', {
                required: 'Email is Required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              error={errors && !!errors?.email}
              helperText={errors?.email?.message}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {!isLogin && (
                <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLogin(true);
                    }}
                    style={{
                      display: 'block',
                      fontSize: '14px',
                      fontWeight: 400,
                      color: '#126FD6',
                      fontFamily: "'Inter', sans-serif",
                      paddingLeft: '0',
                      marginTop: '10px',
                      textDecoration: 'none',
                      backgroundColor: 'white',
                    }}
                  >
                    <span
                      style={{ fontWeight: 'normal' }}
                      onMouseOver={(e) => {
                        e.target.style.fontWeight = 'bold';
                      }}
                      onMouseOut={(e) => {
                        e.target.style.fontWeight = 'normal';
                      }}
                    >
                      Back to Login
                    </span>
                  </a>
                </Typography>
              )}

              {!isLogin && (
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2, mb: 2, maxWidth: '118px' }}
                  color="main"
                  loading={isForgetPasswordsLoading}
                >
                  Submit
                </LoadingButton>
              )}
            </Box>

            {isLogin && (
              <>
                <Box
                  sx={{
                    position: 'relative',
                    // border: '5px solid red',
                    marginBottom: '0px',
                    marginTop: '0px',
                  }}
                >
                  <TextField
                    sx={{ marginBottom: '5px', marginTop: '5px' }}
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    {...register('password', {
                      required: 'Password is Required',
                    })}
                    error={errors && !!errors?.password}
                    helperText={errors?.password?.message}
                  />

                  {showPassword && (
                    <Visibility
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        transform:
                          errors?.password || errors?.email ? 'translate(-50%, -85%)' : 'translate(-50%, -50%)',
                        right: '0px',
                        cursor: 'pointer',
                        color: '#8e71ad',
                      }}
                      onClick={() => setShowPassword(false)}
                    />
                  )}

                  {!showPassword && (
                    <VisibilityOff
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        transform:
                          errors?.password || errors?.email ? 'translate(-50%, -85%)' : 'translate(-50%, -50%)',
                        right: '0px',
                        cursor: 'pointer',
                        color: '#8e71ad',
                      }}
                      onClick={() => setShowPassword(true)}
                    />
                  )}
                </Box>

                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsLogin(!isLogin);
                  }}
                  style={{
                    display: 'block',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#126FD6',
                    fontFamily: "'Inter', sans-serif",
                    paddingLeft: '0',
                    marginTop: '10px',
                    textDecoration: 'none',
                    backgroundColor: 'white',
                  }}
                >
                  <span
                    style={{ fontWeight: 'normal' }}
                    onMouseOver={(e) => {
                      e.target.style.fontWeight = 'bold';
                    }}
                    onMouseOut={(e) => {
                      e.target.style.fontWeight = 'normal';
                    }}
                  >
                    Forgot Your Password?
                  </span>
                </a>

                <LoadingButton
                  loading={loginLoading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, maxWidth: '118px' }}
                  color="main"
                >
                  Login
                </LoadingButton>
                <a
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/onboard-user');
                  }}
                  style={{
                    display: 'block',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#126FD6',
                    paddingLeft: '0',
                    textDecoration: 'none',
                    fontFamily: "'Inter', sans-serif",
                    marginTop: '10px',
                    backgroundColor: 'white',
                  }}
                >
                  <span
                    style={{ fontWeight: 'normal' }}
                    onMouseOver={(e) => {
                      e.target.style.fontWeight = 'bold';
                    }}
                    onMouseOut={(e) => {
                      e.target.style.fontWeight = 'normal';
                    }}
                  >
                    Create Account / Onboard Yourself
                  </span>
                </a>
              </>
            )}
          </Box>
          {/* <div style={{alignContent:"end",width:"100%",justifyContent:"right",cursor:"pointer",marginTop:"1rem"}}>
            <Typography onClick={()=>{navigate("/terms-and-conditions")}}>Terms and Conditions</Typography>
          </div> */}
        </Box>
      </Box>
    </Box>
  );
}
