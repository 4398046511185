/* eslint-disable */

import React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

export default function BasicSelect({
  register,
  field,
  errors,
  label,
  items,
  defaultValue,
  disabled,
  onChange,
  watch,
  isOnlyEdifier,
  schoolDistrict,
}) {
  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  return (
    <Box sx={{ width: '100%' }}>
      {!schoolDistrict && (
        <span style={{ fontSize: '12px', lineHeight: '10px' }}>
          {isOnlyEdifier && field.name === 'districtSchool'
            ? 'Which best characterizes the school(s) type where you have the most experience?'
            : field.name === 'districtSchool' && 'Which best characterizes your district?'}
          {field.name === 'state' && 'State'}
        </span>
      )}

      {register ? (
        <TextField
          id={field.name}
          name={field.name}
          // label={field.label}
          sx={{
            '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-20px', margin: 0 },
            mt: '10px',
          }}
          label={field.label}
          fullWidth
          {...register(field.name, { required: field?.requiredLabel ? field?.requiredLabel : 'This is required' })}
          disabled={disabled}
          // {...register(field.name, { required: `${field.label} is required` })}
          error={errors && !!errors[field.name] && !watch(field?.name)}
          helperText={errors[field.name]?.message}
          select
          defaultValue={defaultValue}
          value={
            field?.name === 'gender' && !defaultValue
              ? watch('gender')
                ? watch('gender')?.toLowerCase()
                : ''
              : defaultValue
          }
          onChange={onChange}
        >
          {items ? (
            items.map((listItems, key) => (
              <MenuItem
                value={listItems.toLowerCase()}
                key={key}
                sx={{
                  display: (listItems.includes('Select') || listItems.includes('select')) && 'none',
                  fontSize: { xs: '12px', sm: '16px' },
                  textWrap: 'pretty',
                }}
              >
                {listItems}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="Item1">Item1</MenuItem>
          )}
        </TextField>
      ) : (
        <TextField
          id={field.name}
          name={field.name}
          label={field.label}
          fullWidth
          disabled={disabled}
          select
          defaultValue={defaultValue ? defaultValue : items ? items[0].toLowerCase() : 'Item1'}
        >
          {items ? (
            items.map((listItems, key) => (
              <MenuItem
                value={listItems.toLowerCase()}
                key={key}
                sx={{
                  display: (listItems.includes('Select') || listItems.includes('select')) && 'none',
                  fontSize: { xs: '12px', sm: '16px' },
                  textWrap: 'pretty',
                }}
              >
                {listItems}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="Item1">Item1</MenuItem>
          )}
        </TextField>
      )}
    </Box>
  );
}
