import * as React from 'react';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import CircularProgress from '@mui/material/CircularProgress';

import Typography from '@mui/material/Typography';

export default function AlertDialog({ open, handleClose, isDeleting, isSchool }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiPaper-root': {
            width: { xs: '350px', sm: '448px' },
            height: '330px',
            borderRadius: '20px',
          },
        }}
      >
        <DialogContent>
          <Typography variant="mainTitles" sx={{ lineHeight: '70px', color: 'text.titles', textAlign: 'center' }}>
            {isSchool ? 'Deleting School' : 'Deleting Coach'}
          </Typography>

          <Box sx={{ textAlign: 'center' }}>{isDeleting && <CircularProgress />}</Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
