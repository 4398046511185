/* eslint-disable */
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { logOutUser, generateToastError } from '../../../utils';
import { queryKeys } from '../queryKeys';

// Checkin and Checkout Logic stars from here

const httpStatuses = { unauthorized: 401, error: 500 };

export const useMutationPairWithEdifier = (
  toast,
  userToken,
  navigate,
  queryClient,
  rankedEdifierPayload,
  setRankedEdifierPayload
) => {
  const queryKey = [queryKeys.pairWithEdifier];

  const queryFn = () => {
    const payload = {
      ...rankedEdifierPayload,
    };

    const edifierId = payload?.id;

    delete payload['id'];
    return axios.post(`${process.env.REACT_APP_SERVER_URL}/api/teacher/pair/${edifierId}`, payload, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  };

  return useMutation(queryKey, queryFn, {
    enabled: true,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });

      setRankedEdifierPayload({});

      // navigate('/dashboard/paired');

      // queryClient.invalidateQueries({ queryKey: [queryKeys.userCheckInStatus] });

      // queryClient.invalidateQueries({ queryKey: [queryKeys.getPairedEdifiers] });
    },
    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useQueryGetPairedEdifiers = (userToken, navigate, toast) => {
  const queryKey = [queryKeys.getPairedEdifiers];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/teacher/get-pairs`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,
    cacheTime: 72000,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useQueryGetPairedUsersAdmin = (userToken, navigate, toast) => {
  const queryKey = [queryKeys.getPairedUsersAdmin];

  const queryFn = ({ queryKey }) =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/pairs`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    refetchOnWindowFocus: false,
    cacheTime: 72000,

    staleTime: Infinity,
    select: (data) => {
      const refactoredData = [];

      data?.data?.data?.forEach((item) => {
        const teacher = item.users.find((user) => user.role === 'TEACHER');
        const coach = item.users.find((user) => user.role === 'COACH');
        if (teacher && coach) {
          refactoredData.push({
            _id: item._id,
            'Teacher Name': `${teacher.fname} ${teacher.lname}`,
            'Teacher Email': teacher.email,
            'Edifier Name': `${coach.fname} ${coach.lname}`,
            usersInPair: { user1: coach?._id, user2: teacher?._id },
            'Edifier Email': coach.email,
            'Paired Since': new Date(item?.timestamp).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            }),
            lastSession: item?.lastSession,
          });
        }
      });
      return refactoredData;
    },
    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useMutationEndTraining = (userToken, navigate, toast, payload, setTrainingEndingPayload, queryClient) => {
  const queryKey = [queryKeys.endUsersTraining, payload];

  const queryFn = () =>
    axios.put(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/pair/${payload?.id}`,
      {},
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
  return useMutation(queryKey, queryFn, {
    retry: 0,
    refetchOnWindowFocus: false,
    cacheTime: 72000,

    staleTime: Infinity,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });

      setTrainingEndingPayload({});

      // navigate('/dashboard/paired');

      // queryClient.invalidateQueries({ queryKey: [queryKeys.userCheckInStatus] });

      // queryClient.invalidateQueries({ queryKey: [queryKeys.getPairedEdifiers] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.getPairedUsersAdmin] });
    },

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};

export const useMutationEndPairing = (userToken, navigate, toast, payload, setPairingEndingPayload, queryClient) => {
  const queryKey = [queryKeys.endUsersPairing, payload];

  const queryFn = () =>
    axios.post(
      `${process.env.REACT_APP_SERVER_URL}/api/admin/unpair`,
      { ...payload },
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
  return useMutation(queryKey, queryFn, {
    retry: 0,
    refetchOnWindowFocus: false,
    cacheTime: 72000,

    staleTime: Infinity,
    onSuccess: (success) => {
      toast.success(`${success?.data?.message}`, {
        duration: 3000,
      });

      setPairingEndingPayload({});

      // navigate('/dashboard/paired');

      // queryClient.invalidateQueries({ queryKey: [queryKeys.userCheckInStatus] });

      queryClient.invalidateQueries({ queryKey: [queryKeys.getPairedUsersAdmin] });
    },

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};
