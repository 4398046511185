import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, CircularProgress, TextField } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { getUserDetails } from '../utils';
import {
  useQueryDeleteSchool,
  useQueryGetSchoolsList,
  useMutationAddSchool,
  useMutationModifySchoolRecord,
} from '../hooks/react-query/schools';
import { mainTitlesStyles } from '../jsxStyles';
import { AddSchoolDialog, DeleteOperationDialog, DeleteDialog, EditSchoolDialog } from '../components/dialog';
import { TableView } from '../components/table';

const FindAnEdifier = () => {
  const queryClient = useQueryClient();

  const userDetails = getUserDetails();

  const userRole = userDetails?.role.toLowerCase();
  const userToken = userDetails?.token;

  const [open, setOpen] = useState(false);
  const [deleteOperationOpen, setDeleteOperationOpen] = useState(false);
  const [addSchoolsOpen, setAddSchoolsOpen] = useState(false);
  const [editSchoolOpen, setEditSchoolOpen] = useState(false);
  const [editedSchoolData, setEditedSchoolData] = useState({});

  const [schoolData, setSchoolData] = useState({});

  const [searchedValue, setSearchedValue] = useState('');

  const [schoolsRecord, setSchoolsRecord] = useState([]);

  const [itemToDeleteId, setItemToDeleteId] = useState('');
  const [schoolToEditData, setSchoolToEditData] = useState({});

  const navigate = useNavigate();

  const handleAddSchoolClose = () => {
    setAddSchoolsOpen(false);
  };

  const handleEditSchoolClose = () => {
    setEditSchoolOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteOperationClose = () => {
    setDeleteOperationOpen(false);
  };

  const { data: schoolsList, isFetching: isSchoolsListLoading } = useQueryGetSchoolsList(
    { token: userToken, role: userRole },
    navigate,
    toast
  );

  const { isLoading: isAddSchoolLoading, mutate: addSchool } = useMutationAddSchool(
    schoolData,
    toast,
    handleAddSchoolClose,
    userToken,
    queryClient,
    navigate
  );
  const { isLoading: isDeletingSchool, refetch: deleteSchool } = useQueryDeleteSchool(
    { token: userToken, _id: itemToDeleteId },
    navigate,
    setDeleteOperationOpen,
    toast,
    queryClient
  );

  const { isLoading: isModifyingSchool, mutate: modifySchool } = useMutationModifySchoolRecord(
    editedSchoolData,
    toast,
    handleEditSchoolClose,
    userToken,
    setSchoolToEditData,
    navigate,
    queryClient
  );

  useEffect(() => {
    if (schoolsList?.data?.data) {
      setSchoolsRecord(schoolsList?.data?.data);
    }
  }, [schoolsList]);

  const searchFromRecord = () => {
    if (searchedValue === '') {
      setSchoolsRecord(schoolsList?.data?.data);
      return;
    }
    const filtered = schoolsList?.data?.data.filter((item) => item.name.toLowerCase().includes(searchedValue));

    setSchoolsRecord(filtered);
  };

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Schools</title>
      </Helmet>
      <Container maxWidth="xl" sx={{ p: '0 !important' }}>
        {/* Dialogs add edit delete deleting loading  */}
        <AddSchoolDialog
          open={addSchoolsOpen}
          handleClose={handleAddSchoolClose}
          addSchool={addSchool}
          setSchoolData={setSchoolData}
          schoolData={schoolData}
          isLoading={isAddSchoolLoading}
          schoolToEditData={schoolToEditData}
          setSchoolToEditData={setSchoolToEditData}
          isEdit={editSchoolOpen}
          isAdd={addSchoolsOpen}
        />

        <EditSchoolDialog
          open={Object.keys(schoolToEditData).length > 0 && editSchoolOpen}
          handleClose={handleEditSchoolClose}
          schoolToEditData={schoolToEditData}
          setEditedSchoolData={setEditedSchoolData}
          editedSchoolData={editedSchoolData}
          modifySchool={modifySchool}
          isLoading={isModifyingSchool}
        />

        <DeleteOperationDialog
          open={deleteOperationOpen}
          handleDeleteOperationClose={handleDeleteOperationClose}
          isDeleting={isDeletingSchool}
          isSchool
        />

        <DeleteDialog
          open={open && itemToDeleteId !== ''}
          handleClose={handleClose}
          deleteCoach={deleteSchool}
          setDeleteOperationOpen={setDeleteOperationOpen}
          deleteSchool
        />
        {/* Dialogs add edit delete deleting loading  */}

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="mainTitles" sx={{ ...mainTitlesStyles }}>
              Schools
            </Typography>

            {/* <Typography variant="subtitles" sx={{ display: 'flex' }}>
              <Box component="img" src="/assets/calendarIcon.svg" sx={{ mr: '7.32px' }} />
              Member since July 11, 2021
            </Typography> */}
          </Box>
          {userRole === 'teacher' && (
            <Button variant="contained" color="main" sx={{ height: 'fit-content', my: 'auto' }}>
              Check Out
            </Button>
          )}

          <Button
            variant="contained"
            color="main"
            sx={{ height: 'fit-content', my: 'auto' }}
            onClick={() => setAddSchoolsOpen(true)}
          >
            Add School
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            mt: '23.63px',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box sx={{ display: 'flex', columnGap: '17px', flexDirection: { xs: 'column', md: 'row' }, rowGap: '20px' }}>
            <TextField
              placeholder="Search Schools by Name"
              className="filters"
              sx={{ minWidth: '233px', maxWidth: { xs: '100%', md: '233px' } }}
              onChange={(e) => setSearchedValue(e.target.value)}
            />

            {/* <FilterSelect field={{ name: 'expertise', placeholder: 'District' }} variant="filters" /> */}

            <Button
              variant="contained"
              color="main"
              sx={{ height: 'fit-content', my: 'auto', width: { xs: 'max-content', md: 'inherit' } }}
              onClick={() => searchFromRecord()}
            >
              Search
            </Button>
          </Box>
        </Box>

        {/* Loader for data being Loaded */}

        {isSchoolsListLoading && (
          <Box sx={{ textAlign: 'center', mt: '30px' }}>
            <CircularProgress />
          </Box>
        )}

        {/* Loader for data being Loaded */}

        {!isSchoolsListLoading && (
          <TableView
            isSchools
            tableData={schoolsRecord}
            setItemToDeleteId={setItemToDeleteId}
            setOpen={setOpen}
            setEditData={setSchoolToEditData}
            setEditOpen={setEditSchoolOpen}
            userRole={userRole}
            searchedValue={searchedValue}
          />
        )}
      </Container>
    </Box>
  );
};

export default FindAnEdifier;
