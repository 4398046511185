import React from 'react';

import { TextField, Box } from '@mui/material';

const ReactHookTextfield = ({
  // id,
  name,
  label,
  isRequired,
  edifierToEditData,
  register,
  type,
  defaultValue,
  setValue,
  formOtherDefaultValues,
  errors,
  placeholder,
  pattern,
  watch,
  largeLabel,
  validationLabel,
}) => {
  const valueChange = (e) => {
    // if (name === 'areaTeaching' || name === 'contentSpeciality') {
    //   const inputValue = e.target.value.replace(/[^A-Za-z\s]/g, '');

    //   setValue(name, inputValue);
    // } else {
      setValue(name, e.target.value);
    // }
  };
  const validateObj = {
    validate: {
      minMax: (value) => {
        if (value >= 0 && value <= 50) return true;
        return 'Must be between 0 and 50';
      },
    },
  };
  return (
    <Box>
      {largeLabel && <span style={{ fontSize: '12px' }}>{label}</span>}

      {!edifierToEditData || name in formOtherDefaultValues === false ? (
        <TextField
          placeholder={placeholder}
          id="demo-helper-text-aligned"
          sx={{ '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-20px', m: 0 } }}
          name={name}
          label={!largeLabel && label}
          type={type}
          fullWidth
          {...register(!isRequired ? name : name, {
            required:
              name === 'yearsClassroomTeaching'
                ? 'Please provide your experience'
                : validationLabel || 'This is Required',
            ...(name === 'yearsClassroomTeaching' && validateObj),
          })}
          rows={5}
          error={
            name !== 'yearsClassroomTeaching'
              ? isRequired && errors && !!errors[name] && !watch(name)
              : isRequired &&
                errors &&
                !!errors[name] &&
                (Number(watch('yearsClassroomTeaching')) < 0 ||
                  Number(watch('yearsClassroomTeaching')) > 50 ||
                  !watch('yearsClassroomTeaching'))
          }
          helperText={isRequired && errors[name]?.message}
          onChange={valueChange}
          pattern={pattern && pattern}
        />
      ) : (
        formOtherDefaultValues[name] && (
          <TextField
            id="demo-helper-text-aligned"
            sx={{ '& .MuiFormHelperText-root': { position: 'absolute', bottom: '-20px', m: 0 } }}
            name={name}
            label={!largeLabel && label}
            fullWidth
            rows={5}
            {...register(!isRequired ? name : name, {
              required:
                name === 'yearsClassroomTeaching'
                  ? 'Please provide your experience'
                  : validationLabel || 'This is Required',
              ...(name === 'yearsClassroomTeaching' && validateObj),
            })}
            type="text"
            error={
              name !== 'yearsClassroomTeaching'
                ? isRequired && errors && !!errors[name] && !watch(name)
                : isRequired &&
                  errors &&
                  !!errors[name] &&
                  (Number(watch('yearsClassroomTeaching')) < 0 ||
                    Number(watch('yearsClassroomTeaching')) > 50 ||
                    !watch('yearsClassroomTeaching'))
            }
            onChange={valueChange}
            helperText={isRequired && errors[name]?.message}
            defaultValue={defaultValue}
            pattern={pattern && pattern}
          />
        )
      )}
    </Box>
  );
};

export default ReactHookTextfield;
