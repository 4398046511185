import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';

function handleClick(event) {
  event.preventDefault();
}

export default function BasicBreadcrumbs({ breadCrumbs }) {
  const navigate = useNavigate();
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'black' }}>
        {breadCrumbs?.map((item, key) => (
          <Typography
            key={key}
            underline="hover"
            color="inherit"
            onClick={() => key !== breadCrumbs.length - 1 && navigate(item?.link)}
            sx={{
              fontSize: '14px',
              opacity:key===breadCrumbs.length-1?1: 0.6,
              '&:hover': key !== breadCrumbs?.length - 1 && {
                textDecoration: 'underline',
                cursor: 'pointer',
                color: '#A8469B',
                opacity: 1,
              },
              textTransform: 'capitalize',

            }}
          >
            {item?.title}
          </Typography>
        ))}
      </Breadcrumbs>
    </div>
  );
}
