import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { Table } from '../components/table';
import { edifyinJourneyTableData } from '../_mock';
import { AccordionDropdown } from '../components/accordion';
// import  DateRangePicker  from '../components/DateRangePicker';

const MyEdifyingJourney = () => (
    <>
      {' '}
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
              My Edifying Journey!
            </Typography>

            {/* <Typography variant="subtitles" sx={{ display: 'flex', mb: '46.32px' }}>
              <Box component="img" src="/assets/calendarIcon.svg" sx={{ mr: '7.32px' }} />
              Member since July 11, 2021
            </Typography> */}
          </Box>
          <Box>
            {/* <Button variant="outlined" color="main" sx={{ mr: '20px' }}>
              Submit Report
            </Button> */}

            <Button variant="contained" color="main">
              Check Out
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
            width: '100%',
            background: '#FFF',
            borderRadius: '20px',
            p: '36.37px 25.14px 42.95px 48.24px',
          }}
        >
          <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
            Edifying Session Logs
          </Typography>
          {/* <MultiDatePicker /> */}

          <Typography variant="h3" sx={{ mt: '26px' }}>
            Search Result:
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: 500, mt: '15px' }}>
            Date Range:<span style={{ fontWeight: 400 }}> July 11, 2021 to July 19, 2023</span>
          </Typography>

          <Typography variant="subtitle1" sx={{ fontWeight: 500, mt: '15px' }}>
            User: Jones Ferdinand
          </Typography>

          <Typography variant="subtitle1" sx={{ fontWeight: 500, mt: '15px', mb: '40px' }}>
            Report Type: Daily Report
          </Typography>

          <Table tableData={edifyinJourneyTableData} />
        </Box>

        <Typography
          variant="mainTitles"
          sx={{ mb: '5px', color: 'text.titles', display: 'block', m: '33.71px 0 4px 0' }}
        >
          Surveys
        </Typography>

        {Array.from(Array(3).keys()).map((item, key) => (
          <Box
            sx={{
              boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
              background: 'white',
              borderRadius: '20px',
              p: '11.7px 43.85px 16px 27.45px',
              mt: '17px',
            }}
            key={key}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  display: 'flex',
                  columnGap: '27.36px',
                }}
              >
                <Box
                  sx={{
                    height: '79px',
                    width: '79px',
                    background: '#84C3E7',
                    borderRadius: '50%',
                    textAlign: 'center',
                    display: 'grid',
                    alignItems: 'center',
                    fontSize: '38px',
                    fontWeight: 600,
                    color: 'white',
                    my: 'auto',
                  }}
                >
                  PS
                </Box>
                <Box sx={{ display: 'grid', alignItems: 'center' }}>
                  <Typography variant="secondaryTitles" sx={{ lineHeight: 'inherit' }}>
                    Post Session Surveys
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: '#769C98 ' }}>
                    Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '28px',
                  height: '28px',
                  background: '#E8F5FF',
                  borderRadius: '50%',
                  my: 'auto',
                  textAlign: 'center',
                  display: 'grid',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ChevronRightIcon sx={{ color: '#8D73AF' }} />
              </Box>
            </Box>
          </Box>
        ))}

        <Typography
          variant="mainTitles"
          sx={{ mb: '5px', color: 'text.titles', display: 'block', m: '33.71px 0 4px 0' }}
        >
          Previous Surveys
        </Typography>

        {/* <Box
          sx={{
            boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
            width: '100%',
            background: '#FFF',
            borderRadius: '20px',
            p: '36.37px 25.14px 42.95px 48.24px',
          }}
        >

          <Box sx={{ height:'58.549px',width:'100%',background:'#5EC4C7' }}>Post Session Surveys</Box>
        </Box> */}

        <AccordionDropdown />
      </Container>
    </>
  );


export default MyEdifyingJourney;
