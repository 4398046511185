/*eslint-disable*/

import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Box, Button, CircularProgress, TextField } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Dialog from '@mui/material/Dialog';
import toast from 'react-hot-toast';

import { useQueryClient } from 'react-query';
import { UserDetailedInformationDialog } from '../components/dialog';
// import { CheckinCheckout } from '../components/checkin';
import {
  FinalSessionSurveys,
  WelcomeSessionSurvey,
  SessionSurveyA,
  SessionSurveyB,
  SessionSurveyC,
} from 'src/components/surveys';
import { getUserDetails } from '../utils';
import { useQueryGetCoachesList } from '../hooks/react-query/useData';
import { useQueryGetPairedUsersAdmin, useMutationEndTraining } from '../hooks/react-query/pairing';

import { filterContainerStyles, listGridViewContainerStyles } from '../jsxStyles';

import { TableView } from '../components/table';

const FindAnEdifier = () => {
  const userDetails = getUserDetails();
  const queryClient = useQueryClient();
  const [currentResponse, setCurrentResponse] = useState({});
  const userRole = userDetails.role.toLowerCase();
  const userToken = userDetails?.token;

  const navigate = useNavigate();

  function refactorData(data) {
    return data.map((entry) => {
      const { user, surveyData } = entry;
      const title = surveyData.title || 'Untitled'; // If title is not provided, set it as "Untitled"
      const submittedBy = user.fname + ' ' + user.lname;

      return { submittedBy, title, surveyData };
    });
  }

  const surveyResponse = queryClient.getQueryData(['totalUsers'], { exact: false })?.data?.data;

  const [open, setOpen] = useState(false);
  const [editEdifierOpen, setEditEdifierOpen] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [coachesRecord, setCoachesRecord] = useState([]);
  const [refactoredPairedUsers, setRefactoredPairedUsers] = useState([]);

  const [trainingEndingPayload, setTrainingEndingPayload] = useState({});

  const [isListView, setIsListView] = useState(false);

  const [displayEdifierDetails, setDisplayEdifierDetails] = useState(false);

  const [currentEdifier, setCurrentEdifier] = useState({});

  // useEffect(() => {
  //   if (checkIfRecordIsNotNullFromBe(coachesList)) {
  //     setCoachesRecord([]);
  //   } else {
  //     setCoachesRecord(coachesList?.data?.data);
  //   }
  // }, [coachesList]);

  useEffect(() => {
    if (!surveyResponse) {
      navigate('/dashboard/app');
    }
  }, []);

  const checkIfRecordIsNotNullFromBe = (coachesList) => {
    return (
      coachesList?.data?.data?.includes(null) &&
      JSON.stringify(coachesList?.data?.data).split(null)?.length - 1 === coachesList?.data?.data?.length
    );
  };

  const searchFromRecord = () => {
    if (searchedValue === '') {
      if (checkIfRecordIsNotNullFromBe(coachesList)) {
        setCoachesRecord([]);
      } else {
        setCoachesRecord(coachesList?.data?.data);
      }
      return;
    }
    const filtered = checkIfRecordIsNotNullFromBe(coachesList)
      ? []
      : coachesList?.data?.data.filter((item) => item?.fname?.toLowerCase().includes(searchedValue));

    setCoachesRecord(filtered);
  };

  const getSurveyByTitle = (currentResponse) => {
    if (currentResponse?.title?.toLowerCase() === 'final session surveys') {
      return <FinalSessionSurveys prePopulatedData={currentResponse} />;
    } else if (currentResponse?.title?.toLowerCase() === 'welcome session survey') {
      return <WelcomeSessionSurvey prePopulatedData={currentResponse} />;
    } else if (currentResponse?.title?.toLowerCase() === 'session survey a') {
      return <SessionSurveyA prePopulatedData={currentResponse} />;
    } else if (currentResponse?.title?.toLowerCase() === 'session survey b') {
      return <SessionSurveyB prePopulatedData={currentResponse} />;
    } else if (currentResponse?.title?.toLowerCase() === 'session survey c') {
      return <SessionSurveyC prePopulatedData={currentResponse} />;
    } else {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (Object.keys(currentResponse).length > 0) {
      setOpen(true);
    }
  }, [currentResponse]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Manage Trainings</title>
      </Helmet>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          // scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          sx={{
            '& .MuiPaper-root': { minWidth: '70%', height: '80%' },
          }}
        >
          {getSurveyByTitle(currentResponse)}
        </Dialog>
      )}

      <Container maxWidth="xl" sx={{ padding: '0 !important' }}>
        {/* Displaying users tags in a modal */}
        <UserDetailedInformationDialog
          open={displayEdifierDetails}
          handleClose={() => setDisplayEdifierDetails(false)}
          userData={currentEdifier}
        />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            rowGap: '20px',
          }}
        >
          <Box>
            <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
              Recorded Responses
            </Typography>

            {/* <Typography variant="subtitles" sx={{ display: 'flex' }}>
              <Box component="img" src="/assets/calendarIcon.svg" sx={{ mr: '7.32px' }} />
              Member since July 11, 2021
            </Typography> */}
          </Box>
        </Box>
        <Box
          sx={{
            ...listGridViewContainerStyles,
          }}
        >
          <Box sx={{ ...filterContainerStyles }}>
            <TextField
              placeholder="Search Edifiers by Name"
              className="filters"
              sx={{ minWidth: '233px', maxWidth: { xs: '100%', md: '233px' } }}
              onChange={(e) => setSearchedValue(e.target.value.toLowerCase())}
            />

            <Button
              variant="contained"
              color="main"
              sx={{ height: 'fit-content', my: 'auto', width: 'max-content' }}
              onClick={() => searchFromRecord()}
            >
              Search
            </Button>
          </Box>
        </Box>

        {/* {isLoadingPairs && (
          <Box sx={{ textAlign: 'center', mt: '30px' }}>
            <CircularProgress />
          </Box>
        )} */}

        {surveyResponse?.surveys?.length && (
          <TableView
            tableData={surveyResponse?.surveys ? refactorData(surveyResponse?.surveys) : []}
            setOpen={setOpen}
            userRole={userDetails?.role.toLowerCase()}
            searchedValue={searchedValue}
            setTrainingEndingPayload={setTrainingEndingPayload}
            setCurrentResponse={setCurrentResponse}
            responses
          />
        )}
      </Container>
    </Box>
  );
};

export default FindAnEdifier;
