import React from 'react';
import { useForm } from 'react-hook-form';

import CloseIcon from '@mui/icons-material/Close';

// @mui
import toast from 'react-hot-toast';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography, Box, TextField } from '@mui/material';
// hooks

import { FileUpload } from '../components/file-upload';

import { validatePassword } from '../utils';

// components

// ----------------------------------------------------------------------

export default function ResetPassword({
  setPreviewImage,
  setSelectedImage,
  previewImage,
  selectedImage,
  setOpen,
  setEditData,
  isLoading,
}) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({});

  const onSubmit = (submittedData) => {
    const formData = new FormData();

    if (!submittedData.confirmPassword && !submittedData.currentPassword && !submittedData.password && !selectedImage) {
      toast.error('Please Update any Info to Continue');
      return;
    }

    Object.keys(submittedData).forEach((key) => {
      if (key !== 'confirmPassword' && key !== 'password') {
        formData.append(key, submittedData[key]);
      }
      if (key === 'password') {
        formData.append('newPassword', submittedData[key]);
      }
    });

    if (selectedImage) {
      formData.append('image', selectedImage);
    }

    setEditData({ formData });
  };

  return (
    <Box
      sx={{
        height: 'fit-content',
        width: '100%',
        boxShadow: '0px 2px 6px 0px rgba(13, 10, 44, 0.08)',
        borderRadius: '20px',
        mx: 'auto',
        background: 'white',
        p: '30px',
      }}
    >
      <Box sx={{ textAlign: 'right' }}>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
      </Box>
      <Typography variant="mainTitles" sx={{ textAlign: 'center', color: 'text.titles', mt: '12px' }}>
        Edit Profile
      </Typography>

      <FileUpload
        setPreviewFile={setPreviewImage}
        setSelectedFile={setSelectedImage}
        previewFile={previewImage}
        selectedFile={selectedImage}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="password"
          label="Current Password *"
          fullWidth
          // {...register('password')}
          {...register(
            'currentPassword',
            (watch('password')?.length > 0 || watch('confirmPassword')?.length > 0) && {
              required: `Current Password is required`,
            }
          )}
          error={errors && !!errors?.currentPassword}
          helperText={errors?.currentPassword?.message}
          type="password"
          autoComplete="on"
        />

        <TextField
          name="password"
          label="New Password *"
          fullWidth
          // {...register('password')}
          {...register(
            'password',
            (watch('currentPassword')?.length > 0 || watch('currentPassword')) && {
              required: true,
              validate: validatePassword,
            }
          )}
          error={errors && !!errors?.password}
          helperText={errors?.password?.message}
          type="password"
          autoComplete="on"
          sx={{ my: '20px' }}
        />
        <TextField
          name="confirmPassword"
          label="Confirm Password *"
          fullWidth
          {...register(
            'confirmPassword',
            watch('currentPassword')?.length > 0 && {
              required: `This is required`,
              validate: (value) => {
                if (watch('password') !== value) {
                  return 'Passwords should match!';
                }
                if (watch('currentPassword') === value) {
                  return 'Current and New Passwords should not be Same';
                }
                return null;
              },
            }
          )}
          error={errors && !!errors?.confirmPassword}
          helperText={errors?.confirmPassword?.message}
          type="password"
          autoComplete="on"
        />

        <LoadingButton
          loading={isLoading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, maxWidth: '118px' }}
          color="main"
        >
          Submit
        </LoadingButton>
      </form>
    </Box>
  );
}
