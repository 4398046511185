import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import { logOutUser, generateToastError } from '../../../utils';
import { queryKeys } from '../queryKeys';

const httpStatuses = { unauthorized: 401, error: 500 };

export const useQueryGetSideLeaderRequests = (userToken, navigate, toast) => {
  const queryKey = [queryKeys.getSiteLeaderRequests];

  const queryFn = () =>
    axios.get(`${process.env.REACT_APP_SERVER_URL}/api/admin/requests`, {
      headers: { Authorization: `Bearer ${userToken}` },
    });
  return useQuery(queryKey, queryFn, {
    enabled: true,
    retry: 0,
    cacheTime: 0,

    refetchOnWindowFocus: false,

    staleTime: Infinity,

    onError: (e) => {
      if (e?.response?.status === httpStatuses.unauthorized) {
        logOutUser(navigate);
        return;
      }
      generateToastError(toast, e);
    },
  });
};



export const useMutationUpdateSiteLeaderRequest = (
  payload,
  toast,
  userToken,
  navigate,
  setPayload,
  queryClient
) => {
  const queryKey = [queryKeys.updateSideLeaderRequest, payload];
  const queryFn = () =>
    axios.put(`${process.env.REACT_APP_SERVER_URL}/api/admin/update-request/${payload?.id}`, payload, {
      headers: { Authorization: `Bearer ${userToken}` },
    });

  return useMutation(queryKey, queryFn, {
    enabled: false,
    refetchOnWindowFocus: false,
    onSuccess: (success) => {
      toast.success(`${success.data.message}`, {
        duration: 3000,
      });
      setPayload({})

      queryClient.invalidateQueries({ queryKey: [queryKeys.getSiteLeaderRequests] });

      
    },
    onError: (error) => {
      try {
        if (error?.response?.status === httpStatuses.unauthorized && payload.role === 'admin') {
          logOutUser(navigate);
          return;
        }
        generateToastError(toast, error);
      } catch (e) {
        toast.error('The errror ', e);
      }
    },
  });
};


