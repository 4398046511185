import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import Onboarding from '../../pages/OnBoarding';
import { fullScreenDialogStyles } from '../../jsxStyles';

export default function AddCoachDialog({
  open,
  handleClose,
  userRole,
  isEdit,
  edifierToEditData,
  setEdifierToEditData,
  userToken,
  queryClient,
}) {
  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ...fullScreenDialogStyles,
        }}
      >
        <DialogContent sx={{ p: { xs: '0 !important', sm: 'inherit' } }}>
          <Box sx={{ textAlign: 'right', p: '20px 30px 0 0', cursor: 'pointer' }} onClick={(e) => handleClose(e)}>
            <CloseIcon />
          </Box>

          <Typography variant="mainTitles" sx={{ color: 'text.titles', textAlign: 'center' }}>
            {isEdit
              ? edifierToEditData?.role?.toLowerCase() === 'teacher'
                ? 'Modify Teacher Data'
                : 'Modify Edifier Data'
              : 'Add Edifier'}
          </Typography>
          {!isEdit && (
            <Onboarding handleClose={handleClose} userRole={userRole} isOnlyEdifier queryClient={queryClient} />
          )}

          {isEdit && (
            <Onboarding
              handleClose={handleClose}
              userRole={userRole}
              edifierToEditData={edifierToEditData}
              setEdifierToEditData={setEdifierToEditData}
              userToken={userToken}
              isOnlyEdifier={!edifierToEditData ? true : edifierToEditData?.role?.toLowerCase() === 'coach'}
              queryClient={queryClient}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
