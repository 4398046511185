/*eslint-disable*/

import React, { useState, useEffect } from 'react';
import { Grid, Container, Typography, Box, Button, CircularProgress, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQueryClient } from 'react-query';

import toast from 'react-hot-toast';

// import { CheckinCheckout } from '../components/checkin';
import { getUserDetails } from '../utils';
import {
  useQueryGetPairedUsersAdmin,
  useMutationEndTraining,
  useMutationEndPairing,
} from '../hooks/react-query/pairing';

import { filterContainerStyles, listGridViewContainerStyles } from '../jsxStyles';

import { TableView } from '../components/table';

const FindAnEdifier = () => {
  const queryClient = useQueryClient();

  const userDetails = getUserDetails();

  const userToken = userDetails?.token;

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [refactoredPairedUsers, setRefactoredPairedUsers] = useState([]);

  const [trainingEndingPayload, setTrainingEndingPayload] = useState({});

  const [pairingEndingPayload, setPairingEndingPayload] = useState({});

  const { data: pairedUsersData, isFetching: isLoadingPairs } = useQueryGetPairedUsersAdmin(userToken, navigate, toast);
  const { isLoading: isEndingTraining, mutate: endTraining } = useMutationEndTraining(
    userToken,
    navigate,
    toast,
    trainingEndingPayload,
    setTrainingEndingPayload,
    queryClient
  );

  const { isLoading: isEndingPairing, mutate: endPairing } = useMutationEndPairing(
    userToken,
    navigate,
    toast,
    pairingEndingPayload,
    setPairingEndingPayload,
    queryClient
  );

  useEffect(() => {
    if (pairedUsersData?.length) {
      setRefactoredPairedUsers(pairedUsersData);
    }
  }, [pairedUsersData]);

  useEffect(() => {
    if (Object.keys(trainingEndingPayload).length > 0) {
      endTraining();
    }
  }, [trainingEndingPayload]);

  useEffect(() => {
    if (Object.keys(pairingEndingPayload).length > 0) {
      endPairing();
    }
  }, [pairingEndingPayload]);

  const checkIfRecordIsNotNullFromBe = (pairedUsersList) => {
    return (
      pairedUsersList?.data?.data?.includes(null) &&
      JSON.stringify(coachesLipairedUsersListst?.data?.data).split(null)?.length - 1 ===
        pairedUsersList?.data?.data?.length
    );
  };

  const searchFromRecord = () => {
    if (searchedValue === '') {
      if (checkIfRecordIsNotNullFromBe(refactoredPairedUsers)) {
        setRefactoredPairedUsers([]);
      } else {
        setRefactoredPairedUsers(pairedUsersData);
      }
      return;
    }
    const filtered = checkIfRecordIsNotNullFromBe(refactoredPairedUsers)
      ? []
      : pairedUsersData?.filter(
          (item) => item?.['Teacher Name'].includes(searchedValue) || item?.['Edifier Name'].includes(searchedValue)
        );

    setRefactoredPairedUsers(filtered);
  };

  return (
    <Box>
      <Helmet>
        <title>Edifying Teachers | Manage Trainings</title>
      </Helmet>

      <Container maxWidth="xl" sx={{ padding: '0 !important' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', sm: 'row' },
            rowGap: '20px',
          }}
        >
          <Box>
            <Typography variant="mainTitles" sx={{ mb: '5px', color: 'text.titles', display: 'block' }}>
              Manage Training
            </Typography>

            {/* <Typography variant="subtitles" sx={{ display: 'flex' }}>
              <Box component="img" src="/assets/calendarIcon.svg" sx={{ mr: '7.32px' }} />
              Member since July 11, 2021
            </Typography> */}
          </Box>
        </Box>
        <Box
          sx={{
            ...listGridViewContainerStyles,
          }}
        >
          <Box sx={{ ...filterContainerStyles }}>
            <TextField
              placeholder="Search Pairs by Name"
              className="filters"
              sx={{ minWidth: '233px', maxWidth: { xs: '100%', md: '233px' } }}
              onChange={(e) => setSearchedValue(e.target.value.toLowerCase())}
            />

            <Button
              variant="contained"
              color="main"
              sx={{ height: 'fit-content', my: 'auto', width: 'max-content' }}
              onClick={() => searchFromRecord()}
            >
              Search
            </Button>
          </Box>
        </Box>

        {isLoadingPairs && (
          <Box sx={{ textAlign: 'center', mt: '30px' }}>
            <CircularProgress />
          </Box>
        )}

        {!isLoadingPairs && (
          <TableView
            tableData={refactoredPairedUsers}
            setOpen={setOpen}
            userRole={userDetails?.role.toLowerCase()}
            searchedValue={searchedValue}
            isEndingTraining={isEndingTraining}
            isEndingPairing={isEndingPairing}
            setTrainingEndingPayload={setTrainingEndingPayload}
            setPairingEndingPayload={setPairingEndingPayload}
            pairedDisplay
          />
        )}
      </Container>
    </Box>
  );
};

export default FindAnEdifier;
